import { createSlice } from '@reduxjs/toolkit';
import { openNotificationWithIcon } from '../../pages/auth/primary_signup/primary_signup';
import { getCablePlans, getUtilityPlans, verifyCable } from '../../services/apiSevices';

const utilitySlice = createSlice({
  name: 'utilities',
  initialState: {
    cablePlans: [],
    utilityPlans: [],
    cableUser: null,
    utilityUser: null,
    loading: null,
    error: null,
  },
  reducers: {
    setCablePlans: (state, { payload }) => {
      state.cablePlans = payload;
    },
    setUtiltyPlans: (state, { payload }) => {
      state.utilityPlans = payload;
    },
    utilityLoading: (state, { payload }) => {
      state.loading = payload;
    },
    setCableUser: (state, { payload }) => {
      state.cableUser = payload;
    },
    setUtilityeUser: (state, { payload }) => {
      state.utilityUser = payload;
    },
    utilityError: (state, { payload }) => {
      state.error = payload;
    },
    resetCableUser: (state) => {
      state.cableUser = null;
    },
    resetUtilityUser: (state) => {
      state.utilityUser = null;
    },
  },
});
const utilityReducer = utilitySlice.reducer;

const { setCablePlans, setUtiltyPlans, setCableUser, utilityError, utilityLoading, setUtilityeUser, resetCableUser, resetUtilityUser } = utilitySlice.actions;
const apply =
  ({ remote, action, actionFor }) =>
  async (dispatch) => {
    const loadingAction = { actionFor };
    dispatch(utilityLoading({ ...loadingAction, isBusy: true }));
    try {
      const { data } = await remote();
      // console.log({ data })
      if (data?.status && data?.data) {
        dispatch(action(data?.data));
        dispatch(utilityLoading({ ...loadingAction, isBusy: false }));
      } else if (data?.status && data?.customer) {
        dispatch(action(data?.customer));
        dispatch(utilityLoading({ ...loadingAction, isBusy: false }));
      } else {
        dispatch(action(null));
        dispatch(utilityError(data?.message));
        openNotificationWithIcon('error', 'Error', data?.message);
        dispatch(utilityLoading({ ...loadingAction, isBusy: false }));
      }
    } catch (err) {
      // console.log({ err })
      dispatch(action(null));
      dispatch(utilityError(err.response?.data?.message));
      openNotificationWithIcon('error', 'Error', err.response?.data?.message);
      dispatch(utilityLoading({ ...loadingAction, isBusy: false }));
    }
  };
export const loadCablePlans = () => async (dispatch) => {
  await apply({ remote: () => getCablePlans(), action: setCablePlans, actionFor: 'cable' })(dispatch);
};

export const loadUtilityPlans = () => async (dispatch) => {
  await apply({ remote: () => getUtilityPlans(), action: setUtiltyPlans, actionFor: 'utility' })(dispatch);
};

export const verifySmartCardNumber = (cardNumber, data) => async (dispatch) => {
  await apply({ remote: () => verifyCable(cardNumber, data), action: setCableUser, actionFor: 'verify' })(dispatch);
};

export const verifyUtilityNumber = (meterNumber, data) => async (dispatch) => {
  await apply({ remote: () => verifyCable(meterNumber, data), action: setUtilityeUser, actionFor: 'verify' })(dispatch);
};

export const resetUser = (type) => async (dispatch) => {
  await dispatch(type === 'cable' ? resetCableUser() : resetUtilityUser());
};
export const utilitySelector = (state) => state.utilities;
export default utilityReducer;
