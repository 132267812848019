import { Select } from 'antd';
import { Bar, Line } from 'react-chartjs-2';
import { LoyaltyTable } from '../bulk_table/bulk_table';
import { Spacer } from '../layout/layout';
import './loyalty_scheme.css';

export const LoyaltyTopCards = () => {
  const cardFilter = [
    {
      name: 'Today',
      value: 'Today',
    },
    {
      name: 'Last 1 Week',
      value: 'Last 1 Week',
    },
    {
      name: 'Last 1 Month',
      value: 'Last 1 Month',
    },
    {
      name: 'Last 1 Year',
      value: 'Last 1 Year',
    },
  ];
  return (
    <div className="loyalty-top-cards d-flex j-space-between">
      <LoyaltyTopCard cardTitle="No of cards assigned" cardValue={300} selectData={cardFilter} />
      <LoyaltyTopCard cardTitle="No of cards assigned" cardValue={380} selectData={cardFilter} />
      <LoyaltyTopCard cardTitle="Funding bracket" cardValue={200} />
    </div>
  );
};

const LoyaltyTopCard = ({ cardTitle, cardValue, selectData }) => {
  return (
    <div className="loyalty-card-container">
      <div className="loyalty-card-content d-flex j-space-between">
        <div className="d-flex flex-column j-space-between">
          <div className="fw500 grey-text paddingTop5">{cardTitle}</div>
          <div className="fw500 font-size-25em card-value">{cardValue}</div>
        </div>
        <div className="pt-5 pr-5">
          <Filter selectData={selectData} />
        </div>
      </div>
    </div>
  );
};

const Filter = ({ selectData }) => {
  return (
    <div className="loyalty-dropdown-container p-relative">
      <Select className="loyalty-borderless" bordered={false} placeholder="Select a filter">
        {selectData?.map((option, idx) => (
          <Select.Option value={option?.value} key={idx} className="loyalty-dropdown-item">
            {option?.name}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

export const TransactionAnalytics = () => {
  return (
    <div className="trans-analytics-container mb-50">
      <div className="fw500 font-size-25em mb-50 sm-header mb-0">Transaction Analytics</div>
      <div className="trans-analytics-content d-flex full-width j-space-between">
        <div className="loyalty-analytics-section">
          <LoyaltyGraph headerTitle="Customer spend insights" />
        </div>
        <Spacer className="no-desktop-but-mobile" height={25} />
        <div className="loyalty-analytics-section">
          <LoyaltyBarCharts headerTitle="Product insights" />
        </div>
      </div>
    </div>
  );
};

const LoyaltyGraph = ({ headerTitle }) => {
  return (
    <div className="loyalty-graph-container">
      <div className="loyalty-graph-header d-flex j-space-between p20">
        <div className="fw500 font-size12em sm-graphHeader">{headerTitle}</div>
        <div className="sm-filter">
          <Filter />
        </div>
      </div>
      <div className="sm-charts">
        <LoyaltyLineChart />
      </div>
    </div>
  );
};

const LoyaltyBarCharts = ({ headerTitle }) => {
  return (
    <div className="loyalty-graph-container">
      <div className="loyalty-graph-header d-flex j-space-between p20">
        <div className="fw500 font-size12em sm-graphHeader">{headerTitle}</div>
        <div className="sm-filter">
          <Filter />
        </div>
      </div>
      <>
        <LoyaltyBarChart />
      </>
    </div>
  );
};

const LoyaltyLineChart = () => {
  return <Line />;
};

const LoyaltyBarChart = () => {
  return <Bar />;
};

export const TopTransactingCustomers = () => {
  const tableHeader = ['Name', 'Phone Number', 'Date Joined', 'Amount Spent'];
  return (
    <div className="mb-50">
      <div className="d-flex full-width j-space-between">
        <div className="fw500 font-size-25em mb-50 sm-header mb-0">Top Transacting customer</div>
        <div className="c-pointer font-size-14 red-text-color fw500">View All</div>
      </div>
      <div>
        <LoyaltyTable headers={tableHeader} data={[]} />
      </div>
    </div>
  );
};

export const DiscountAnalysis = () => {
  return (
    <div className="trans-analytics-container mb-50">
      <div className="fw500 font-size-25em mb-50 sm-header mb-0">Discount Analytics</div>
      <div className="trans-analytics-content d-flex full-width j-space-between">
        <div className="loyalty-analytics-section">
          <LoyaltyBarCharts headerTitle="Discount Insights" />
        </div>
        <Spacer className="no-desktop-but-mobile" height={25} />
        <div className="loyalty-analytics-section">
          <LoyaltyGraph headerTitle="Discount Product Insights" />
        </div>
      </div>
    </div>
  );
};
