import { createSlice } from '@reduxjs/toolkit';

const virtualAccountFeatureSlice = createSlice({
  name: 'virtualAccountFeature',
  initialState: {
    inboundTotalCount: 0,
    cardPaymentCount: 0,
    inboundTransferCount: 0,
  },
  reducers: {
    resetCardPaymentCount: (state) => {
      state.cardPaymentCount = 0;
    },
    resetInboundTransfer: (state) => {
      state.inboundTransferCount = 0;
    },
    resetTotalTransferCount: (state) => {
      state.inboundTotalCount = 0;
    },
    setCardPaymentCount: (state, { payload }) => {
      state.cardPaymentCount = payload;
    },
    setInboundPaymentCount: (state, { payload }) => {
      state.inboundTransferCount = payload;
    },
    setTransferTotalCount: (state, { payload }) => {
      state.inboundTotalCount = payload;
    },
  },
});

export const { resetCardPaymentCount, resetInboundTransfer, setCardPaymentCount, setInboundPaymentCount, resetTotalTransferCount, setTransferTotalCount } = virtualAccountFeatureSlice.actions;
const merchantVirtualAccountReducer = virtualAccountFeatureSlice.reducer;
export default merchantVirtualAccountReducer;
