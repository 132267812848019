import { LoadingOutlined } from '@ant-design/icons';
import { lazy, Suspense, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { DashboardSkeleton } from './components/dashboard_skeleton';
import { SessionExpirationPopup } from './components/popup/popup';
import { ScrollToTop } from './components/scroll_to_top/scroll_to_top';
import { TITLE, TITLE_MAPPING } from './constants/title';
import { useRedirect } from './hooks/use_redirect';
import useTitle from './hooks/use_title';

import { useFeatureFlag } from './hooks/use_feature_flag';
import Admin from './pages/admin';
import Login from './pages/auth/login/login';
import { MultiStepRegistration } from './pages/auth/signup_container/signup_container';
import Home from './pages/home/home';
import { CashierFAQ } from './pages/mobile-faq/cashier_faq';
import { FAQ } from './pages/mobile-faq/faq';
import { closeSessionModal } from './redux/user/user_actions';
import { AppEntry } from './services/auth/params_auth_guard';
import { interceptor } from './services/interceptors';

const Privacy = lazy(() => import('./pages/privacy/privacy'));
const MerchantPage = lazy(() => import('./pages/merchant/merchant'));
const NotFoundPage = lazy(() => import('./pages/not_found_page/not_found'));
const Contact = lazy(() => import('./pages/contact/contact'));
const CashierPage = lazy(() => import('./pages/cashier_page/cashier'));
const DeveloperPage = lazy(() => import('./pages/developer/developer_new'));
const StorefrontPage = lazy(() => import('./pages/storefront/storefront'));
const StorefrontWaitlistPage = lazy(() => import('./pages/storefront/waitlist/waitlist'));
const Terms = lazy(() => import('./pages/terms/terms'));
const VerifyAccount = lazy(() => import('./pages/verify_account/verify_account'));
const MobileTerms = lazy(() => import('./pages/terms/mobile_terms'));
const PasswordRequest = lazy(() => import('./pages/password_request/password_request'));

export const SpinnerWrapper = () => (
  <div className="full-vh full-width d-flex flex-vertical align-center j-center">
    <LoadingOutlined />
  </div>
);

export default function App() {
  useTitle(TITLE, TITLE_MAPPING);
  const dispatch = useDispatch();
  const location = useLocation();
  const isSession = useSelector((state) => state.user.isSession);
  const timerRef = useRef();
  const token = localStorage.getItem('v_pay_token');
  useRedirect();
  interceptor();
  const ERROR_HEADER = 'Oops';
  const ERROR_BODY = "We can't seem to find the page that you are looking for.";

  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const { isFlagRequired: shouldRedirectToNewSite = true } = useFeatureFlag('redirect.to.new.site');

  useEffect(() => {
    const pathsOnOldView = [
      '/signup',
      '/login',
      '/forgot-password',
      '/more-details',
      '/merchant/authorizations',
      '/mobile-terms',
      '/faq',
      '/cashierfaq',
      '/verify-account',
      '/mid',
      '/storefront/waitlist',
      '/storefront',
    ];

    if (!token && !pathsOnOldView.includes(location.pathname) && shouldRedirectToNewSite) {
      window.location.href = 'https://home.vpay.africa';
    }
    timerRef.current = setTimeout(() => {
      setIsAuthenticated(!!token);
    }, 500);

    return () => clearTimeout(timerRef.current);
  }, [location.pathname, shouldRedirectToNewSite, token]);

  useEffect(() => {
    closeSessionModal(dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isAuthenticated === null) {
    return <SpinnerWrapper />;
  }

  return (
    <ScrollToTop>
      <Suspense fallback={<SpinnerWrapper />}>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/merchant-page" component={MerchantPage} />
          <Route exact path="/cashier-page" component={CashierPage} />
          <Route exact path="/developer-page" component={DeveloperPage} />
          <Route exact path="/storefront" component={StorefrontPage} />
          <Route exact path="/storefront/waitlist" component={StorefrontWaitlistPage} />
          <Route exact path="/terms-and-condition" component={Terms} />
          <Route exact path="/signup" component={MultiStepRegistration} />
          <Route exact path="/signup?ref=mid" component={MultiStepRegistration} />
          <Route exact path="/mid" component={MultiStepRegistration} />
          <Route exact path="/login" component={Login} />
          <Route exact path="/forgot-password" component={PasswordRequest} />
          <Route exact path="/verify-account" component={VerifyAccount} />
          <Route exact path="/mobile-terms" component={MobileTerms} />
          <Route exact path="/privacy" component={Privacy} />
          <Route exact path="/faq" component={FAQ} />
          <Route exact path="/cashierfaq" component={CashierFAQ} />
          <Route exact path="/contact" component={Contact} />
          {/* we would have to remove the merchant authorizations route if we confirm that mobile doesn't need it anymore */}
          <Route exact path="/merchant/authorizations" component={AppEntry} />

          <Suspense fallback={<DashboardSkeleton />}>
            <Switch>
              <Route path="/admin" component={Admin} />
              <Route path="/404">
                <NotFoundPage error={ERROR_HEADER} errorInfo={ERROR_BODY} showRefresh={false} />
              </Route>
              <Route path="*">{token ? <Redirect to="/admin" /> : <Redirect to="/" />}</Route>
            </Switch>
          </Suspense>
        </Switch>
      </Suspense>

      {isSession && <SessionExpirationPopup />}
    </ScrollToTop>
  );
}
