import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import vpayLogo from '../../../assets/images/vpayLogo.svg';
import AppButton from '../../../components/app_button/app_button';
import { FormCheckBox, NairaFormInput } from '../../../components/form_input/form_input';
import { Spacer } from '../../../components/layout/layout';
import { LimitVideoPopup } from '../../../components/popup/popup';
import { fetchMerchantCurrentLimit, updateMerchantLimit } from '../../../services/apiSevices';
import { formatMoney } from '../../../utils/formatNumber';
import { openNotificationWithIcon } from '../../auth/primary_signup/primary_signup';

const AccountLimit = () => {
  const [limit, setLimit] = useState('');
  const [stage, setStage] = useState(1);
  const [isChecked, setIsChecked] = useState(null);
  const { businessname, address, nuban } = useSelector((state) => state.user.currentMerchant);
  const [videoUrl, setVideoUrl] = useState('');
  const [loading, setLoading] = useState(false);

  const { data, isLoading } = useQuery({ queryKey: ['getcurrentLimit'], queryFn: () => fetchMerchantCurrentLimit(), refetchInterval: false });
  const confirmedlimit = data?.data?.data?.confirmedlimit;
  let currentLimit;
  if (isLoading) {
    currentLimit = 'Loading';
  } else {
    currentLimit = formatMoney(confirmedlimit);
  }

  const handleLimitChange = (e) => {
    setLimit(e.target.value);
  };

  const handleCheck = (e) => {
    setIsChecked(e.target.checked);
  };
  const restrictMinus = (e) => {
    if (e.code === 'Minus') {
      e.preventDefault();
      return;
    }
  };

  const handleSubmitRequest = async () => {
    setLoading(true);
    const data = {
      amount: limit,
      videoUrl: videoUrl,
    };
    try {
      const res = await updateMerchantLimit(data);
      openNotificationWithIcon('success', 'Success', res?.data?.message);
      setLoading(false);
      setLimit('');
      setStage(1);
    } catch (error) {
      setLoading(false);
      openNotificationWithIcon('error', 'Error', error.response?.data?.message);
    }
  };

  const moveToNextStage = () => {
    if (limit) {
      setStage(2);
    } else {
      openNotificationWithIcon('error', 'Error', 'Please Enter A New Transfer Limit To Continue');
    }
  };

  return (
    <div className="profile-wrapper fw400 primary-bg-color p-relative" style={{ width: '45%' }}>
      {stage === 1 && (
        <div className="fade-in margin-auto" style={{ width: '80%' }}>
          <Spacer height={40} />
          <div className="text-center">
            <p className="fw500 font-size-18 text-color">Update your Account Limit</p>
          </div>
          <Spacer height={40} />
          <div>
            <NairaFormInput label="Current Account Limit" className="important-full-width fw400" value={`${currentLimit}`} readOnly={true} />
            <NairaFormInput
              label={`Enter New Account Limit = ₦${formatMoney(limit)}`}
              className="important-full-width fw400"
              placeholder="Enter your preferred new limit"
              onChange={(e) => handleLimitChange(e)}
              type="number"
              onKey={(e) => restrictMinus(e)}
            />
          </div>

          <Spacer height={40} />
          <AppButton name="Next" isActive={true} className="fade-in primary-color fw500 upload-button full-width action-color-bg" onClick={() => moveToNextStage()} />
          <Spacer height={40} />
        </div>
      )}
      {stage === 2 && (
        <div className="fade-in">
          <Spacer height={30} />
          <div className="c-pointer" onClick={() => setStage(1)}>
            <FontAwesomeIcon icon={faAngleLeft} size="2x" />
          </div>
          <Spacer height={40} />
          <LimitVideoPopup onClose={() => setStage(1)} setParentStage={setStage} setVideoUrl={setVideoUrl} />
          <AppButton name="Next" className="fade-in primary-color fw500 upload-button full-width action-color-bg" />
          <Spacer height={40} />
        </div>
      )}
      {stage === 3 && (
        <div className="fade-in ">
          <Spacer height={30} />
          <div className="c-pointer" onClick={() => setStage(1)}>
            <FontAwesomeIcon icon={faAngleLeft} size="2x" />
          </div>
          <Spacer height={40} />
          <div className="top-level">
            <div className="row d-flex j-space-between">
              <div className="d-flex">
                <section>
                  <p>To</p>
                </section>
                <Spacer width={5} />
                <section>
                  <p>
                    Managing Director
                    <br /> Minerva Technologies Company Limited
                    <br /> (Owners of VPay Africa Platform)
                    <br /> 163/165 Broad Street Marina, Lagos
                  </p>
                </section>
              </div>
              <div>
                <img src={vpayLogo} alt="VPay logo" />
              </div>
            </div>
            <Spacer height={30} />
            <div className="row text-center">
              <p>Account Transaction Limit Increase Form</p>
            </div>
            <div className="row font-size-14 fw200">
              <p>Whereas:</p>
              <div>
                <p className="mb-2">
                  1. I/We <span className="fw700">{businessname ?? ''}</span> am/are the holder of account number {nuban ?? ''} registered to transact on the VPay platform.{' '}
                </p>{' '}
                <p className="mb-2">
                  2. VPay has advised that the daily limit approved for online funds transfer is <span className="fw700">₦2,000,000</span> for individual and corporate merchants registered on the VPay
                  Africa platform.
                </p>{' '}
                <p className="mb-2">
                  {' '}
                  3. I/We understand that while VPay Africa may permit an increase in transaction limits, this decision will be made based on internal considerations and directive by regulators.
                </p>
                <p className="mb-2">
                  4. I/We hereby request to be allowed to carry out transactions more than the current limit on my/our account. VPay Africa has advised me/us regarding the risks associated with this
                  but I/we have indicated that for business exigency, I/We am/are willing to take the risk.
                </p>{' '}
                <p className="mb-2 ">
                  5. VPay Africa, Minerva Technologies (her parent company), or VFD Microfinance Bank (her banking partner) will not be liable for any loss arising from exceeding the current
                  authorized limit on this account.{' '}
                </p>
                <Spacer height={20} />
                <p>Now, in consideration of you, VPay Africa agreeing to allow me/us carry out online transactions</p>
                <p>
                  up to <span className="">{`₦${formatMoney(limit)}`}</span> per day on my/our Account,
                </p>
                <Spacer height={5} />
                <p>
                  I/We <span>{businessname ?? ''}</span> of <span>{address}</span> hereby;
                </p>
                <Spacer height={10} />
                <p className="mb-2">
                  1, Undertake to indemnify you and keep you indemnified against any loss, cos, damage, liability, charges or expenses whatsoever (included but not limited to legal expenses) which you
                  may suffer or incur by reason of allowing me/us to carry out online transfers in excess of the limit initially set for my account.
                </p>
              </div>
              <p className="mb-2 fw700">
                2, I Agree that VPay Africa has informed me of the risk associated with exceeding the threshold set and the possibility of incurring losses in the event of a compromise, fraud, etc.
              </p>
              <p className="mb-2 fw700">
                3, I/We agree that under no circumstance will VPay Africa be liable for any loss suffered because my/our account was enabled to allow transaction above the threshold set previously.
              </p>
              <Spacer height={30} />
              <p>Date: {new Date().toDateString()}</p>
            </div>
          </div>
          <Spacer height={15} />
          <FormCheckBox checktext="I Agree To These Terms And Conditions." oncheckboxChange={(e) => handleCheck(e)} />
          <Spacer height={17} />
          {isChecked && (
            <AppButton name="Submit Request" className="fade-in primary-color fw500 upload-button full-width action-color-bg" isActive={true} isBusy={loading} onClick={() => handleSubmitRequest()} />
          )}
          <Spacer height={40} />
        </div>
      )}
    </div>
  );
};

export default AccountLimit;
