import './store_button.css';

import googlePlay from '../../assets/images/smallGoogle.svg';
import appStore from '../../assets/images/mobileApple.svg';

export default function StoreButton({ className, orientation }) {
  return (
    <div className={`d-flex ${className || ''} ${orientation === 'vertical' ? 'flex-vertical' : ''}`}>
      <button className={`unstyle-button store-button no-padding c-pointer ${orientation === 'vertical' ? 'mb-2' : 'mr-2'}`}>
        <a href="https://merchantapp.vpay.africa" target="_blank" rel="noopener noreferrer">
          <img src={googlePlay} className="store-icon" alt="link to google play" />
        </a>
      </button>
      <button className="unstyle-button store-button no-padding c-pointer">
        <a href="https://merchantapp.vpay.africa" target="_blank" rel="noopener noreferrer">
          <img src={appStore} className="store-icon" alt="link to app store" />
        </a>
      </button>
    </div>
  );
}
