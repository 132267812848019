import { Divider, Select } from 'antd';
import 'antd/dist/antd.css';

import { useDispatch } from 'react-redux';
import { setProductPopup } from '../../redux/merchant/custom_inbound.feature';
import { AppButtonWithIcon } from '../app_button/app_button';
import './form_dropdown.css';

export function FormDropdown({ options, labelColor, placeholder, label, className, loading, onChange, error, name, defaultValue, suffixIcon, width }) {
  return (
    <div style={{ width }} className="dropdown-container p-relative">
      {label && <span className={`${labelColor ?? 'dropdown-label'} fw200 `}>{label}</span>}
      <div className="p-relative">
        <Select
          showSearch
          className={`${className} ${error && error[name] ? 'has-error-1' : ''}`}
          bordered={false}
          size="large"
          loading={loading}
          placeholder={placeholder}
          autoComplete="new-password"
          onChange={onChange}
          name={name}
          suffixIcon={suffixIcon}
          defaultValue={defaultValue || null}
        >
          {options?.map((option, idx) => (
            <Select.Option value={option} key={idx}>
              {option}
            </Select.Option>
          ))}
        </Select>
      </div>
    </div>
  );
}

export function BorderLessFormDropdown({ options, placeholder, label, className, loading, onChange, error, name, defaultValue, suffixIcon, width }) {
  return (
    <div style={{ width }} className="dropdown-container p-relative">
      {label && <span className="dropdown-label fw200 ">{label}</span>}
      <div className="p-relative">
        <Select
          showSearch
          className={`${className} unique-borderless ${error && error[name] ? 'has-error-1' : ''}`}
          bordered={false}
          size="large"
          loading={loading}
          placeholder={placeholder}
          autoComplete="new-password"
          onChange={onChange}
          name={name}
          suffixIcon={suffixIcon}
          defaultValue={defaultValue || null}
        >
          {options?.map((option, idx) => (
            <Select.Option value={option} key={idx}>
              {option}
            </Select.Option>
          ))}
        </Select>
      </div>
    </div>
  );
}

export function BorderLessFormDropdownClone({ options, placeholder, label, className, loading, onChange, error, name, defaultValue, suffixIcon, width }) {
  return (
    <div style={{ width }} className="dropdown-container p-relative">
      {label && <span className="dropdown-label fw200 ">{label}</span>}
      <div className="p-relative">
        <Select
          showSearch
          className={`${className} unique-borderless ${error && error[name] ? 'has-error-1' : ''}`}
          bordered={false}
          size="large"
          loading={loading}
          placeholder={placeholder}
          autoComplete="new-password"
          onChange={onChange}
          name={name}
          suffixIcon={suffixIcon}
          defaultValue={defaultValue || null}
        >
          {options?.map((option, idx) => (
            <Select.Option value={option?._id + '-' + option?.name} key={idx}>
              {option?.name}
            </Select.Option>
          ))}
        </Select>
      </div>
    </div>
  );
}

export function BorderLessFormDropdownWithAddButton({ options, onClick, placeholder, label, className, loading, onChange, error, name, defaultValue, suffixIcon, width, stage }) {
  const dispatch = useDispatch();
  const handleShowProductPop = () => {
    // onClick();
    dispatch(setProductPopup(true));
  };

  return (
    <>
      {stage === 0 && (
        <div style={{ width }} className="dropdown-container p-relative">
          {label && <span className="dropdown-label fw200 ">{label}</span>}
          <div className="p-relative">
            <Select
              showSearch
              className={`${className} unique-borderless ${error && error[name] ? 'has-error-1' : ''}`}
              bordered={false}
              size="large"
              loading={loading}
              placeholder={placeholder}
              autoComplete="new-password"
              onChange={onChange}
              name={name}
              suffixIcon={suffixIcon}
              defaultValue={defaultValue || null}
              dropdownRender={(menu) => (
                <div>
                  {menu}
                  <Divider />
                  <div className="d-flex">
                    <div className="w-60"></div>
                    <AppButtonWithIcon
                      onClick={() => handleShowProductPop()}
                      className="d-flex align-center border-radius35 action-color-2 button-nav-padding text-white fade-in"
                      imgClassName={true}
                      name="Add Product"
                      space={8}
                      isActive={true}
                    />
                  </div>
                </div>
              )}
            >
              {options?.map((option, idx) => (
                <>
                  <Select.Option value={option?._id} key={idx}>
                    {option?.name}
                  </Select.Option>
                </>
              ))}
            </Select>
          </div>
        </div>
      )}
    </>
  );
}
