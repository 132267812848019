import { STATE } from '../mock/state';

import dayjs from 'dayjs';

var isSameOrAfter = require('dayjs/plugin/isSameOrAfter');
dayjs.extend(isSameOrAfter);

const {
  REACT_APP_STAGING_CLOUD_NAME,
  REACT_APP_STAGING_KEY,
  REACT_APP_STAGING_CLOUD_SECRET,
  REACT_APP_CLOUDINARY_STAGING_BASE_URL,
  REACT_APP_LIVE_CLOUD_NAME,
  REACT_APP_LIVE_KEY,
  REACT_APP_LIVE_CLOUD_SECRET,
  REACT_APP_CLOUDINARY_LIVE_BASE_URL,
  REACT_APP_ENV,
  REACT_APP_CLOUDINARY_STAGING_BASE_URL_DESTROY,
  REACT_APP_CLOUDINARY_LIVE_BASE_URL_DESTROY,
  REACT_APP_CLOUDINARY_STAGING_BASE_VIDEO_URL,
  REACT_APP_CLOUDINARY_LIVE_BASE_VIDEO_URL,
} = process.env;

export const getCloudNameFromEnv = () => (REACT_APP_ENV === 'dev' ? REACT_APP_STAGING_CLOUD_NAME : REACT_APP_LIVE_CLOUD_NAME);
export const getCloudKeyFromEnv = () => (REACT_APP_ENV === 'dev' ? REACT_APP_STAGING_KEY : REACT_APP_LIVE_KEY);
export const getCloudSecretFromEnv = () => (REACT_APP_ENV === 'dev' ? REACT_APP_STAGING_CLOUD_SECRET : REACT_APP_LIVE_CLOUD_SECRET);
export const getCloudUrlFromEnv = () => (REACT_APP_ENV === 'dev' ? REACT_APP_CLOUDINARY_STAGING_BASE_URL : REACT_APP_CLOUDINARY_LIVE_BASE_URL);
export const getCloudUrlFromEnvDestroy = () => (REACT_APP_ENV === 'dev' ? REACT_APP_CLOUDINARY_STAGING_BASE_URL_DESTROY : REACT_APP_CLOUDINARY_LIVE_BASE_URL_DESTROY);
export const getCloudVideoUrlFromEnv = () => (REACT_APP_ENV === 'dev' ? REACT_APP_CLOUDINARY_STAGING_BASE_VIDEO_URL : REACT_APP_CLOUDINARY_LIVE_BASE_VIDEO_URL);
export const mappedStates = STATE.map((state, _idx) => state.state);

export const getLGAFromState = (state) => {
  const foundState = STATE.find((e) => e.state === state);

  return foundState?.lgas || [];
};

export const setNuban = (merchantArray, id) => {
  const foundMerchant = merchantArray.find((merchant) => merchant.businessid === id);

  if (foundMerchant) {
    return foundMerchant.nuban;
  } else {
    return;
  }
};

export const setPublicKey = (merchantArray, id) => {
  const foundMerchant = merchantArray.find((merchant) => merchant.businessid === id);
  if (foundMerchant) {
    return foundMerchant.publickey;
  } else {
    return;
  }
};

export const isDesktop = () => {
  const mobileChecker = document.querySelector('.no-desktop-but-mobile');
  // console.log('dis', mobileChecker)
  if (!mobileChecker) {
    return true;
  }
  const { display } = getComputedStyle(mobileChecker) || {};
  return display === 'none';
};

export const getReadableFile = (input) => {
  if (input.files && input.files[0]) {
    const reader = new FileReader();
    // convert to base64 string
    reader.readAsDataURL(input.files[0]);
    return reader;
  }
};

export const getBase64String = (input) => {
  let block = input.split(';');
  // Get the content type of the image
  let contentType = block[1].split(',')[1];

  return contentType;
};

export const formatDay = (day) => dayjs(day).format('DD MMM YYYY. hh:mm a');

export const maskData = (data) => {
  const maskedData = data.replace(/[0-9A-Za-z]/g, '*');
  return maskedData;
};

export const formatYear = (day) => dayjs(day).format('DD/MMM/YYYY');

export const totalTransactionByDate = (transactions, dates, total) => {
  if (!transactions.length) return null;
  dates.forEach((date) => {
    const creditTransactionsByDate = transactions.filter((transaction) => formatYear(transaction?.createdon) === date && transaction.inbound_order === undefined && transaction?.type === 'credit');
    const debitTransactionsByDate = transactions.filter((transaction) => formatYear(transaction?.createdon) === date && transaction?.type === 'debit');
    const customInboundTnxsByDate = transactions.filter((transaction) => formatYear(transaction?.createdon) === date && transaction?.inbound_order?.type === 'credit');
    let totalCredit = creditTransactionsByDate.reduce((total, transaction) => total + transaction?.amount, 0);
    let totalDebit = debitTransactionsByDate.reduce((total, transaction) => total + transaction?.amount, 0);
    let totalCustomInbound = customInboundTnxsByDate.reduce((total, transaction) => total + transaction?.amount, 0);

    total = {
      ...total,
      [date]: {
        totalCredit,
        totalDebit,
        totalCustomInbound,
      },
    };
  });

  return total;
};

export const isSameOrAfterNow = (reversalExpiryDate) => {
  const date1 = dayjs(reversalExpiryDate).format('DD MMM YYYY. hh:mm a');
  const date2 = dayjs(new Date()).format('DD MMM YYYY. hh:mm a');
  return dayjs(date1).isSameOrAfter(date2, 'day');
};

export const isNotAWebUser = (classification) => {
  return classification === 'web' ? { userIsWebUser: false, userType: classification } : { userIsWebUser: true, userType: classification };
};

export const externalRedirect = (url) => {
  const anchor = document.createElement('a');
  anchor.href = url;
  anchor.target = '_blank';
  anchor.click();
};
