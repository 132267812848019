import { createSlice } from '@reduxjs/toolkit';
import { getMerchantPaymentGroups, getPaymentGroupCurrentBeneficiaries, uploadFileStatus } from '../../services/apiSevices';

const bulkPaymentFeatureSlice = createSlice({
  name: 'bulkPaymentFeature',
  initialState: {
    groups: [],
    groupName: '',
    groupID: '',
    beneficiariesCount: 0,
    groupDescription: '',
    currentBeneficiaries: [],
    currentBeneficiary: [],
    loadingPaymentGroups: false,
    loadingCurrentBeneficiaries: false,
    loadingCurrentBeneficiary: false,
    error: null,
    showPinPopup: false,
    payTitle: '',
    bonus: '',
    flatrate: '',
    csvListPopup: false,
    logPopup: false,
    payStatusLog: false,
    failedBeneficiaries: [],
    bulkPaymentStatus: [],
    uploadStatus: [],
    beneficiary_id: '',
    group_id_delete: '',
    uploadStatusCount: 0,
    showDeletePopup: false,
    totalUploadCount: 0,
    refreshCount: 0,
  },
  reducers: {
    setPaymentGroups: (state, { payload }) => {
      state.groups = payload.groups;
    },
    setPaymentGroupName: (state, { payload }) => {
      state.groupName = payload;
    },
    setPaymentGroupID: (state, { payload }) => {
      state.groupID = payload;
    },
    delPaymentGroupID: (state) => {
      state.groupID = '';
    },
    setPaymentGroupDesc: (state, { payload }) => {
      state.groupDescription = payload;
    },
    setBeneficiariesCount: (state, { payload }) => {
      state.beneficiariesCount = payload;
    },
    setCurrentBeneficairies: (state, { payload }) => {
      state.currentBeneficiaries = payload.beneficiaries;
    },
    setCurrentBeneficiary: (state, { payload }) => {
      state.currentBeneficiary = payload;
    },
    setLoadingPaymentGroups: (state, { payload }) => {
      state.loadingPaymentGroups = payload;
    },
    setLoadingCurrentBeneficiaries: (state, { payload }) => {
      state.loadingCurrentBeneficiaries = payload;
    },
    setPaymentGroupsError: (state, { payload }) => {
      state.error = payload;
    },
    resetCurrentBeneficiaries: (state) => {
      state.currentBeneficiaries = [];
    },
    setShowPinPopup: (state, { payload }) => {
      state.showPinPopup = payload;
    },
    setPayTitle: (state, { payload }) => {
      state.payTitle = payload;
    },
    setBonus: (state, { payload }) => {
      state.bonus = payload;
    },
    setFlatrate: (state, { payload }) => {
      state.flatrate = payload;
    },
    setCsvListPopup: (state, { payload }) => {
      state.csvListPopup = payload;
    },
    setLogPopup: (state, { payload }) => {
      state.logPopup = payload;
    },
    setBulkPaymentStatus: (state, { payload }) => {
      state.bulkPaymentStatus = payload;
    },
    setFailedBeneficiaries: (state, { payload }) => {
      state.failedBeneficiaries.push(payload);
    },
    setUploadStatus: (state, { payload }) => {
      state.uploadStatus = payload;
    },
    setBeneficiaryId: (state, { payload }) => {
      state.beneficiary_id = payload;
    },
    setGroupIdDelete: (state, { payload }) => {
      state.group_id_delete = payload;
    },
    setPayStatusLog: (state, { payload }) => {
      state.payStatusLog = payload;
    },
    setUploadStatusCount: (state, { payload }) => {
      state.uploadStatusCount = payload;
    },
    setShowDeletePopup: (state, { payload }) => {
      state.showDeletePopup = payload;
    },
    setTotalUploadCount: (state, { payload }) => {
      state.totalUploadCount = payload;
    },
    setRefreshCount: (state, { payload }) => {
      state.refreshCount = payload;
    },
  },
});

const getCurrentBeneficiaries =
  ({ remote, action }) =>
  async (dispatch) => {
    dispatch(setLoadingCurrentBeneficiaries(true));
    try {
      const { data } = await remote();
      if (data?.status) {
        dispatch(action(data?.data));
        dispatch(setLoadingCurrentBeneficiaries(false));
      } else {
        dispatch(setPaymentGroupsError(data?.message));
        dispatch(setLoadingCurrentBeneficiaries(false));
      }
    } catch (err) {
      console.log(err);
      dispatch(setPaymentGroupsError(err.response?.data?.message));
      dispatch(setLoadingCurrentBeneficiaries(false));
    }
  };

const getPaymentGroups =
  ({ remote, action }) =>
  async (dispatch) => {
    dispatch(setLoadingPaymentGroups(true));

    try {
      const { data } = await remote();
      if (data?.status) {
        dispatch(action(data?.data));
        dispatch(setLoadingPaymentGroups(false));
      } else {
        dispatch(setPaymentGroupsError(data?.message));
        dispatch(setLoadingPaymentGroups(false));
      }
    } catch (err) {
      console.log(err?.response);
      dispatch(setPaymentGroupsError(err.response?.data?.message));
      dispatch(setLoadingPaymentGroups(false));
    }
  };

const uploadStatusGet =
  ({ remote, action }) =>
  async (dispatch) => {
    try {
      const { data } = await remote();
      if (data?.status) {
        dispatch(action(data?.data));
      } else {
        dispatch(setPaymentGroupsError(data?.message));
      }
    } catch (err) {
      console.log(err?.response);
      dispatch(setPaymentGroupsError(err.response?.data?.message));
    }
  };

export const getUploadStatus = (key, group, merchant) => async (dispatch) => {
  await uploadStatusGet({ remote: () => uploadFileStatus(key, group, merchant), action: setUploadStatus })(dispatch);
};

export const getAllCurrentBeneficiaries = (key, groupId) => async (dispatch) => {
  await getCurrentBeneficiaries({ remote: () => getPaymentGroupCurrentBeneficiaries(key, groupId), action: setCurrentBeneficairies })(dispatch);
};

export const getAllPaymentGroups = (key) => async (dispatch) => {
  await getPaymentGroups({ remote: () => getMerchantPaymentGroups(key), action: setPaymentGroups })(dispatch);
};

export const {
  setPaymentGroups,
  setPaymentGroupName,
  setPaymentGroupID,
  setPaymentGroupDesc,
  setCurrentBeneficairies,
  setCurrentBeneficiary,
  setBeneficiariesCount,
  setLoadingPaymentGroups,
  setLoadingCurrentBeneficiaries,
  setPaymentGroupsError,
  resetCurrentBeneficiaries,
  setShowPinPopup,
  setBonus,
  setFlatrate,
  setPayTitle,
  setCsvListPopup,
  setLogPopup,
  setSuccessfulBeneficiaries,
  setFailedBeneficiaries,
  setUploadStatus,
  setBeneficiaryId,
  setBulkLoaderPopup,
  setUploadStatusCount,
  setBulkPaymentStatus,
  setPayStatusLog,
  setShowDeletePopup,
  setGroupIdDelete,
  delPaymentGroupID,
  setTotalUploadCount,
  setRefreshCount,
} = bulkPaymentFeatureSlice.actions;
const merchantBulkPaymentReducer = bulkPaymentFeatureSlice.reducer;
export default merchantBulkPaymentReducer;
