import _ from 'lodash';

export const formatNumber = (number = 0) => {
  return parseFloat(number).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const formatMoney = (money) => {
  return new Intl.NumberFormat('en', {
    style: 'decimal',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(money);
};

export const lastPointCustomer = _.memoize(function (pointCustomers) {
  return pointCustomers.find((pointCustomer) => pointCustomer.paymentstatus !== 'pending');
});

export const modifyPhoneNumber = (value) => {
  let newValue = value;
  if (newValue) {
    newValue = value.replace('+', '');
    if (newValue.startsWith('234')) {
      if (newValue.length > 3) {
        if (newValue[3] === '0') {
          newValue = '' + newValue.substring(3);
        } else {
          newValue = '0' + newValue.substring(3);
        }
      } else {
        newValue = '0' + newValue.substring(3);
      }
    }
  }
  return newValue;
};

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export const mungePhoneNumber = (s) => {
  let i = s.indexOf(s[7]);
  let startIndex = (i * 0.5) | 0;
  let endIndex = (i * 1.2) | 0;
  return s.slice(0, startIndex) + s.slice(startIndex, endIndex).replace(/./g, '*') + s.slice(endIndex);
};
