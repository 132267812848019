export const addCashpointValidate = (values) => {
  let errors = {};
  if (!values.contactfirstname) {
    errors.contactfirstname = 'First Name is required';
  }
  if (!values.contactlastname) {
    errors.contactlastname = 'Last Name is required';
  }

  if (!values.phone) {
    errors.phone = 'Phone number is required';
  }
  if (!/\S+@\S+\.\S+/.test(values.email) && values.email) {
    errors.email = 'Email address is invalid';
  }
  return errors;
};
