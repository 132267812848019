import { useFlags } from 'flagsmith/react';

export const useFeatureFlag = (flagName) => {
  const flags = useFlags([flagName]);

  return {
    isFlagRequired: flags[flagName].enabled,
    flagValue: flags[flagName].value,
  };
};
