import { Link } from 'react-router-dom';
import AppButton from '../app_button/app_button';
import AppIcon from '../app_icon/app_icon';
import Footer from '../footer/footer';
import Navigation from '../navigation/navigation';

export default function Layout({ children }) {
  return (
    <div className="p-relative">
      <Navigation />
      <div className="full-height">
        <div className="full-height full-width">{children}</div>
        <Footer />
      </div>
    </div>
  );
}

export const Spacer = ({ width, height, className }) => <div style={{ width, height }} className={className || ''}></div>;

export const ColouredBackground = ({ children, bottomText, route, linkText, lastText, onClick }) => (
  <div className="full-width overflow-scroll page-color-height page-color-background d-flex flex-vertical align-center app-padding-starter-tb">
    <div className="mb-30 mb-0">
      <AppIcon height={45} />
    </div>
    {children}
    {bottomText && (
      <>
        <span className="font-size-17 fw400 text-center mt30 ">
          {bottomText}{' '}
          <Link to={route || '/'} className="action-color text-dec-none ml5 font-size-17">
            {linkText}
          </Link>
        </span>
        <span onClick={onClick} className="font-size-17 fw400 text-center c-pointer" style={{ opacity: '0.5' }}>
          {lastText}
        </span>
      </>
    )}
  </div>
);

export const FormContainer = ({ children, className }) => (
  <div className={(className || '') + ' border-radius5  app-padding-starter-tb form-padding-lr primary-bg-color d-flex flex-vertical align-center'}>{children}</div>
);

export const FormLayout = ({ children, headerText, buttonText, bottomText, onClick, isBusy, goBackFlag, goBackClick }) => (
  <FormContainer className="form-layout-width fade-in">
    <p className="text-uppercase letter-spacing-em mt-20 font-size-clamp header-color mb-50 text-center">{headerText}</p>
    <Spacer height={30} />
    {children}
    {!goBackFlag && (
      <AppButton
        isActive={true}
        name={buttonText}
        isBusy={isBusy}
        onClick={onClick}
        className="action-color-2 no-padding form-btn-width primary-color h-50 font-size-18 mb-30"
        type="submit"
        height={51}
      />
    )}
    {goBackFlag && (
      <div className="d-flex align-center j-space-between full-width">
        <AppButton name="Back" isActive={true} isBusy={false} onClick={goBackClick} className="more-details-btn font-size-20 text-color" />
        <AppButton isActive={true} name={buttonText} isBusy={isBusy} onClick={onClick} className="action-color-2 more-details-btn primary-color  font-size-20" type="submit" />
      </div>
    )}
    {bottomText && <p className="font-size-16 fw400 text-center">{bottomText}</p>}
  </FormContainer>
);
