import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import iconClose from '../../assets/images/iconClose.svg';
import useTour from '../../hooks/use_tour';
import useURLType from '../../hooks/use_url_type';
import { TOUR_TYPE, changeTourVisibilty, decreaseTourIndex, increaseTourIndex, tourSelector } from '../../redux/tour/tour.feature';
import colors from '../../utils/color/colors';
import AppButton from '../app_button/app_button';
import { Spacer } from '../layout/layout';
import './tour_wrapper.css';

export default function TourWrapper({ title, content, children, contentClass, className, index, style }) {
  const history = useHistory();
  const { tourIndex, visible } = useSelector(tourSelector);
  const tourType = useURLType();
  const totalIndex = () => {
    switch (tourType?.toLocaleLowerCase()) {
      case TOUR_TYPE.MERCHANT:
        return 4;
      case TOUR_TYPE.CASHIER:
        return 2;
      default:
        return;
    }
  };
  const lastTourItem = tourIndex === totalIndex();
  const dispatch = useDispatch();
  const disableTour = () => {
    dispatch(changeTourVisibilty(false));
    history.push(tourType === 'merchant' ? '/admin/merchant' : '/admin/cashpoint');
  };
  const decrementTour = () => {
    dispatch(decreaseTourIndex());
  };
  const incrementTour = () => {
    if (lastTourItem) {
      disableTour();
    } else {
      dispatch(increaseTourIndex());
    }
  };

  const tourClassVisible = visible && tourIndex === index;
  // console.log({ tourIndex, index })
  useTour(visible, tourIndex);
  const { textColor } = colors();

  return (
    <>
      {tourClassVisible && (
        <div className="p-fixed tour-bg">
          <div className="p-absolute tour-actions d-flex align-center j-center">
            <AppButton isActive={tourIndex !== 1} className={tourIndex === 1 ? '' : 'tour-button-left'} onClick={decrementTour} name={tourIndex === 1 ? '' : 'Back'} />
            <Spacer width={40} />
            <AppButton isActive={true} className="tour-button-right" onClick={incrementTour} name={lastTourItem ? 'End Tour' : 'Next'} />
          </div>
        </div>
      )}
      <div className={`${tourClassVisible ? 'p-relative z-index200 border-radius15' : ''}`}>
        <div className={`${tourClassVisible ? (className || '') + ' wrapper-widget p-relative' : ''}`}>
          {children}
          {tourClassVisible && <div className="p-absolute disable-widget"></div>}
        </div>
        {tourClassVisible && (
          <>
            <div className={`${contentClass || ''} tour-content p-fixed`} style={{ ...(style || {}) }}>
              <div className="d-flex align-center j-space-between">
                <p className="text-color text-bold">{title}</p>
                <img onClick={disableTour} className="c-pointer" src={iconClose} alt="close action" />
              </div>
              <Spacer height={2} />
              <div style={{ color: textColor.withOpacity(0.54) }}>{content}</div>
              <Spacer height={15} />
              <ProgressBar width={index / totalIndex()} />
            </div>
          </>
        )}
      </div>
    </>
  );
}

const ProgressBar = ({ width }) => {
  return (
    <div className="progress-container">
      <div className="progress-inner" style={{ width: `${(width || 0) * 100}%` }}></div>
    </div>
  );
};
