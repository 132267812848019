import axios from 'axios';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { openNotificationWithIcon } from '../../pages/auth/primary_signup/primary_signup';
import LoadingScreen from '../../pages/merchant/authorizations/loadingScreen';
import { signInSuccess } from '../../redux/user/user_actions';
import { getCurrentUserFromMobileApp } from '../apiSevices';

export const AppEntry = () => {
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const token = searchParams.get('token');
  const history = useHistory();
  const dispatch = useDispatch();
  const { data, isLoading, isError, isFetched } = useQuery({ queryKey: ['getUser'], queryFn: () => getCurrentUserFromMobileApp(token), refetchInterval: false });

  useEffect(() => {
    // check credentials
    if (token === null || token === undefined || token === '') {
      openNotificationWithIcon('error', 'Error', 'Invalid token');
      history.push('/login');
      return null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const gotoDashboard = () => {
    setLocalStorage();
    history.replace('/admin/merchant/authorizations');
  };

  const setLocalStorage = () => {
    localStorage.clear();
    dispatch(signInSuccess(data?.data));
    localStorage.setItem('v_pay_token', token);
    axios.defaults.headers.accessToken = token;
    axios.defaults.headers['b-access-token'] = token;
  };

  return (
    <div>
      {isLoading && <LoadingScreen />}
      {isFetched && gotoDashboard()}
    </div>
  );
};
