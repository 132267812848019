import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Divider } from 'antd';
import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import shieldIcon from '../../assets/images/shield-check.svg';
import { useOutsideRef } from '../../hooks/useOutsideClick';
import useVerifiedUser from '../../hooks/use_user_verified';
import { fetchUserMerchant, openCreateMerchant } from '../../redux/merchant/merchant_actions';
import { setCurrentMerchant } from '../../redux/user/user_actions';
import { isNotAWebUser } from '../../utils/helper';
import { Spacer } from '../layout/layout';
import { LogoutPopup } from '../popup/popup';

export function CardDropDown({ className }) {
  const [showLogout, setShowLogout] = useState(false);
  const { classification } = useSelector((state) => state?.merchant?.merchantDetails);
  const { userIsWebUser, userType } = isNotAWebUser(classification);
  const { hasBVNorNIN, hasIDCard, hasLivelinessVideo, hasBeenVerified } = useVerifiedUser();
  const history = useHistory();
  const [show, setShow] = useState(false);
  const location = useLocation();
  const merchants = useSelector((state) => state.user.user.merchants);
  const currentMerchant = useSelector((state) => state.user.currentMerchant);
  const dispatch = useDispatch();
  const dropdownRef = useRef(null);

  function setShowFalse() {
    setShow(false);
  }

  useOutsideRef(dropdownRef, setShowFalse);

  const onAddBusinessClick = () => {
    history.push({
      pathname: '/admin/merchant',
    });
    setShow(false);
    setTimeout(() => {
      openCreateMerchant(dispatch);
    }, 0);
  };

  const onSignOutClick = () => {
    setShowLogout(true);
  };

  const onMerchantClick = (id) => {
    dispatch(setCurrentMerchant(id));
    dispatch(fetchUserMerchant(id));
    setShow(false);
    if (location.pathname === '/admin/merchant/bonus' || location.pathname === '/admin/merchant/referral') {
      history.push('/admin/merchant/');
    }
  };
  const goToCompliance = () => {
    history.push('/admin/merchant/compliance');
  };

  let isVerified;
  if (hasBVNorNIN && hasIDCard) {
    isVerified = true;
  } else if (hasBVNorNIN && hasLivelinessVideo) {
    isVerified = true;
  } else {
    isVerified = false;
  }

  return (
    <>
      <button ref={dropdownRef} className={(className || '') + ' unstyle-button c-pointer card-dropdown full-width'}>
        <div onClick={() => setShow(!show)} className="d-flex j-space-between card-dropdown-item align-center">
          <div>
            <p className="merchant-name text-cut">{currentMerchant.businessname}</p>
            {userIsWebUser && (
              <p>
                {isVerified || hasBeenVerified ? currentMerchant.nuban : 'Unverified Account'} |<span className="font-size-10">{isVerified || hasBeenVerified ? ' VFD MFB' : 'NA'}</span>
              </p>
            )}
          </div>
          <FontAwesomeIcon icon={faAngleDown} />
        </div>
        <div style={{ padding: '0px 20px' }}>{show && <Divider style={{ borderBottom: '0.5px solid rgba(185, 185, 185, 0.3)', margin: 'auto' }} />}</div>
        {(hasBVNorNIN && isVerified) || hasBeenVerified
          ? show && (
              <div className="card-dropdown-item below overflow-scroll vh80">
                {merchants &&
                  merchants.map((merchant, merchantIdX) => (
                    <div onClick={() => onMerchantClick(merchant.businessid)} key={merchantIdX}>
                      <p className="text-cut">{merchant.businessname}</p>
                      <Spacer height={20} />
                    </div>
                  ))}
                <p onClick={onAddBusinessClick} className="top-bottom-border">
                  Add New Business
                </p>
                <Spacer height={10} />
                <p onClick={onSignOutClick}>Sign Out</p>
              </div>
            )
          : show && (
              <div className="card-dropdown-item below overflow-scroll vh80">
                <div className="d-flex" onClick={() => goToCompliance()}>
                  <p>Verify Your Account</p>
                  <img src={shieldIcon} />
                </div>
                <Spacer height={10} />
                <p onClick={onSignOutClick}>Sign Out</p>
              </div>
            )}
      </button>
      {showLogout && <LogoutPopup setShow={setShow} onClose={() => setShowLogout(false)} />}
    </>
  );
}
