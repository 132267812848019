import { createSelector } from 'reselect';

export const selectLoading = createSelector(
  (state) => state.user,
  (user) => user.loading,
);

export const selectSignupCredentials = createSelector(
  (state) => state.user,
  (user) => user.signUpCredentials,
);

export const selectUserError = createSelector(
  (state) => state.user,
  (user) => user.error,
);
