import { createSlice } from '@reduxjs/toolkit';
import { getInLS, saveToLS } from '../../utils/local';

export const TOUR_TYPE = {
  CASHIER: 'cashpoint',
  MERCHANT: 'merchant',
  DEFAULT: '',
};

const checkTourState = () => {
  const userDidTour = getInLS('tour');
  //   console.log({ userDidTour }, !userDidTour);
  return !userDidTour;
};
const tourSlice = createSlice({
  name: 'tour',
  initialState: {
    visible: checkTourState(),
    tourIndex: 1,
  },
  reducers: {
    increaseTourIndex: (state) => {
      state.tourIndex += 1;
    },
    decreaseTourIndex: (state) => {
      state.tourIndex -= 1;
    },
    changeTourVisibilty: (state, { payload }) => {
      saveToLS('tour', 'xertre');
      state.visible = payload;
    },
    changeTourType: (state, { payload }) => {
      state.tourType = payload;
    },
  },
});
const tourReducer = tourSlice.reducer;

export const { increaseTourIndex, changeTourType, decreaseTourIndex, changeTourVisibilty } = tourSlice.actions;

export const tourSelector = (state) => state.tour;
export default tourReducer;
