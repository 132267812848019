import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { openNotificationWithIcon } from '../pages/auth/primary_signup/primary_signup';
import { getRecieptInfo } from '../services/apiSevices';

export const useRecieptDetails = (merchant, isOutbound, id) => {
  const [recieptDetails, setReceiptDetails] = React.useState(null);
  const location = useLocation();
  const merchantIdFromState = useSelector((state) => state.user?.currentMerchant?.businessid);
  const merchantID = merchant || merchantIdFromState;
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const res = await getRecieptInfo(merchantID, id);
        setReceiptDetails(res.data);
        setLoading(false);
      } catch (error) {
        openNotificationWithIcon('warning', 'Ooopps...');
        setLoading(false);
      }
    };
    if (isOutbound && location.pathname === '/admin/merchant/history') {
      fetchData();
    }
    /* eslint-disable react/display-name */
    return () => null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return { recieptDetails, loading };
};
