import { LoadingOutlined } from '@ant-design/icons';
import { faCheckCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useBenficiaryPaginate } from '../../hooks/use_paginate';
import { getBankName } from '../../pages/merchant/settings';
import { setBeneficiaryId, setGroupIdDelete, setShowDeletePopup } from '../../redux/merchant/bulk_payment.feature';
import { getPaymentGroupCurrentBeneficiaries } from '../../services/apiSevices';
import { formatNumber } from '../../utils/formatNumber';
import { AppButtonWithIcon } from '../app_button/app_button';
import { Spacer } from '../layout/layout';
import { EmptyView, MerchantContentWrapper } from '../merchant_widgets/merchant_widgets';

// css
import '../merchant_widgets/merchant_widgets.css';
import './bulk_table.css';

const spinner = (
  <div>
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
    />
  </div>
);

export const BulkTable = ({ showButton, name, error, onClick, columns, data, count, onClickEdit, onClickDel, onChange, tableLoader, banks }) => {
  return (
    <>
      <div className="bulk-table-container rounded-md no-mobile-but-desktop fade-in">
        <BulkHeader name={name} onClick={onClick} count={count} onChange={onChange} showButton={showButton} />
        <AppTable error={error} headers={columns} data={data} onClickEdit={onClickEdit} onClickDel={onClickDel} tableLoader={tableLoader} banks={banks} />
      </div>
    </>
  );
};

export const BulkHeader = ({ onClick, onChange, showButton }) => {
  const { groupName, currentBeneficiaries } = useSelector((state) => state.bulkPayment);
  return (
    <div className="full-width table-header d-flex j-space-between align-center">
      <div className="p10 text-bold-500 fw500 d-flex j-space-between align-center hundredPx">
        <span className="ellipsis text-center ">{groupName} </span> <span>| {currentBeneficiaries.length}</span>
      </div>
      <div className="d-flex">
        <input className="" onChange={onChange} autoComplete="off" placeholder="Search bank" type="text" name="search-text" />
        <Spacer width={10} />
        {showButton && <AppButtonWithIcon name="Add Beneficiary" className="w-75 p-small text-white bulk-add-button-color" onClick={onClick} isActive isBusy={false} color backgroundColor />}
      </div>
    </div>
  );
};
export const TableSearchBar = ({ setSearchItem, setShowAddBeneficiary }) => {
  const { beneficiariesCount, groupName } = useSelector((state) => state.bulkPayment);
  return (
    <div className="d-flex j-center align-center tableSearch">
      <div className="segment_1 w-50 d-flex justify-start align-center">
        <div className="groupName">{groupName}</div>
        <Spacer width={30} />
        <div className="count text-faded2">{beneficiariesCount}</div>
      </div>
      <div className="segment_2 full-width d-flex justify-end">
        <input
          className="searchBeneficiary"
          onChange={(evt) => {
            setSearchItem(evt.target.value);
          }}
          autoComplete="off"
          placeholder="Search for beneficiary...."
          type="text"
          name="search-text"
        />
        <Spacer width={30} />
        <AppButtonWithIcon
          name="Add Beneficiary"
          className="w-25 p-small text-white bulk-add-button-color d-flex j-center align-center"
          onClick={() => {
            setShowAddBeneficiary(true);
          }}
          isActive
          isBusy={false}
          color
          backgroundColor
          space={20}
        />
      </div>
    </div>
  );
};

export const BulkBeneficiariesList = ({ groupID, searchItem }) => {
  const publickey = useSelector((state) => state.user.currentMerchant['publickey']);
  const [pageNumber, setPageNumber] = useState(1);
  const { currentBeneficiaries } = useSelector((state) => state.bulkPayment);

  const searchBeneficiary = useCallback(
    (data) => {
      return data?.filter((item) => item.beneficiary_account_name?.toLowerCase()?.includes(searchItem.toLowerCase()));
    },
    [searchItem],
  );

  const bulkPayouts = useCallback(async () => {
    const res = await getPaymentGroupCurrentBeneficiaries(publickey, groupID, pageNumber);
    return res?.data?.data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, groupID]);

  const { data, lastElementRef, loading } = useBenficiaryPaginate(groupID, pageNumber, setPageNumber, bulkPayouts);

  const filteredData = searchBeneficiary(data);

  const headers = ['Account Name', 'Account Number', 'Bank Name', 'Amount', ''];
  return (
    <section>
      {data && data.length > 0 ? (
        <>
          <div className="desktop-history-trans no-mobile table-padding primary-bg-color full-width border-radius5 height_auto">
            <div className="d-flex desktop-history-trans-header">
              {headers.map((header) => (
                <p className="font-size-15 fw500" key={header}>
                  {header}
                </p>
              ))}
            </div>
            <div>
              {data && filteredData?.length > 0 ? (
                filteredData?.map((group, idx) => {
                  return (
                    <Fragment key={idx}>
                      <Beneficiary group={group} ref={data?.length === idx + 1 ? lastElementRef : null} idx={idx} />
                    </Fragment>
                  );
                })
              ) : (
                <>
                  <Spacer className="no-desktop-but-mobile" height={100} />
                  <div className="d-flex align-center no-mobile j-center full-width full-height">
                    {filteredData.length < 1 && (
                      <EmptyView
                        title="no matched beneficiary"
                        description={
                          <>
                            You don't have such beneficiary.
                            <br /> Search for beneficiaries that you have.
                          </>
                        }
                      />
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
          {loading && <Spacer height={20} />}
          <div className="full-width d-flex align-center j-center">{loading && <LoadingOutlined style={{ fontSize: '1em' }} size="large" />}</div>
        </>
      ) : (
        <>
          <Spacer className="no-desktop-but-mobile" height={100} />
          <div className="d-flex align-center no-mobile j-center full-width full-height">
            {!loading && (
              <EmptyView
                title="No Beneficiary"
                description={
                  <>
                    You do not have any beneficiary, click on the button
                    <br />
                    above to add a new beneficiary.
                  </>
                }
              />
            )}
            {loading && data.length < 1 && <LoadingOutlined style={{ fontSize: '1em' }} size="large" />}
          </div>
        </>
      )}
    </section>
  );
};

export const AppTable = ({ headers, data, tableLoader, error, banks }) => {
  const dispatch = useDispatch();
  const handleClick = (merchant_id) => {
    dispatch(setBeneficiaryId(merchant_id));
    dispatch(setShowDeletePopup(true));
  };

  return (
    <div className="table-container fade-in">
      <table className="full-width p-relative">
        <thead className="">
          <tr>
            {headers.map((header) => {
              return (
                <th scope="col" key={header} className="table-header">
                  {header}
                </th>
              );
            })}
          </tr>
        </thead>
        {tableLoader && (
          <tbody>
            <tr className="empty-component-center">
              <td className="d-flex j-center align-center vh-50">{spinner}</td>
            </tr>
          </tbody>
        )}
        {!tableLoader && data.length < 1 && error && (
          <tbody>
            <tr className="empty-component-center fade-in">
              <td className="d-flex j-center align-center vh-50">
                <div>
                  <Spacer height={50} />
                  <EmptyView
                    title="Create Group"
                    description={
                      <>
                        You do not have any beneficiary, click on the button
                        <br />
                        above to add a new beneficiary.
                      </>
                    }
                  />
                </div>
              </td>
            </tr>
          </tbody>
        )}
        {!tableLoader && data.length < 1 && !error && (
          <tbody>
            <tr className="empty-component-center fade-in">
              <td className="d-flex j-center align-center vh-50">
                <div>
                  <Spacer height={50} />
                  <EmptyView
                    title="Create Group"
                    description={
                      <>
                        You do not have any beneficiary, click on the button
                        <br />
                        above to add a new beneficiary.
                      </>
                    }
                  />
                </div>
              </td>
            </tr>
          </tbody>
        )}
        {!tableLoader && data.length > 0 && !error && (
          <tbody className="full-width p-relative">
            {data.map((datum) => {
              return (
                <tr key={datum._id} className={`p-relative fade-in`}>
                  <td className="log_success">
                    <FontAwesomeIcon icon={faCheckCircle} />
                  </td>
                  <td>{datum?.beneficiary_account_name}</td>
                  <td>{datum?.beneficiary_account_number}</td>
                  <td>{getBankName(datum?.beneficiary_bank, banks)}</td>
                  <td>₦{formatNumber(datum?.amount)}</td>
                  <td>
                    <FontAwesomeIcon icon={faTrash} className="c-pointer del-icon" onClick={() => handleClick(datum._id)} />
                  </td>
                </tr>
              );
            })}
          </tbody>
        )}
      </table>
    </div>
  );
};

export const LoyaltyTable = ({ headers, data, tableLoader, error }) => {
  const dispatch = useDispatch();
  const handleClick = (merchant_id) => {
    dispatch(setBeneficiaryId(merchant_id));
    dispatch(setShowDeletePopup(true));
  };

  return (
    <div className="loyalty-table-container fade-in">
      <table className="full-width p-relative">
        <thead className="">
          <tr>
            {headers.length > 0 &&
              headers.map((header) => {
                return (
                  <th scope="col" key={header} className="table-header">
                    {header}
                  </th>
                );
              })}
          </tr>
        </thead>
        {tableLoader && (
          <tbody>
            <tr className="empty-component-center">
              <td className="d-flex j-center align-center vh-50">{spinner}</td>
            </tr>
          </tbody>
        )}
        {!tableLoader && data.length < 1 && error && (
          <tbody>
            <tr className="empty-component-center fade-in">
              <td className="d-flex j-center align-center vh-50">
                <div>
                  <Spacer height={50} />
                  <EmptyView title="No Transacting Customer" description={<>You do not have any record for transacting customers at the moment.</>} height={true} />
                </div>
              </td>
            </tr>
          </tbody>
        )}
        {!tableLoader && data.length < 1 && !error && (
          <tbody>
            <tr className="empty-component-center fade-in">
              <td className="d-flex j-center align-center vh-50">
                <div>
                  <Spacer height={50} />
                  <EmptyView height={true} title="No Transacting Customer" description={<>You do not have any record for transacting customers at the moment.</>} />
                </div>
              </td>
            </tr>
          </tbody>
        )}
        {!tableLoader && data.length > 0 && !error && (
          <tbody className="full-width p-relative">
            {data.map((datum) => {
              return (
                <tr key={datum._id} className={`p-relative fade-in`}>
                  <td>{datum.customer_name}</td>
                  <td>{datum.phone_number}</td>
                  <td>{datum.date_joined}</td>
                  <td>₦{formatNumber(datum.amount)}</td>
                  <td>
                    <div className="ellipsis">
                      <div>.</div>
                      <div>.</div>
                      <div>.</div>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        )}
      </table>
    </div>
  );
};

export const PaymentGroups = ({ groups }) => {
  const headers = ['Group Name', 'Total Beneficiaries', 'Time/Date Created', ''];

  return (
    <MerchantContentWrapper>
      <Spacer height={40} className="no-mobile" />
      <section>
        {groups && groups?.length > 0 ? (
          <>
            <div className="desktop-history-trans no-mobile table-padding primary-bg-color full-width border-radius5 height_auto">
              <div className="d-flex desktop-history-trans-header">
                {headers.map((header) => (
                  <p className="font-size-15 fw500" key={header}>
                    {header}
                  </p>
                ))}
              </div>
              <div>
                {groups &&
                  groups.map((group, idx) => {
                    return (
                      <Fragment key={idx}>
                        <PaymentGroup group={group} />
                      </Fragment>
                    );
                  })}
              </div>
            </div>
          </>
        ) : (
          <>
            <Spacer className="no-desktop-but-mobile" height={100} />
            <div className="d-flex align-center no-mobile j-center full-width full-height">
              {
                <EmptyView
                  title="No Payment Group"
                  description={
                    <>
                      You do not have any payment group, check the previous tab
                      <br />
                      to add a new payment group.
                    </>
                  }
                />
              }
            </div>
          </>
        )}
      </section>
    </MerchantContentWrapper>
  );
};

const Beneficiary = React.forwardRef(({ group }, ref) => {
  const dispatch = useDispatch();
  const banks = useSelector((state) => state.merchant.banks);
  const handleClick = (group_id) => {
    dispatch(setShowDeletePopup(true));
    dispatch(setBeneficiaryId(group_id));
  };

  return (
    <>
      <div ref={ref} className="trans-item-wrapper slide-up" id="group-item-wrapper">
        <div>
          <p className="font-size-13 fw400">{group?.beneficiary_account_name}</p>
        </div>
        <div>
          <p className="font-size-13 fw400 narrative">{group?.beneficiary_account_number}</p>
        </div>
        <div>
          <p className="font-size-13 fw400 trans-ref">{getBankName(group?.beneficiary_bank, banks)}</p>
        </div>
        <div>
          <p className="font-size-13 fw400 trans-ref">₦{formatNumber(group?.amount)}</p>
        </div>
        <div>
          <p className="font-size-13 fw400">
            <FontAwesomeIcon icon={faTrash} className="c-pointer del-icon" onClick={() => handleClick(group._id)} />
          </p>
        </div>
      </div>
    </>
  );
});

const PaymentGroup = ({ group }) => {
  const dispatch = useDispatch();
  const handleClick = (group_id) => {
    dispatch(setGroupIdDelete(group_id));
    dispatch(setShowDeletePopup(true));
  };

  return (
    <>
      <div className="trans-item-wrapper  slide-up">
        <div>
          <p className="font-size-13 fw400 trans-ref">{group?.name}</p>
        </div>
        <div>
          <p className="font-size-13 fw400 narrative">{group?.beneficiary_count}</p>
        </div>
        <div>
          <p className="font-size-13 fw400 trans-ref">{new Date(group?.createdAt).toLocaleString('en-GB')}</p>
        </div>
        <div>
          <p className="font-size-13 fw400">
            <FontAwesomeIcon icon={faTrash} className="c-pointer del-icon" onClick={() => handleClick(group._id)} />
          </p>
        </div>
      </div>
    </>
  );
};

export const LogTable = ({ headers, data, title, failedMsg, isUpload }) => {
  const { totalUploadCount } = useSelector((state) => state.bulkPayment);
  return (
    <div className="payment-table full-width no-mobile-but-desktop fade-in text-center">
      <p className="font-size12em fw700">{title}</p>
      <Spacer height={5} />
      {isUpload && (
        <p className="font-size-13 text-capitalize fw400 letter-spacing1">
          <span className="redColor">{data?.length} </span>
          {` beneficiaries failed to upload out of`} <span className="redColor">{totalUploadCount}</span> {`uploaded - see the failure reasons below`}
        </p>
      )}
      <Spacer height={10} />
      <table className="full-width p-relative">
        <thead>
          <tr>
            {headers.map((header) => {
              return (
                <th scope="col" key={header} className="log-table-header ">
                  {header}
                </th>
              );
            })}
          </tr>
        </thead>
        {data.length < 1 && (
          <tbody className="full-width bulk-table-container">
            <tr className=" fade-in text-center" rowSpan="3">
              <td className="" rowSpan="3">
                {failedMsg}
              </td>
            </tr>
          </tbody>
        )}
        {data.length > 0 && (
          <tbody className="full-width p-relative bulk-table-container">
            {data?.map((datum, idx) => {
              return (
                <tr key={idx} className="p-relative tr-hover text-center">
                  <td>{idx + 1}</td>
                  <td>{datum?.data?.beneficiary_account_number || datum?.beneficiary_account_name}</td>
                  <td>{datum?.message}</td>
                </tr>
              );
            })}
          </tbody>
        )}
      </table>
    </div>
  );
};
