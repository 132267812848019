import React, { useState } from 'react';
import IntermediateSignUp from '../intermediate_signup/intermediate_signup';
import PrimarySignUp from '../primary_signup/primary_signup';
import SecondarySignUp from '../secondary_signup/secondary_signup';

export const SignupFormContainer = ({ children }) => {
  const [position, setPosition] = useState(0);

  const currentForm = React.Children.toArray(children)[position];
  if (React.isValidElement(currentForm)) {
    return React.cloneElement(currentForm, { position, setPosition });
  }
};

export const MultiStepRegistration = () => {
  return (
    <SignupFormContainer>
      <PrimarySignUp />
      <IntermediateSignUp />
      <SecondarySignUp />
    </SignupFormContainer>
  );
};
