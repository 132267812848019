import React from 'react';
import NotFoundPage from '../../pages/not_found_page/not_found';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({
      hasError: true,
      error: error,
      errorInfo: errorInfo,
    });
  }

  render() {
    const { hasError, error, errorInfo } = this.state;
    if (hasError) {
      return <NotFoundPage sendReport={true} error="Something went wrong, Please refresh your browser" errorInfo={{ error, errorInfo }} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
