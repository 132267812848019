import { useEffect, useState } from 'react';

import cardOverlay from '../../assets/images/cardOverlay.svg';
import arrowIcon from '../../assets/images/right-arrow.svg';
import { Spacer } from '../../components/layout/layout';

import { CashierQA } from './cashier_qa';

import './faq.css';

export const CashierFAQ = () => {
  const [show, setShow] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [current, setCurrent] = useState(null);

  const handleChange = (e) => {
    const { value } = e.target;
    setSearchValue(value);
  };

  const filteredItems = CashierQA.filter((items) => items.title.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1);

  return (
    <div className="faq p-relative">
      <div className="gradient">
        <Spacer height={10} />
        <img className="overlay" style={{ background: 'transparent' }} src={cardOverlay} alt="" />
        <p className="primary-color text-center fw400 font-size-15">
          Welcome, let's help you get started.
          <br /> Find answers from the FAQs below, start a live chat or email, Whatsapp or call us at support@vpay.africa, <br />
          <span className="fa-num">08028108892</span>,<span className="fa-num">02016339125</span>
        </p>
      </div>
      {!show && (
        <div className="faq-search-wrapper">
          <input className="faq-search fw400 font-size-15" type="search" value={searchValue} onChange={(e) => handleChange(e)} placeholder="Type to search..." />
        </div>
      )}
      {!show && <Spacer height={50} />}
      {!show && <FAQItems setShow={setShow} filteredItems={filteredItems} setCurrent={setCurrent} />}
      {show && <FaqDetails body={current} setShow={setShow} />}
    </div>
  );
};

export const FAQItems = ({ setShow, setCurrent, filteredItems }) => {
  return (
    <div className="faq-items">
      {filteredItems.map((content, idx) => (
        <FAQItem setShow={setShow} setCurrent={setCurrent} key={idx} text={content.title} body={content.content} />
      ))}
    </div>
  );
};

export const FAQItem = ({ text, setShow, setCurrent, body }) => {
  const onClick = () => {
    setCurrent(body);
    setShow(true);
  };

  const formatText = (text) => {
    const stringedText = String(text).toLowerCase();
    const length = stringedText.length - 1;
    const formattedText =
      stringedText[0].toUpperCase() + stringedText.substring(1, stringedText.length).split('home').join('Home').split('vpay').join('VPay').split('@VPay').join('@vpay').split(' i ').join(' I ');

    return formattedText[length] === '?' ? formattedText : formattedText + '?';
  };

  return (
    <div onClick={onClick} className={`d-flex align-center primary-bg-color faq-item j-space-between`}>
      <span className="fw400 font-size-14 text-break text-color">{formatText(text)}</span>
      <img src={arrowIcon} alt="" />
    </div>
  );
};

export const FaqDetails = ({ body, setShow }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="faq-detail-body slide-out">
      <button onClick={() => setShow(false)} className="unstyle-button no-padding fw700 font-size-17">
        &#x2190; Back
      </button>
      <Spacer height={20} />
      <div className="d-flex align-center j-center">{body}</div>
    </div>
  );
};
