import { useCallback, useEffect, useRef, useState } from 'react';

export const CustomCountdown = ({ onStop, reversalExpiriyTime, txnCreationTime }) => {
  const [minutesSpan, setMinutesSpan] = useState(null);
  const [secondsSpan, setSecondsSpan] = useState(null);
  let count = 0;
  let deadline = new Date(reversalExpiriyTime).getTime();
  let startDate = new Date(txnCreationTime).getTime();
  let timerInterval = useRef(null);

  function pad(n) {
    return (n < 10 ? '0' : '') + n;
  }

  const getTimeRemaining = useCallback((count) => {
    let secondsLeft = (deadline - startDate) / 1000 - count;
    if (new Date().getTime() - startDate < deadline) {
      secondsLeft = (deadline - new Date().getTime()) / 1000 - count;
    }
    secondsLeft %= 86400;
    if (secondsLeft <= 0) {
      clearInterval(timerInterval.current);
      return onStop();
    }
    setMinutesSpan(pad(Math.floor(secondsLeft / 60)));
    setSecondsSpan(pad(Math.floor(secondsLeft % 60)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initializeClock = useCallback(() => {
    getTimeRemaining(count);
    timerInterval.current = setInterval(() => {
      getTimeRemaining(count++);
    }, 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    initializeClock(deadline);
    return () => {
      clearInterval(timerInterval.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <span className="font-size-15 fw500">
      Reverse Transaction : {minutesSpan}:{secondsSpan}
    </span>
  );
};
