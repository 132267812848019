import { useFlags } from 'flagsmith/react';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getUsableNavLink } from '../constants/title';
import { useFeatureFlag } from './use_feature_flag';
import useVerifiedUser from './use_user_verified';

export const useMenuRender = () => {
  const flags = useFlags(['web.loan']);
  const isLoanEnabled = flags['web.loan'].enabled;
  const isAffiliateFlagEnabled = useFeatureFlag('web.affiliate.for.aggregator')?.isFlagRequired;
  const isLimitFlagEnabled = useFeatureFlag('web.limit.increase')?.isFlagRequired;
  const isLoyaltyDashboardEnabled = useFeatureFlag('web.loyalty.dashboard')?.isFlagRequired;
  const isWebFailedOrderEnabled = useFeatureFlag('web.failed.web.user.orders').isFlagRequired;
  const { hasBVNorNIN, hasIDCard, hasLivelinessVideo, hasBeenVerified } = useVerifiedUser();
  let { links, keys } = getUsableNavLink(1);
  const { classification, isaggregator, isaggregatoragent } = useSelector((state) => state?.merchant?.merchantDetails);
  if (!isLoanEnabled) {
    keys = keys.filter((key) => key !== '/admin/merchant/loans');
  }
  if (!isAffiliateFlagEnabled) {
    keys = keys.filter((key) => key !== '/admin/merchant/affliates');
  }
  if (!isLimitFlagEnabled) {
    keys = keys.filter((key) => key !== '/admin/merchant/authorizations');
  }
  if (!isLoyaltyDashboardEnabled) {
    keys = keys.filter((key) => key !== '/admin/merchant/loyalty');
  }
  if (!isWebFailedOrderEnabled) {
    keys = keys.filter((key) => key !== '/admin/merchant/failed_web_orders');
  }
  let isVerified;
  if (hasBVNorNIN && hasIDCard) {
    isVerified = true;
  } else if (hasBVNorNIN && hasLivelinessVideo) {
    isVerified = true;
  } else {
    isVerified = false;
  }

  const data = useMemo(() => {
    if ((hasBVNorNIN && isVerified) || hasBeenVerified) {
      if (classification && classification.toLowerCase().includes('pos')) {
        let newLinks = { ...links };
        delete newLinks['/admin/merchant/referral'];
        delete newLinks['/admin/merchant/virtual-accounts'];
        delete newLinks['/admin/merchant/failed_web_orders'];
        if (!isaggregator && !isaggregatoragent) {
          delete newLinks['/admin/merchant/affliates'];
          delete newLinks['/admin/merchant/failed_web_orders'];
          return {
            links: newLinks,
            keys: keys.filter(
              (key) => key !== '/admin/merchant/referral' && key !== '/admin/merchant/virtual-accounts' && key !== '/admin/merchant/affliates' && key !== '/admin/merchant/failed_web_orders',
            ),
          };
        }
        return {
          links: newLinks,
          keys: keys.filter((key) => key !== '/admin/merchant/referral' && key !== '/admin/merchant/virtual-accounts' && key !== '/admin/merchant/failed_web_orders'),
        };
      } else {
        let newLinks = { ...links };

        delete newLinks['/admin/merchant/bonus'];
        if (!isaggregator && !isaggregatoragent) {
          delete newLinks['/admin/merchant/affliates'];
        }
        // delete newLinks['/admin/merchant/affliates'];
        //remove retail merchants from viewing failed order, this is for web guys
        if (classification && classification.toLowerCase().includes('retail')) {
          let newLinks = { ...links };
          delete newLinks['/admin/merchant/failed_web_orders'];
          delete newLinks['/admin/merchant/virtual-accounts'];
          return {
            links: newLinks,
            keys: keys.filter((key) => key !== '/admin/merchant/failed_web_orders' && key !== '/admin/merchant/virtual-accounts'),
          };
        }
        //web users should see the web failed order
        if (classification && classification.toLowerCase().includes('web')) {
          let newLinks = { ...links };
          delete newLinks['/admin/merchant/bonus'];
          delete newLinks['/admin/merchant/virtual-accounts'];
          if (!isaggregator && !isaggregatoragent) {
            delete newLinks['/admin/merchant/affliates'];
            return {
              links: newLinks,
              keys: keys.filter((key) => key !== '/admin/merchant/bonus' && key !== '/admin/merchant/virtual-accounts' && key !== '/admin/merchant/affliates'),
            };
          }
        }
        if (classification && !classification.toLowerCase().includes('api')) {
          let newLinks = { ...links };
          delete newLinks['/admin/merchant/virtual-accounts'];
          if (!isaggregator && !isaggregatoragent) {
            delete newLinks['/admin/merchant/affliates'];
            return {
              links: newLinks,
              keys: keys.filter((key) => key !== '/admin/merchant/bonus' && key !== '/admin/merchant/virtual-accounts' && key !== '/admin/merchant/affliates'),
            };
          }

          return {
            links: newLinks,
            keys: keys.filter((key) => key !== '/admin/merchant/bonus' && key !== '/admin/merchant/virtual-accounts'),
          };
        }

        return {
          links: newLinks,
          keys: keys.filter((key) => key !== '/admin/merchant/bonus' && key !== '/admin/merchant/affliates' && key !== '/admin/merchant/failed_web_orders'),
        };
      }
    } else {
      // added a new condition for unverified to not be able to view cashpoint,referral,authorizations,bonus,affiliates,virtual acoounts and so on

      let newLinks = { ...links };
      delete newLinks['/admin/merchant/referral'];
      delete newLinks['/admin/merchant/virtual-accounts'];
      delete newLinks['/admin/merchant/bonus'];
      delete newLinks['/admin/merchant/affliates'];
      delete newLinks['/admin/merchant/authorizations'];
      delete newLinks['/admin/merchant/cashpoint'];
      delete newLinks['/admin/merchant/loans'];
      delete newLinks['/admin/merchant/bulk'];
      delete newLinks['/admin/merchant/failed_web_orders'];
      return {
        links: newLinks,
        keys: keys.filter(
          (key) =>
            key !== '/admin/merchant/referral' &&
            key !== '/admin/merchant/virtual-accounts' &&
            key !== '/admin/merchant/affliates' &&
            key !== '/admin/merchant/bonus' &&
            key !== '/admin/merchant/authorizations' &&
            key !== '/admin/merchant/cashpoint' &&
            key !== '/admin/merchant/loans' &&
            key !== '/admin/merchant/bulk' &&
            key !== '/admin/merchant/failed_web_orders',
        ),
      };

      // added a new condition for unverified to not be able to view cashpoint,referral,authorizations,bonus,affiliates,virtual acoounts and so on
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classification, keys, links, isaggregator, isaggregatoragent]);

  return data;
};
