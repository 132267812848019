import { useFlags } from 'flagsmith/react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { MERCHANT_ROLES } from '../constants/roles';
import { AUTH_ROUTES } from '../constants/title';

export const useRedirect = () => {
  const token = localStorage.getItem('v_pay_token');
  const role = useSelector((state) => state.user.role);
  const location = useLocation();
  const history = useHistory();
  let routes = AUTH_ROUTES;

  const flags = useFlags(['web.loan']);
  const isLoanEnabled = flags['web.loan'].enabled;

  if (!isLoanEnabled) {
    routes = routes.filter((key) => key !== '/admin/merchant/loans');
  }

  useEffect(() => {
    if (MERCHANT_ROLES.includes(role)) {
      if (token && !routes.includes(location.pathname)) {
        history.push('/admin/merchant');
      }
    }

    if (role === 'cashier') {
      if (token && !routes.includes(location.pathname)) {
        history.push('/admin/cashpoint');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);
};
