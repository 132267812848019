import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Checkbox, DatePicker } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import { Spacer } from '../layout/layout';
import './form_input.css';

// export const ValidationType = ({ type, error }) => {
//   const ErrorMessage = error[type];
//   return error[type] ? (
//     <span className="error-message fw400">{ErrorMessage}</span>
//   ) : ""
// }

export const FormInput = ({ label, value, disabled, inputClass, labelColor, onChange, onClick, type, noBorder, isPassword, icon, name, error, className, placeholder, readOnly, onKey, minValue }) => {
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown((passwordShown) => !passwordShown);
  };

  return (
    <div className={`input-container p-relative ${className || ''}`}>
      {label && <label className={`${labelColor ?? 'input-label'} fw400 `}>{label}</label>}
      <div className="app-input-wrapper">
        <AppInput
          inputClass={inputClass}
          onKey={onKey}
          noBorder={noBorder}
          onClick={onClick}
          readOnly={readOnly}
          disabled={disabled}
          placeholder={placeholder}
          value={value}
          name={name}
          error={error}
          onChange={onChange}
          icon={icon}
          type={type === 'password' ? (passwordShown ? 'text' : 'password') : type}
          minValue={minValue}
        />
        {isPassword && (
          <span className="show fw400 text-bold-400 c-pointer" onClick={togglePasswordVisiblity}>
            {!passwordShown ? 'Show' : 'Hide'}
          </span>
        )}
        {/* <ValidationType type={name} error={error} /> */}
      </div>
    </div>
  );
};

export const AppInput = ({ className, inputClass, readOnly, onClick, noBorder, onKey, disabled, value, onChange, placeholder, type, icon, name, error, minValue }) => {
  return (
    <div className="p-relative">
      <input
        disabled={disabled}
        onClick={onClick}
        className={`${className ? '' : ' app-input'} ${inputClass} ${error && error[name] ? 'has-error' : ''} ${noBorder ? 'no-border' : ''}`}
        name={name}
        readOnly={readOnly}
        value={value}
        onKeyDown={onKey}
        type={type}
        onChange={onChange}
        placeholder={placeholder}
        autoComplete="new-password"
        style={{ background: '#fff' }}
        min={minValue ? minValue : ''}
      />
      {icon && icon}
    </div>
  );
};

export const NairaFormInput = ({ label, value, disabled, inputClass, onChange, onClick, type, noBorder, isPassword, icon, name, error, className, placeholder, readOnly, onKey, minValue, onBlur }) => {
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown((passwordShown) => !passwordShown);
  };

  return (
    <div className={`input-container p-relative ${className || ''}`}>
      {label && <label className="input-label fw200 ">{label}</label>}
      <div className="app-input-wrapper">
        <NairaAppInput
          inputClass={inputClass}
          onKey={onKey}
          noBorder={noBorder}
          onClick={onClick}
          readOnly={readOnly}
          disabled={disabled}
          placeholder={placeholder}
          value={value}
          name={name}
          error={error}
          onChange={onChange}
          icon={icon}
          type={type === 'password' ? (passwordShown ? 'text' : 'password') : type}
          minValue={minValue}
          onBlur={onBlur}
        />

        {isPassword && (
          <span className="show fw400 text-bold-400 c-pointer" onClick={togglePasswordVisiblity}>
            {!passwordShown ? 'Show' : 'Hide'}
          </span>
        )}
        {/* <ValidationType type={name} error={error} /> */}
      </div>
    </div>
  );
};

export const NairaAppInput = ({ className, inputClass, readOnly, onClick, noBorder, onKey, disabled, value, onChange, placeholder, type, icon, name, error, minValue, onBlur }) => {
  return (
    <div className="p-relative">
      <div className="naira-prefix">₦</div>
      <input
        disabled={disabled}
        onClick={onClick}
        className={`${className ? '' : ' app-input'} ${inputClass} ${error && error[name] ? 'has-error' : ''} ${noBorder ? 'no-border' : ''}`}
        name={name}
        readOnly={readOnly}
        value={value}
        onKeyDown={onKey}
        type={type}
        onChange={onChange}
        placeholder={placeholder}
        autoComplete="new-password"
        style={{ background: '#fff' }}
        min={minValue ? minValue : ''}
        onBlur={onBlur}
      />
      {icon && icon}
    </div>
  );
};

export const SearchIcon = ({ className }) => {
  return (
    <svg className={className || ''} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="9.96667" cy="9.96667" r="9.21667" stroke="#200E32" strokeOpacity="0.3" strokeWidth="1.5" />
      <path d="M16.8662 16.8672L22.9995 23.0005" stroke="#200E32" strokeOpacity="0.3" strokeWidth="1.5" strokeLinecap="round" />
    </svg>
  );
};

export const FormCheckBox = ({ height, className, checktext, oncheckboxChange }) => {
  return (
    <div className="full-width">
      <Checkbox name={checktext} onChange={(e) => oncheckboxChange(e)}>
        {checktext && <p className={`${className ?? 'font-size-16 fw400'} text-center`}>{checktext}</p>}
      </Checkbox>
      <Spacer height={height ?? 20} />
    </div>
  );
};

export const BorderLessFormInput = ({
  label,
  value,
  disabled,
  inputClass,
  onChange,
  onClick,
  type,
  noBorder,
  isPassword,
  icon,
  name,
  error,
  className,
  placeholder,
  readOnly,
  onKey,
  minValue,
  needNairaPrefix,
}) => {
  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisiblity = () => {
    setPasswordShown((passwordShown) => !passwordShown);
  };

  return (
    <div className={`input-container p-relative ${className || ''}`}>
      {label && <label className="input-label fw200 ">{label}</label>}
      <div className="app-input-wrapper">
        <BorderLessAppInput
          inputClass={inputClass}
          onKey={onKey}
          noBorder={noBorder}
          onClick={onClick}
          readOnly={readOnly}
          disabled={disabled}
          placeholder={placeholder}
          value={value}
          name={name}
          error={error}
          onChange={onChange}
          icon={icon}
          type={type === 'password' ? (passwordShown ? 'text' : 'password') : type}
          minValue={minValue}
          needNairaPrefix={needNairaPrefix}
        />
        {isPassword && (
          <span className="show fw400 text-bold-400 c-pointer" onClick={togglePasswordVisiblity}>
            {!passwordShown ? 'Show' : 'Hide'}
          </span>
        )}
        {/* <ValidationType type={name} error={error} /> */}
      </div>
    </div>
  );
};

export const BorderLessDateInput = ({ className, onChange }) => {
  function disabledDate(current) {
    // Can not select days after today
    return current && current > moment().endOf('day');
  }

  return (
    <div className={`input-container p-relative ${className || ''}`}>
      <div className="app-input-wrapper">
        <DatePicker onChange={onChange} style={{ width: '500px' }} placeholder="Enter transaction date" disabledDate={disabledDate} />
      </div>
    </div>
  );
};

export const BorderLessAppInput = ({ className, inputClass, readOnly, onClick, noBorder, onKey, disabled, value, onChange, placeholder, type, icon, name, error, minValue, needNairaPrefix }) => {
  return (
    <div className="p-relative">
      {needNairaPrefix && <div className="naira-prefix">₦</div>}
      <input
        disabled={disabled}
        onClick={onClick}
        className={`${className ? '' : ' borderless-app-input'} ${inputClass} ${error && error[name] ? 'has-error' : ''} ${noBorder ? 'no-border' : ''}`}
        name={name}
        readOnly={readOnly}
        value={value}
        onKeyDown={onKey}
        type={type}
        onChange={onChange}
        placeholder={placeholder}
        autoComplete="new-password"
        style={{ background: '#fff' }}
        min={minValue ? minValue : ''}
      />
      {icon && icon}
    </div>
  );
};

export const BorderLessAppInputWithCount = ({ className, inputClass, readOnly, onClick, noBorder, onKey, disabled, handleAdd, handleMinus, count, onChange, placeholder, name, error, minValue }) => {
  return (
    <div className="p-relative d-flex input-container">
      <input
        disabled={disabled}
        onClick={onClick}
        className={`${className ? '' : ' borderless-app-input'} ${inputClass} ${error && error[name] ? 'has-error' : ''} ${noBorder ? 'no-border' : ''}`}
        name={name}
        readOnly={readOnly}
        value={count === 0 ? '' : count}
        onKeyDown={onKey}
        type="number"
        onChange={onChange}
        placeholder={placeholder}
        autoComplete="new-password"
        style={{ background: '#fff' }}
        min={minValue ? minValue : ''}
      />

      <div className="p-absolute minusIcon c-pointer" onClick={() => handleMinus()}>
        <FontAwesomeIcon icon={faMinus} />
      </div>
      <div className="p-absolute plusIcon c-pointer" onClick={() => handleAdd()}>
        <FontAwesomeIcon icon={faPlus} />
      </div>
    </div>
  );
};
