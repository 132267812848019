import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

export const useTransactions = (transactions) => {
  const [todayTnxTotal, setTodayTxnTotal] = useState('');
  const [tnxTotal, setTnxTotal] = useState('');
  const [tnxData, setTnxData] = useState([]);
  useEffect(() => {
    if (transactions) {
      let total = transactions.reduce((cumm, curr) => (cumm += curr.amount || curr.orderamount || curr.accountbalance || 0), 0);
      let todayTnx = transactions.map((tnx) => {
        return {
          ...tnx,
          isToday: dayjs(tnx.createdon).format('DD MMM YY') === dayjs().format('DD MMM YY'),
        };
      });

      let todayTotal = todayTnx.filter((t) => t.isToday).reduce((cumm, curr) => (cumm += curr.amount || curr.orderamount || curr.accountbalance || 0), 0);
      setTodayTxnTotal(todayTotal);
      setTnxTotal(total);

      let transactions_ = transactions
        .map((c) => {
          return {
            ...c,
            date: dayjs(c.createdon).format('MMM'),
          };
        })
        .reverse()
        .reduce((cumm, curr) => {
          cumm[curr.date] ? (cumm[curr.date] = cumm[curr.date] + (curr.amount || curr.orderamount || curr.accountbalance || 0)) : (cumm[curr.date] = curr.amount || curr.orderamount || curr.accountbalance || 0);
          return cumm;
        }, {});

      setTnxData(Object.keys(transactions_).map((t) => ({ month: t, value: transactions_[t] })));
    }
  }, [transactions]);
  return { todayTnxTotal, tnxTotal, tnxData };
};
