import { Link } from 'react-router-dom';
import appIcon from '../../assets/images/vpayLogo.svg';
import { useFeatureFlag } from '../../hooks/use_feature_flag';
import './app_icon.css';

export default function AppIcon({ height }) {

   const { isFlagRequired: shouldRedirectToNewSite } = useFeatureFlag('redirect.to.new.site')

   if(shouldRedirectToNewSite){
    return <img src={appIcon} className="app-icon" alt="vpay identity (home page)" style={{ height: `${height}px` }} />
   }

  return (
    <Link to="/" className="app-icon-link">
      <img src={appIcon} className="app-icon" alt="vpay identity (home page)" style={{ height: `${height}px` }} />
    </Link>
  );
}
