import { Skeleton } from 'antd';
import { Spacer } from '../layout/layout';
import './index.css';

const AvatarInputSkeleton = () => {
  return (
    <div className="d-flex align-center column-gap1">
      <Skeleton.Avatar active size="small" />
      <Skeleton.Button
        style={{
          width: '210px',
        }}
        active
        size="small"
      />
    </div>
  );
};

const CardInputSkeleton = () => {
  return (
    <Skeleton.Button
      style={{
        width: '210px',
        height: '120px',
        borderRadius: '5px',
      }}
      active
      size="small"
    />
  );
};

const TouchCardInputSkeleton = () => {
  return (
    <Skeleton.Button
      style={{
        width: '170px',
        height: '150px',
        borderRadius: '5px',
      }}
      active
      size="small"
    />
  );
};

export const DashboardSkeleton = () => {
  return (
    <div style={{ background: '#f7f7f7' }} className="full-width full-vh">
      <div style={{ background: '#f7f7f7' }} className="full-width mock-wrapper d-flex align-start full-vh">
        <div className="left-side-mock d-flex flex-vertical">
          <Skeleton.Input
            active
            shape="round"
            style={{
              width: '250px',
              height: '70px',
              borderRadius: '5px',
            }}
          />
          <AvatarInputSkeleton />
          <AvatarInputSkeleton />
          <AvatarInputSkeleton />
          <AvatarInputSkeleton />
          <AvatarInputSkeleton />
          <Skeleton.Input
            active
            shape="round"
            style={{
              width: '250px',
              height: '10em',
              borderRadius: '5px',
            }}
          />
        </div>
        <div className="right-side-mock d-flex flex-vertical">
          <Skeleton.Input
            active
            shape="round"
            style={{
              width: '100%',
              height: '70px',
              borderRadius: '5px',
            }}
          />
          <Spacer height={30} />
          <div className="d-flex align-start column-gap1 full-width">
            <div className="d-flex flex-vertical">
              <div className="d-flex align-center column-gap1">
                <CardInputSkeleton />
                <CardInputSkeleton />
                <CardInputSkeleton />
              </div>
              <Spacer height={30} />
              <Skeleton.Input
                active
                shape="round"
                style={{
                  width: '41rem',
                  height: '27.5rem',
                  borderRadius: '5px',
                }}
              />
            </div>
            <div className="d-flex flex-vertical">
              <div className="d-flex flex-vertical">
                <div className=" d-flex">
                  <TouchCardInputSkeleton />
                  <Spacer width={5} />
                  <TouchCardInputSkeleton />
                </div>
                <Spacer height={5} />
                <div className=" d-flex">
                  <TouchCardInputSkeleton />
                  <Spacer width={5} />
                  <TouchCardInputSkeleton />
                </div>
                <Spacer height="1em" />
                <Skeleton.Input
                  active
                  shape="round"
                  style={{
                    width: '347px',
                    height: '19.3em',
                    borderRadius: '5px',
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
