import { formatMoney } from '../../utils/formatNumber';

export const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export function generateChartData(labels, data, legendLabel) {
  return (canvas) => {
    let backgroundColor = 'rgba(226, 14, 23, 0.07';
    const ctx = canvas.getContext('2d');

    if (ctx !== null) {
      backgroundColor = ctx.createLinearGradient(0, 0, 0, 300);
      backgroundColor.addColorStop(0, 'rgba(226, 14, 23, 0.35');
      backgroundColor.addColorStop(0.5, 'rgba(226, 14, 23, 0.05');
      backgroundColor.addColorStop(1, 'rgba(226, 14, 23, 0.01');
    }

    return {
      labels,
      datasets: [
        {
          label: legendLabel,
          fill: true,
          fillColor: 'rgba(226, 14, 23, 0.2)',
          lineTension: 0.5,
          backgroundColor,
          borderColor: 'rgba(226, 14, 23, 0.8)',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'miter',
          pointBorderColor: 'rgba(226, 14, 23, 0.8)',
          pointBackgroundColor: '#fff',
          pointBorderWidth: 7,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: 'rgba(226, 14, 23, 0.8)',
          pointHoverBorderColor: 'rgba(220,220,220,0)',
          pointHoverBorderWidth: 2,
          pointRadius: 0,
          pointHitRadius: 10,
          borderWidth: 2,
          responsive: true,
          maintainAspectRatio: false,
          data,
        },
      ],
    };
  };
}

export function getChartOptions(currencyType = '₦') {
  return {
    maintainAspectRatio: false,
    responsive: true,
    legend: { display: false },
    tooltips: {
      mode: 'index',
      intersect: false,
      displayColors: false,
      titleMarginBottom: 10,
      borderWidth: 1,
      titleFontColor: '#fafafa',
      callbacks: {
        title: () => '',
        label: (tooltipItem) => {
          const { value } = tooltipItem;
          return `${currencyType} ${formatMoney(parseInt(value, 10))}`;
        },
        labelTextColor: () => '#fafafa',
      },
    },
    scales: {
      yAxes: [
        {
          id: 'y-axis-1',
          type: 'linear',
          display: true,
          position: 'right',
          ticks: {
            beginAtZero: true,
            callback: (value) => {
              return `${currencyType} ${formatMoney(value)}`;
            },
          },
          gridLines: {
            color: 'rgba(0,0,0,0)',
          },
        },
      ],
      xAxes: [
        {
          position: 'left',
          gridLines: {
            color: '#eef2f7',
          },
        },
      ],
    },
    tooltipTemplate: '',
  };
}
