export const firstFromValidate = (values) => {
  let errors = {};
  if (!values.contactfirstname) {
    errors.contactfirstname = 'First Name is required';
  }
  if (!values.contactlastname) {
    errors.contactlastname = 'Last Name is required';
  }

  if (!values.businessphone) {
    errors.businessphone = 'Phone number is required';
  }
  if (values.businessphone.length !== 11) {
    errors.businessphone = 'Phone number must be 11 characters';
  }
  if ((values.businessphone.length !== 11 || values.businessphone[0] !== '0') && values.businessphone.length > 0) {
    errors.businessphone = "Phone number must start with '0' and must be 11 characters";
  }

  if (values.password.indexOf(' ') !== -1 && values.password) {
    errors.password = 'Password must not contain any space. Please remove all leading, trailing or middle spaces.';
  }
  if (values.confirmPassword.indexOf(' ') !== -1 && values.confirmPassword) {
    errors.confirmPassword = 'Password must not contain any space. Please remove all leading, trailing or middle spaces.';
  }

  if (!values.password) {
    errors.password = 'Password is required';
  }
  if (!values.confirmPassword) {
    errors.confirmPassword = 'Password is required';
  }
  if (values.password !== values.confirmPassword) {
    errors.confirmPassword = "Passwords don't match";
  }
  if (!values.email) {
    errors.email = 'Email is required';
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = 'Email address is invalid';
  }
  return errors;
};

export const secondFormValidate = (values) => {
  let errors = {};
  if (!values.address) {
    errors.address = 'Address is required';
  }
  if (!values.businessname) {
    errors.businessname = 'Business Name is required';
  }
  if (!values.industry) {
    errors.industry = 'Industry is required';
  }

  if (!values.lga) {
    errors.lga = 'LGA is required';
  }

  if (!values.state) {
    errors.state = 'State is required';
  }

  return errors;
};

export const thirdFormValidate = (values) => {
  let errors = {};

  if (!values.account_type) {
    errors.account_type = 'Selecting an account type is required';
  }

  return errors;
};

export const addBusinessValidate = (values) => {
  let errors = {};
  if (!values.contactfirstname) {
    errors.contactfirstname = 'First Name is required';
  }
  if (!values.contactlastname) {
    errors.contactlastname = 'Last Name is required';
  }

  if (!values.businessphone) {
    errors.businessphone = 'Phone number is required';
  }
  if (!values.address) {
    errors.address = 'Address is required';
  }
  if (!values.businessname) {
    errors.businessname = 'Business Name is required';
  }
  if (!values.industry) {
    errors.industry = 'Industry is required';
  }

  if (!values.lga) {
    errors.lga = 'LGA is required';
  }

  if (!values.state) {
    errors.state = 'State is required';
  }

  if (!values.email) {
    errors.email = 'Email is required';
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = 'Email address is invalid';
  }
  return errors;
};
