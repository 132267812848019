import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import mobileApp from '../../assets/images/newVpayMobile.png';
import { openNotificationWithIcon } from '../../pages/auth/primary_signup/primary_signup';
import { closeWaitlistModal } from '../../redux/user/user_actions';
import AppButton from '../app_button/app_button';
import { Spacer } from '../layout/layout';
import Popup from '../popup/popup';
import './home_header.css';
export default function HomeHeader() {
  const history = useHistory();
  return (
    <GradientHeader>
      <div className="body-header-padding container d-flex align-center">
        <div className="d-flex flex-vertical">
          <div>
            <h1 className="font-size-5em home-header-bt fw700 line-height1 brown-text no-margin">
              Simplifying
              <br /> Payment
              <br /> Processes
            </h1>
            <Spacer height={15} />
            <p className="header-rider fw400 home-header-text">The easiest way to setup your business bank accounts, send/receive payments and verify customer bank transfers instantly.</p>
            <Spacer height={30} />
            {/* <InputWaitlist /> */}
            <div className="d-flex header-action align-center">
              <AppButton isActive={true} name="Create free account" onClick={() => history.push('/signup')} className="fw500 header-account-button" />
              <Spacer width={40} />
              <button className="unstyle-button fw500 c-pointer action-color no-mobile home-header-text" onClick={() => window.scroll(0, 3500)}>
                Get the App
              </button>
            </div>
          </div>
        </div>
        <img src={mobileApp} className="mobile-app no-mobile" alt="vpay on a mobile app" />
      </div>
      <WaitlistPopUpContainer />
    </GradientHeader>
  );
}

export const WaitlistPopUpContainer = () => {
  const shouldModalOpen = useSelector((state) => state.user.isWaitlistModalOpen);
  return <>{shouldModalOpen && <WaitlistPopUp />}</>;
};

export const WaitlistPopUp = () => {
  const dispatch = useDispatch();
  return (
    <Popup onClose={() => closeWaitlistModal(dispatch)}>
      <div className="p-relative p10">
        <p className="text-color fw400 font-size13em line-height17 no-margin">Interested in VPay?</p>
        <h2 className="text-color fw700 line-height12 font-size-32 no-margin ">
          We're launching soon!
          <br /> Be the first to join when
          <br /> we launch.
        </h2>
        <Spacer height={15} />
        <div className="d-flex header-action align-center w-con flex-vertical">
          <InputWaitlistContainer
            render={(onClick, onChange, value) => (
              <>
                <input type="text" placeholder="Enter email address" onChange={onChange} value={value} className="list-email height55 full-width fw400 text-color" />
                <Spacer height={20} />
                <div onClick={onClick} className="join-waitlist-btn height55  full-width fw500 primary-color">
                  Join Waitlist
                </div>
              </>
            )}
          />
        </div>
      </div>
    </Popup>
  );
};

export const InputWaitlistContainer = (props) => {
  const [value, setValue] = useState('');
  const onChange = (e) => {
    setValue(e.target.value);
  };
  const onClick = () => {
    if (/\S+@\S+\.\S+/.test(value)) {
      setValue('');
      openNotificationWithIcon('success', '', 'Thank you for your interest. You will be informed once VPay is available to the public.');
    }
  };
  // Using the render props pattern to reuse component logic
  return <>{props.render(onClick, onChange, value)}</>;
};

export const InputWaitlist = () => {
  return (
    <div className="d-flex header-action align-center w-con">
      <InputWaitlistContainer
        render={(onClick, onChange, value) => (
          <>
            <input type="text" placeholder="Enter email address" onChange={onChange} value={value} className="waitlist-email fw400 text-color" />
            <span onClick={onClick} className="join-waitlist-btn fw500 primary-color">
              Join Waitlist
            </span>
          </>
        )}
      />
    </div>
  );
};

export const GradientHeader = ({ children }) => {
  return <div className="gradient-header">{children}</div>;
};

export const Content = ({ children }) => {
  return <div className="content-padding top-content-padding p-relative">{children}</div>;
};
