import Axios from 'axios';

import { store } from '../redux/store';
import { openSessionModal } from '../redux/user/user_actions';

const { dispatch } = store;

export const interceptor = () => {
  // interceptor to log users with expired tokens out
  Axios.interceptors.response.use(
    function (res) {
      return res;
    },
    (err) => {
      if (err.response && err.response.data.message === 'Token expired.' && err.response.status === 403) {
        openSessionModal(dispatch);
        // Logout End point
      }
      if (err?.response?.data?.message === 'Session has expired' || err.response?.status === 401) {
        openSessionModal(dispatch);
      }
      return Promise.reject(err);
    },
  );
};
