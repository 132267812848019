import { Spacer } from '../layout/layout';

import call from '../../assets/images/call.png';
import cancel from '../../assets/images/cancel.svg';
import chat from '../../assets/images/chat.svg';
import mail from '../../assets/images/mail.png';
import message from '../../assets/images/message.png';
import mailTo from '../../assets/images/support-message-icon.svg';
import telegram from '../../assets/images/telegram-icon.svg';
import whatsapp from '../../assets/images/whatsapp-icon.svg';
import Popup from '../popup/popup';

import AppButton from '../app_button/app_button';
import { FormInput } from '../form_input/form_input';

import { Divider } from 'antd';
import './contact_cards.css';

export const CardContents = [
  {
    icon: call,
    title: 'Call Admin',
    content: (
      <>
        Get quick answers to any
        <br /> questions about VPay
      </>
    ),
    buttonText: 'Contact Admin',
    color: '#FF1C26',
    border: '0.5px solid #FF1C26',
    backTitle: 'Phone Number',
    number: '02 01 6339125',
    flag: false,
  },
  {
    icon: message,
    title: 'Get In Touch',
    content: (
      <>
        Send us a message or chat
        <br /> when someone is available
      </>
    ),
    buttonText: 'Chat Admin',
    color: '#200E32',
    border: '0.5px solid rgba(25, 0, 65, 0.13)',
    backTitle: 'Send Us A Message',
    number: 'support@vpay.africa',
    flag: true,
  },
  {
    icon: mail,
    title: 'Suggest An Improvement',
    content: (
      <>
        If you spot something that
        <br /> can be better, let us know
      </>
    ),
    buttonText: 'Make A Suggestion',
    color: '#200E32',
    border: '0.5px solid rgba(25, 0, 65, 0.13)',
    backTitle: 'Email Address',
    number: 'support@vpay.africa',
    flag: false,
  },
];

export const ContactCards = ({ height, width }) => {
  return CardContents.map((content, idx) => (
    <ContactCard
      color={content.color}
      buttonText={content.buttonText}
      {...content}
      flag={content.flag}
      backTitle={content.backTitle}
      number={content.number}
      border={content.border}
      height={height}
      width={width}
      key={idx}
    />
  ));
};

export const ContactCard = ({ icon, title, content, buttonText, width, height, border, color, backTitle, number, flag }) => (
  <div className="p-relative lo">
    <div
      className="d-flex flex-vertical c-pointer  front-card align-center"
      style={{
        padding: '10px 30px',
        height: height ?? '300px',
        maxWidth: '400px',
        minWidth: '300px',
        width: width ?? 'calc((100vw - 400px)/ 3)',
        background: '#FFFFFF',
        border: '0.5px solid rgba(25, 0, 65, 0.15)',
        borderRadius: '5px',
      }}
    >
      <Spacer height={30} />
      <img src={icon} alt="" style={{ height: '60px' }} />
      <Spacer height={20} />
      <h3 className="no-margin fw500 font-size-16 text-center">{title}</h3>
      <Spacer height={10} />
      <p className="no-margin text-center fw400 font-size-14">{content}</p>
      <Spacer height={20} />
      <button className="fw400 text-center font-size-14 primary-bg-color c-pointer" style={{ borderRadius: '5px', padding: '10px 25px', border: border, color: color }}>
        {buttonText}
      </button>
    </div>
    <MessageBackCard icon={icon} flag={flag} backTitle={backTitle} height={height} width={width} number={number} />
  </div>
);

export const MessageBackCard = ({ icon, backTitle, height, width, number, flag }) => (
  <div
    className="d-flex flex-vertical back-card c-pointer  align-center"
    style={{
      padding: '10px 30px',
      height: height ?? '300px',
      maxWidth: '400px',
      minWidth: '300px',
      width: width ?? 'calc((100vw - 400px)/ 3)',
      background: '#FFFFFF',
      border: '0.5px solid rgba(25, 0, 65, 0.15)',
      borderRadius: '5px',
    }}
  >
    <Spacer height={30} />
    <img src={icon} alt="" style={{ height: '60px' }} />
    <Spacer height={20} />
    <h3 className="no-margin fw500 font-size-17 text-center">{backTitle}</h3>
    <Spacer height={10} />
    {!flag && (
      <p className="no-margin text-center fw400 font-size-15">
        <a className="text-color" rel="noreferrer" href={number === 'support@vpay.africa' ? 'mailto: support@vpay.africa' : 'tel: +23416309952'}>
          {number}
        </a>
      </p>
    )}
    {flag && (
      <div className="d-flex flex-vertical">
        <CardFlexWidget>
          <img src={mailTo} alt="" />
          <Spacer width={20} />
          <p className="no-margin text-center fw400 font-size-15">
            <a className="action-color anchor-tag" rel="noreferrer" href={number === 'support@vpay.africa' ? 'mailto: support@vpay.africa' : 'tel: +2348028108892'}>
              {number}
            </a>
          </p>
        </CardFlexWidget>
        <Divider className="divider-margin" />
        <CardFlexWidget>
          <img src={whatsapp} alt="" />
          <Spacer width={20} />
          <p className="no-margin link-l text-center fw400 font-size-15">
            <a target="_blank" rel="noreferrer" className="text-color opacity-5 anchor-tag" href="https://wa.me/2348028108892">
              https://wa.me/2348028108892
            </a>
          </p>
        </CardFlexWidget>
        <Divider className="divider-margin" />
        <CardFlexWidget>
          <img src={telegram} alt="" />
          <Spacer width={20} />
          <p className="no-margin link-l text-center fw400 font-size-15">
            <a target="_blank" rel="noreferrer" className="text-color anchor-tag opacity-5" href="https://t.me/joinchat/nqPoWpDU5wk4MjE8">
              https://t.me/joinchat/nqPoWpDU5wk4MjE8
            </a>
          </p>
        </CardFlexWidget>
      </div>
    )}
  </div>
);

export const CardFlexWidget = ({ children }) => <div className="d-flex align-center">{children}</div>;

export const SuggestionPopup = () => {
  return (
    <Popup width="30%" noPadding>
      <div className="d-flex align-center  gradient-background suggestion-header">
        <img src={chat} alt="" />
        <Spacer width={20} />
        <p className="fw500 font-size-20 primary-color">Leave us a Message</p>
        <button style={{ marginLeft: 'auto' }} type="button" className="unstyle-button c-pointer rounded-widget text-center text-white container-size-small d-flex align-center j-center">
          <img src={cancel} alt="close action" />
        </button>
      </div>
      <div className="primary-bg-color">
        <Spacer height={40} />
      </div>
      <div style={{ padding: '40px' }}>
        <span className="fw500 font-size-18">Subject</span>
        <FormInput className="important-full-width" />
        <span className="fw500 font-size-18">Description</span>
        <Spacer height={5} />
        <span className="fw300 font-size-16 opacity-5">Please enter the details of your request. A member of our support staff will respond as soon as possible.</span>
        <Spacer height={5} />
        <textarea className="message-box fw400 font-size-17 full-width"></textarea>
        <Spacer height={20} />
        <div className="d-flex">
          <AppButton isActive={true} className="font-size-18 margin-left-auto s-c b-tn-padding action-color-2 fw400 primary-color" name="Send" />
        </div>
      </div>
    </Popup>
  );
};
