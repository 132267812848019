import { faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import add from '../../assets/images/add.svg';
import camera from '../../assets/images/camera.png';
import cardOverlay from '../../assets/images/cardOverlay.svg';
import category from '../../assets/images/category-2.svg';
import { default as checkMark } from '../../assets/images/check-green.svg';
import arrow from '../../assets/images/downArrow.svg';
import greenCheck from '../../assets/images/gagged_green_check.png';
import housing from '../../assets/images/house.svg';
import salary from '../../assets/images/money-send.svg';
import phone from '../../assets/images/monitor-mobbile.svg';
import userIcon from '../../assets/images/purple-user-icon.png';
import { useFormFileWithW3 } from '../../hooks/use_form_file';
import { useInput } from '../../hooks/use_input';
import { openNotificationWithIcon } from '../../pages/auth/primary_signup/primary_signup';
import { FlexContainerWithPaddingForCustomInbound, FlexContainerWithPaddingForCustomInboundLower } from '../../pages/merchant/withdraw';
import { removeConfirmCustomInbound, resetCustomInbound, setProductPopup, setRefreshCount } from '../../redux/merchant/custom_inbound.feature';
import { addOutboundCategory, addProductCategory, getAllProducts, getMerchantProducts, submitCustomInboundSrv, submitIncomeCategorySrv } from '../../services/apiSevices';
import { formatMoney } from '../../utils/formatNumber';
import { addProductValidate } from '../../utils/validation_rules/custom_inbound_validate';
import AppButton from '../app_button/app_button';
import { BorderLessFormDropdown, BorderLessFormDropdownWithAddButton } from '../form_dropdown/form_dropdown';
import { BorderLessAppInput, BorderLessAppInputWithCount } from '../form_input/form_input';
import { InputimageFile } from '../image_upload/image_upload';
import { Spacer } from '../layout/layout';
import Popup from '../popup/popup';
import { PopupHeader } from '../popup_header/popup_header';
import './popup_widget.css';

export const BorderlessDropdownWithImg = ({ openCategory, closeParentPopup }) => {
  const [arrowdown, setArrowDown] = useState(false);
  const [currentSelection, setCurrentSelection] = useState(1);

  const handleDropdown = () => {
    closeParentPopup();
    setArrowDown(!arrowdown);
  };

  return (
    <div className="full-width p-relative mb-20">
      <div className="d-flex align-center full-width j-space-between">
        <div className="d-flex align-center">
          <div className="rounded-bg mr-2">
            <img src={category} alt="icon" className="" />
          </div>
          <div>
            <p className="fw400 font-size12em">Categorize this expense</p>
          </div>
        </div>
        <div className="c-pointer">
          <img src={arrow} onClick={() => handleDropdown()} className={`${arrowdown && 'arrow-down'}`} />
        </div>
      </div>
      {arrowdown && (
        <Popup width="20%" onClose={() => handleDropdown()}>
          <div className="full-width d-flex">
            <div className="full-width">
              <div className="d-flex j-space-between linebreak">
                <div className="d-flex align-center pl-10 py-15 c-pointer" onClick={openCategory}>
                  <div className="padding5 mr-5 rounded-widget d-flex j-center align-center p8 addBg">
                    <img src={add} className="" />
                  </div>
                  <Spacer width={15} />
                  <p>Add a custom category</p>
                </div>
              </div>

              <div className={`${currentSelection === 1 ? 'd-flex j-space-between linebreak fade-in' : 'd-flex j-space-between linebreak opacity-3 fade-in'}`} onClick={() => setCurrentSelection(1)}>
                <div className="d-flex align-center pl-10 py-15 c-pointer">
                  <div className="padding5 mr-5 rounded-widget d-flex j-center align-center p8 salaryColor">
                    <img src={salary} className="salaryColor" />
                  </div>
                  <p>Salary</p>
                </div>
                {currentSelection === 1 && <img src={checkMark} className="fade-in" />}
              </div>
              <div className={`${currentSelection === 2 ? 'd-flex j-space-between linebreak fade-in' : 'd-flex j-space-between linebreak opacity-3 fade-in'}`} onClick={() => setCurrentSelection(2)}>
                <div className="d-flex align-center pl-10 py-15 c-pointer">
                  <div className="padding5 mr-5 rounded-widget d-flex j-center align-center p8 salaryColor">
                    <img src={housing} className="salaryColor" />
                  </div>
                  <p>Housing and Rent</p>
                </div>
                {currentSelection === 2 && <img src={checkMark} className="fade-in" />}
              </div>
              <div className={`${currentSelection === 3 ? 'd-flex j-space-between linebreak fade-in' : 'd-flex j-space-between linebreak opacity-3 fade-in'}`} onClick={() => setCurrentSelection(3)}>
                <div className="d-flex align-center pl-10 py-15 c-pointer">
                  <div className="padding5 mr-5 rounded-widget d-flex j-center align-center p8 phoneColor">
                    <img src={phone} className="phoneColor" />
                  </div>
                  <p>Phone & Internet</p>
                </div>
                {currentSelection === 3 && <img src={checkMark} className="fade-in" />}
              </div>
            </div>
          </div>
        </Popup>
      )}
      <Spacer height={10} />
    </div>
  );
};

export const BorderlessCategoriseInbound = ({ openPopup, stage, setStage }) => {
  const [arrowdown, setArrowDown] = useState(false);

  const { showProdctPopup } = useSelector((state) => state?.customInboundReducer);

  return (
    <div className="full-width p-relative mb-20 c-pointer">
      <div className="d-flex align-center full-width j-space-between" onClick={() => openPopup(true)}>
        <div className="d-flex align-center">
          <div className="rounded-bg mr-2">
            <img src={category} alt="icon" className="" />
          </div>
          <div>
            <p className="fw400 font-size12em">Categorize This Income</p>
          </div>
        </div>
        <div className="">
          <img src={arrow} className={`${arrowdown && 'arrow-down'}`} />
        </div>
      </div>
      {showProdctPopup && <ProductPopup />}
      <Spacer height={10} />
    </div>
  );
};

export const CategoriseInboundPopup = ({ popupTrigger, tnxData, setStage, stage }) => {
  const [count, setCount] = useState(0);
  const { showProductPopup } = useSelector((state) => state.customInboundReducer);
  const merchantId = useSelector((state) => state.user?.currentMerchant?.businessid);
  const [loading, setLoading] = useState(false);
  const [productId, setProductId] = useState('');
  const [errors, setErrors] = useState({});

  // get the list of categories
  const { data, isLoading } = useQuery({ queryKey: ['getMerchantProductList'], queryFn: () => getMerchantProducts(merchantId, 1), refetchInterval: false });

  const merchantsProductList = data?.data?.data?.products;

  const categories = (!isLoading && merchantsProductList.length > 0 && merchantsProductList.map((item) => item)) || [];
  const handleAdd = () => {
    setCount((count) => parseInt(count) + 1);
  };

  const handleMinus = () => {
    if (count === 0) {
      return 0;
    }
    setCount((count) => parseInt(count) - 1);
  };

  const handlePopup = () => {
    popupTrigger(false);
  };

  // console.log(tnxData, ' this is the tnxData');

  const saveInboundTnx = async () => {
    setLoading(true);
    const info = {
      merchant: merchantId,
      product: productId,
      amount: tnxData?.currentRecord?.amount,
      originator_account_name: 'NA',
      transaction: new Date().toLocaleDateString(),
    };

    if (!productId) {
      openNotificationWithIcon('error', 'Error', 'Please select a product.');
      setLoading(false);
      return;
    }

    try {
      // this is the wrong service, using inbound for outbound, wrong service, please get the correct one from CJ
      const res = await submitIncomeCategorySrv(info);
      // console.log(res, ' res in adding income category');
      openNotificationWithIcon('success', 'Success', 'Added Successfully');
      setLoading(false);
      popupTrigger(false);
    } catch (error) {
      console.log(error?.response, ' error in income category');
      openNotificationWithIcon('error', 'Error', error?.response?.data?.message);
      setLoading(false);
      popupTrigger(false);
    }
  };

  const productShow = () => {
    setStage(1);
  };

  return (
    <>
      {
        <Popup flag={true}>
          <PopupHeader text="Categorise This Income" goBack={() => handlePopup()} />
          <Spacer height={30} />
          <div className="d-flex j-center align-center flex-column">
            <BorderLessFormDropdownWithAddButton placeholder="Select Product" options={categories} onChange={(e) => setProductId(e)} onClick={() => productShow()} stage={stage} />
            <BorderLessAppInputWithCount handleAdd={() => handleAdd()} handleMinus={() => handleMinus()} placeholder="Enter Quantity" count={count} onChange={(e) => setCount(e.target.value)} />
            <div className="px-50 full-width">
              <Spacer height={30} />
              <AppButton
                className="d-flex full-width align-center j-center app-button action-color-2 button-nav-padding text-white fade-in"
                name="Save"
                isActive={true}
                onClick={() => saveInboundTnx()}
                isBusy={loading}
              />
            </div>
            <Spacer height={30} />
          </div>
        </Popup>
      }
      {showProductPopup && <ProductPopup />}
    </>
  );
};

export const BorderlessDropdownWithImgClone = ({ openCategory }) => {
  const [arrowdown, setArrowDown] = useState(false);
  const [currentSelection, setCurrentSelection] = useState(1);
  // get the list of categories

  const handleDropdown = () => {
    setArrowDown(!arrowdown);
  };

  return (
    <div className="full-width p-relative mb-20">
      <div className="d-flex align-center full-width j-space-between">
        <div className="d-flex align-center">
          <div className="rounded-bg mr-2">
            <img src={category} alt="icon" className="" />
          </div>
          <div>
            <p className="fw400 font-size12em">Categorize this expense</p>
          </div>
        </div>
        <div className="c-pointer">
          <img src={arrow} onClick={() => handleDropdown()} className={`${arrowdown && 'arrow-down'}`} />
        </div>
      </div>
      {arrowdown && (
        <div className="p-absolute options full-width slide-up d-flex justify-end">
          <div className=" options-content half-width">
            <div className="d-flex j-space-between linebreak">
              <div className="d-flex align-center pl-10 py-15 c-pointer" onClick={openCategory}>
                <div className="padding5 mr-5 rounded-widget d-flex j-center align-center p8 addBg">
                  <img src={add} className="" />
                </div>
                <p>Add a custom category</p>
              </div>
            </div>

            <div className={`${currentSelection === 1 ? 'd-flex j-space-between linebreak fade-in' : 'd-flex j-space-between linebreak opacity-3 fade-in'}`} onClick={() => setCurrentSelection(1)}>
              <div className="d-flex align-center pl-10 py-15 c-pointer">
                <div className="padding5 mr-5 rounded-widget d-flex j-center align-center p8 salaryColor">
                  <img src={salary} className="salaryColor" />
                </div>
                <p>Salary</p>
              </div>
              {currentSelection === 1 && <img src={checkMark} className="fade-in" />}
            </div>
            <div className={`${currentSelection === 2 ? 'd-flex j-space-between linebreak fade-in' : 'd-flex j-space-between linebreak opacity-3 fade-in'}`} onClick={() => setCurrentSelection(2)}>
              <div className="d-flex align-center pl-10 py-15 c-pointer">
                <div className="padding5 mr-5 rounded-widget d-flex j-center align-center p8 salaryColor">
                  <img src={housing} className="salaryColor" />
                </div>
                <p>Housing and Rent</p>
              </div>
              {currentSelection === 2 && <img src={checkMark} className="fade-in" />}
            </div>
            <div className={`${currentSelection === 3 ? 'd-flex j-space-between linebreak fade-in' : 'd-flex j-space-between linebreak opacity-3 fade-in'}`} onClick={() => setCurrentSelection(3)}>
              <div className="d-flex align-center pl-10 py-15 c-pointer">
                <div className="padding5 mr-5 rounded-widget d-flex j-center align-center p8 phoneColor">
                  <img src={phone} className="phoneColor" />
                </div>
                <p>Phone & Internet</p>
              </div>
              {currentSelection === 3 && <img src={checkMark} className="fade-in" />}
            </div>
          </div>
        </div>
      )}
      <Spacer height={10} />
    </div>
  );
};

export const AddProductCategory = ({ closeCategory, merchantId }) => {
  const [loading, setLoading] = useState(false);
  const categoryNameProps = useInput('', 'text', '', '');
  const [errors, setError] = useState({});
  const dispatch = useDispatch();

  const handleAddProduct = async () => {
    setLoading(true);
    // validate input
    const val = {
      categoryName: categoryNameProps.value,
    };
    const error = addProductValidate(val);
    setError(error);
    if (Object.keys(errors).length > 0) {
      setLoading(true);
      return;
    }

    const data = {
      merchant: merchantId,
      name: categoryNameProps.value,
    };
    try {
      const res = await addOutboundCategory(data);
      if (res?.data?.status) {
        openNotificationWithIcon('success', 'Success', 'Product Category Added Successfully');
        setLoading(false);
        // close the popup
        closeCategory();
      }
      setLoading(false);
    } catch (error) {
      //console.log('an error occurered ', error?.response?.data?.message);
      openNotificationWithIcon('error', 'Error', error?.response?.data?.message ?? 'Error Ading Product Category');
      setLoading(false);
    }
  };

  return (
    <Popup flag={true} width="20%">
      <PopupHeader text="Create a category" />
      <Spacer height={40} />
      <BorderLessAppInput placeholder="Category Name" {...categoryNameProps} name="categoryName" error={errors} />
      <Spacer height={40} />
      <AppButton
        onClick={() => handleAddProduct()}
        isBusy={loading}
        className="full-width d-flex align-center j-center app-button action-color-2 button-nav-padding text-white fade-in"
        isActive={true}
        name="Save"
      />
    </Popup>
  );
};

export const ConfirmCustomHeader = ({ setParentPopup }) => {
  const dispatch = useDispatch();

  const goBack = () => {
    dispatch(removeConfirmCustomInbound());
  };
  return (
    <div className="p-relative fade-in page-color-background">
      <div className="overlay">
        <img src={cardOverlay} className="p-absolute" style={{ right: '0' }} />
        <Spacer height={40} />
        <div className="d-flex align-center j-space-between text-white p10">
          <div style={{ width: '4%' }} onClick={() => goBack()}>
            <FontAwesomeIcon icon={faAngleLeft} size="2x" className="c-pointer" />
          </div>
          <div className="fw700 font-size14em full-width d-flex j-center">
            <p>Confirm Details</p>
          </div>
        </div>
      </div>
      <ConfirmCustomDetails />
      <ConfirmCustomLowerDetails goBack={goBack} setParentPopup={setParentPopup} />
    </div>
  );
};

export const ConfirmCustomDetails = () => {
  // get state values from redux
  const { customer_name, amount } = useSelector((state) => state?.customInboundReducer);
  return (
    <div className="custom-confirm-details">
      <div className="d-flex flex-column align-center j-center full-height full-width fw500">
        <div className="text-center ">
          <p className="text-vpay-purple ">From</p>
          <p className="font-size13em">{customer_name}</p>
        </div>
        <Spacer height={10} />
        <div className="text-center">
          <p className="text-vpay-purple">Amount</p>
          <p className=" font-size13em">₦{formatMoney(amount)}</p>
        </div>
      </div>
    </div>
  );
};

export const ConfirmCustomLowerDetails = ({ goBack, setParentPopup }) => {
  const [loading, setLoading] = useState(false);
  // get state values from redux
  const { tnx_date, product, payment_type, quantity, amount, customer_name, short_desc } = useSelector((state) => state?.customInboundReducer);
  const merchantId = useSelector((state) => state.user?.currentMerchant?.businessid);
  const dispatch = useDispatch();

  const saveCustomInbound = async () => {
    const info = {
      merchant_id: merchantId,
      product_id: product.split('-')[0],
      amount: amount,
      originator_account_name: customer_name,
      payment_type: payment_type.toLowerCase(),
      description: short_desc,
      transaction_date: tnx_date,
      quantity: quantity,
    };

    setLoading(true);
    try {
      await submitCustomInboundSrv(info);
      openNotificationWithIcon('success', 'Success', 'Custom inbound added successfully');
      setLoading(false);
      goBack();
      setParentPopup(false);
      dispatch(resetCustomInbound);
      // reload the tnxs history page
      dispatch(setRefreshCount(Math.random()));
    } catch (error) {
      //console.log('an error occured', error?.response);
      dispatch(resetCustomInbound);
      openNotificationWithIcon('error', 'Error', error?.response?.data?.message);
      setLoading(false);
      goBack();
    }
  };
  const modifiedProduct = product.split('-')[1];

  return (
    <div>
      <div>
        <FlexContainerWithPaddingForCustomInbound leftText="Date" rightText="Payment Type" className="pl-40 pr-40" />
        <FlexContainerWithPaddingForCustomInboundLower leftText={tnx_date} rightText={payment_type} className="pl-40 pr-40" />
      </div>
      <Spacer height={20} />
      <div>
        <FlexContainerWithPaddingForCustomInbound leftText="Product" rightText="Quantity" className="pl-40 pr-40" />
        <FlexContainerWithPaddingForCustomInboundLower leftText={modifiedProduct} rightText={quantity} className="pl-40 pr-40" />
      </div>
      <Spacer height={30} />
      <div>
        <AppButton
          onClick={() => saveCustomInbound()}
          isBusy={loading}
          isActive={true}
          className="full-width d-flex align-center j-center app-button action-color-2 button-nav-padding text-white fade-in"
          name="Save"
        />
      </div>
    </div>
  );
};

export const ProductPopup = () => {
  const imgProps = useFormFileWithW3(true, 'product_picture', 'ProductCategoryImage');
  const [unitCount, setUnitCount] = useState(0);
  const [stockCount, setStockCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const productNameProps = useInput('', 'text');
  const [productDesc, setProductDesc] = useState('');
  const merchantId = useSelector((state) => state.user?.currentMerchant?.businessid);

  const dispatch = useDispatch();
  const { data, isLoading } = useQuery({ queryKey: ['getAllProducts'], queryFn: () => getAllProducts(), refetchInterval: false });

  const allProducts = !isLoading && data?.data?.data.length > 0 ? data?.data?.data : [];
  const stepBack = (val) => {
    // setStage(0);
    dispatch(setProductPopup(val));
  };

  const handleAdd = () => {
    setUnitCount((unitCount) => parseInt(unitCount) + 1);
  };

  const handleMinus = () => {
    if (unitCount === 0) {
      return 0;
    }
    setUnitCount((unitCount) => parseInt(unitCount) - 1);
  };
  const handleStockAdd = () => {
    setStockCount((stockCount) => parseInt(stockCount) + 1);
  };

  const handleStockMinus = () => {
    if (stockCount === 0) {
      return 0;
    }
    setStockCount((stockCount) => parseInt(stockCount) - 1);
  };

  const onDescChange = (e) => {
    setProductDesc(e);
  };

  const addProduct = async () => {
    const data = {
      merchant: merchantId,
      name: productNameProps.value,
      description: productDesc,
      unit: unitCount,
      stock: stockCount,
      picture_url: imgProps.uploadedDocs[-1],
    };
    //console.log(imgProps.uploadedDocs[-1].url, 'image props');
    if (!imgProps.uploadedDocs || imgProps.uploadedDocs.length < 1) {
      openNotificationWithIcon('error', 'Error', 'Please upload an image of the product');
      return;
    }
    if (!productNameProps.value) {
      openNotificationWithIcon('error', 'Error', 'Please enter the product name.');
      return;
    }
    if (!productDesc) {
      openNotificationWithIcon('error', 'Error', 'Please enter the product category.');
      return;
    }

    setLoading(true);
    try {
      const res = await addProductCategory(data);

      if (res?.data?.status) {
        openNotificationWithIcon('success', 'Success', 'Product Has Been Added Successfully');
      }
      setLoading(false);
      //close popup
      stepBack(false);
    } catch (error) {
      // console.log(error, ' error on adding product');
      openNotificationWithIcon('error', 'Error', 'An error occurred, please try again.');
      setLoading(false);
      // close popup
      stepBack(false);
    }
  };

  return (
    <Popup flag={true}>
      <PopupHeader text="Add New Product" goBack={() => stepBack(false)} />
      <Spacer height={40} />
      <div className="app-padding-lr">
        <ProductImageUpload props={imgProps} name="product_picture" />
        <Spacer height={20} />
        <BorderLessAppInput placeholder="Product Name" {...productNameProps} />
        <BorderLessFormDropdown placeholder="Product Category" options={allProducts} onChange={onDescChange} />
        <BorderLessAppInputWithCount placeholder="Unit" handleAdd={handleAdd} handleMinus={handleMinus} count={unitCount} onChange={(e) => setUnitCount(e.target.value)} />
        <BorderLessAppInputWithCount placeholder="Stock" handleAdd={handleStockAdd} handleMinus={handleStockMinus} count={stockCount} onChange={(e) => setStockCount(e.target.value)} />
        <Spacer height={40} />
        <AppButton
          className="d-flex full-width align-center j-center app-button action-color-2 button-nav-padding text-white fade-in margin-top-10"
          name="Save"
          isActive={true}
          onClick={() => addProduct()}
          isBusy={loading}
        />
        <Spacer height={30} />
      </div>
    </Popup>
  );
};

export const ProductImageUpload = ({ props, name }) => {
  return (
    <div className="d-flex j-center p-relative">
      <div className="product-camera-bg d-flex j-center align-center">
        <InputimageFile fileProps={props} clickable disabled={false} name={name} className="product-camera-bg">
          <img src={camera} alt="camera" className="p-absolute left-ab-25" />
        </InputimageFile>
      </div>
    </div>
  );
};

export const RolesImageUpload = ({ props, name }) => {
  return (
    <div className="d-flex j-center p-relative">
      <div className="roles-user-bg d-flex j-center align-center">
        <InputimageFile fileProps={props} clickable disabled={false} name={name} className="roles-user-bg j-center">
          <img src={userIcon} alt="camera" className="" />
        </InputimageFile>
      </div>
    </div>
  );
};

export const WhyImagePopup = ({ closePopup }) => {
  return (
    <Popup width="25%" onClose={() => closePopup()}>
      <div className="d-flex j-center flex-column align-center">
        <img src={greenCheck} alt="check" className="greenCheckBg" />
        <Spacer height={10} />
        <p className="fw500">Why do I need to upload an image?</p>
        <Spacer height={15} />
        <p>
          The image uploaded will be used for authorization checks <br /> whenever there are suspicious actions on your account.
        </p>
        <Spacer height={50} />
        <AppButton
          className="d-flex full-width align-center j-center app-button action-color-2 button-nav-padding text-white fade-in margin-top-10"
          name="Got it!"
          isActive={true}
          onClick={() => closePopup()}
          isBusy={false}
        />
      </div>
    </Popup>
  );
};
