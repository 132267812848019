import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { openNotificationWithIcon } from '../pages/auth/primary_signup/primary_signup';
import { getCashierTransactionDetailService, getTransactionDetailService } from '../services/apiSevices';

export const useTransactionDetail = (id) => {
  const [detail, setDetail] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  const role = useSelector((state) => state.user.role);
  const location = useLocation();

  const fetchTxnDetails = async () => {
    try {
      setLoading(true);
      const res = await getTransactionDetailService(id);
      setDetail(res.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      return openNotificationWithIcon('error', 'Error', error.response?.data?.message);
    }
  };

  const fetchCashierTxnDetails = async () => {
    try {
      setLoading(true);
      const res = await getCashierTransactionDetailService(id);
      setDetail(res.data.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      return openNotificationWithIcon('error', 'Error', error.response?.data?.message);
    }
  };

  React.useEffect(() => {
    (() => {
      if (role !== 'cashier' && location.pathname === '/admin/merchant/history') {
        fetchTxnDetails(id);
      } else {
        fetchCashierTxnDetails(id);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return {
    detail,
    loading,
  };
};
