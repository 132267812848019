import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { verifySmartCardNumber, verifyUtilityNumber } from '../redux/merchant/utility.features';

export default function useVerifySmartCardNumber(smartcardNumber, data) {
  const dispatch = useDispatch();
  useEffect(() => {
    if (smartcardNumber && smartcardNumber.length === 10) {
      dispatch(verifySmartCardNumber(smartcardNumber, data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smartcardNumber]);
}

export function useVerifyUtiliy(smartcardNumber, data) {
  const dispatch = useDispatch();
  useEffect(() => {
    if (smartcardNumber && smartcardNumber.length === 11) {
      dispatch(verifyUtilityNumber(smartcardNumber, data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [smartcardNumber]);
}
