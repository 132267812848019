import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { FormDropdown } from '../../../components/form_dropdown/form_dropdown';
import { FormInput } from '../../../components/form_input/form_input';
import { ColouredBackground, FormLayout } from '../../../components/layout/layout';
import { useInput } from '../../../hooks/use_input';
import { addDetails, signUpStartAsync } from '../../../redux/user/user_actions';
import { getLGAFromState, mappedStates } from '../../../utils/helper';
// import { industries } from "../../mock/industries";
import createGoogleRecaptchaV3 from '../../../utils/createCaptchaToken';
import { secondFormValidate } from '../../../utils/validation_rules/signup_validate';

export default function SecondarySignUp({ setPosition }) {
  const history = useHistory();
  const signUpCredentials = useSelector((state) => state.user.signUpCredentials);
  const loading = useSelector((state) => state.user.loading);
  const dispatch = useDispatch();
  const [error, setError] = useState({});
  const googleToken = createGoogleRecaptchaV3();

  // const signUpCredentials = JSON.parse(localStorage.getItem('myObj')) || {};
  const [dropdownInfo, setDropdownInfo] = useState({
    industry: signUpCredentials.account_type === '3' ? 'Other Services' : (signUpCredentials.industry ?? ''),
  });
  const personalAccount = `${signUpCredentials.contactfirstname} ${signUpCredentials.contactlastname}`;
  const businessnameProps = useInput(
    () => (signUpCredentials.account_type === '3' ? (signUpCredentials.businessname = personalAccount) : (signUpCredentials.businessname ?? '')),
    'text',
    error,
    setError,
  );
  const addressProps = useInput(() => signUpCredentials.address ?? '', 'text', error, setError);
  const [state, setState] = useState(() => signUpCredentials.state ?? '');
  const [lga, setLga] = useState(() => signUpCredentials.lga ?? '');
  const [mappedLGA, setMappedLGA] = useState([]);
  const industries = useSelector((state) => state.merchant.industries);
  const onChange = (e) => {
    setDropdownInfo({
      ...dropdownInfo,
      industry: e,
    });
    setError((error) => ({ ...error, e: '' }));
  };

  const info = {
    address: addressProps.value,
    industry: dropdownInfo.industry,
    businessname: businessnameProps.value,
    state,
    lga,
  };

  const goBackClick = () => {
    // localStorage.setItem('myObj', JSON.stringify(info));
    // history.goBack();
    dispatch(addDetails(info));
    setPosition((prev) => prev - 1);
  };

  useEffect(() => {
    localStorage.removeItem('myObj');
  }, []);

  const onClick = (e) => {
    e.preventDefault();
    const error = secondFormValidate(info);
    setError(error);
    if (Object.keys(error).length === 0) {
      localStorage.removeItem('myObj');
      // eslint-disable-next-line no-unused-vars
      let { _, ...details } = { ...signUpCredentials, ...info };
      dispatch(signUpStartAsync(details, history));
    }
  };

  useEffect(() => {
    setMappedLGA(getLGAFromState(state));
  }, [state]);

  const handleStateChange = (e) => {
    setState(e);
  };

  const handleLGAChange = (e) => {
    setLga(e);
  };

  if (window.location.hostname.includes('sandbox')) {
    return (
      <ColouredBackground bottomText="" linkText="Login" route="" onClick={() => {}}>
        <h3>
          You can't signup through sandbox. Visit <a href="https://www.vpay.africa/signup">www.vpay.africa/signup</a> to sign up
        </h3>
      </ColouredBackground>
    );
  }

  return (
    <ColouredBackground>
      <FormLayout headerText="TELL US ABOUT YOURSELF" buttonText="Continue" onClick={(e) => onClick(e)} isBusy={loading} goBackFlag goBackClick={goBackClick}>
        {signUpCredentials.account_type === '3' ? (
          ''
        ) : (
          <>
            <FormInput className="important-full-width" label="Legal Business Name" name="businessname" {...businessnameProps} />
            <FormDropdown
              defaultValue={dropdownInfo.industry}
              className="important-full-width"
              width="100%"
              onChange={onChange}
              name="industry"
              error={error}
              options={industries}
              label="Business Industry"
            />
          </>
        )}
        <div className="d-grid dropdown-grid-temp-col-1fr grid-gap10 important-full-width">
          <FormDropdown defaultValue={state} error={error} name="state" onChange={handleStateChange} className="important-full-width" options={mappedStates} label="State" />
          <FormDropdown defaultValue={lga} error={error} name="lga" onChange={handleLGAChange} className="important-full-width" options={mappedLGA} label="LGA" />
        </div>
        <FormInput className="important-full-width" label="Street Address" name="address" {...addressProps} />
      </FormLayout>
    </ColouredBackground>
  );
}
