import { createSlice } from '@reduxjs/toolkit';
import { fetchMerchantCustomersCashpoints } from '../../services/apiSevices';

const cashierTransactionSlice = createSlice({
  name: 'cashierTransaction',
  initialState: {
    transactions: [],
    loading: false,
    error: null,
  },
  reducers: {
    setTransactions: (state, { payload }) => {
      state.transactions = payload;
    },
    transLoading: (state, { payload }) => {
      state.loading = payload;
    },
    transError: (state, { payload }) => {
      state.error = payload;
    },
  },
});
const cashierTransactionReducer = cashierTransactionSlice.reducer;

export const { setTransactions, transLoading, transError } = cashierTransactionSlice.actions;
const apply =
  ({ remote, action }) =>
  async (dispatch) => {
    dispatch(transLoading(true));
    try {
      const { data } = await remote();

      if (data) {
        dispatch(action(data));
        dispatch(transLoading(false));
      } else {
        dispatch(transError(data?.message));
        dispatch(transLoading(false));
      }
    } catch (err) {
      console.log({ err });
      dispatch(transError(err.response?.data?.message));
      dispatch(transLoading(false));
    }
  };

export const loadTransactions = (id, pageNumber) => async (dispatch) => {
  await apply({ remote: () => fetchMerchantCustomersCashpoints(id, pageNumber, { period: 'year' }), action: setTransactions })(dispatch);
};

export const cashierTransactionSelector = (state) => state.cashierTransaction;
export default cashierTransactionReducer;
