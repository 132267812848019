import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export default function useVerifiedUser() {
  const { issetNIN_BVN, isset_id_card, isset_liveliness_test, verified, isset_international_passport } = useSelector((state) => state.merchant.merchantDetails);
  const data = useMemo(() => {
    return {
      hasBVNorNIN: issetNIN_BVN,
      hasIDCard: isset_id_card || isset_international_passport,
      hasLivelinessVideo: isset_liveliness_test,
      hasBeenVerified: verified,
    };
  }, [issetNIN_BVN, isset_id_card, isset_liveliness_test, verified, isset_international_passport]);
  return data;
}
