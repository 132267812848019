import { faArrowRight, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';
import cancel from '../../assets/images/cancel.svg';
import { closeRedMessage } from '../../redux/merchant/merchant_actions';

import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Spacer } from '../layout/layout';
import './red_alert.css';

export const RedAlert = ({ text }) => {
  const dispatch = useDispatch();
  const onClose = () => {
    closeRedMessage(dispatch);
  };

  return (
    <div className="red-area d-flex align-center j-space-between">
      <p className="flex-one text-center primary-color font-size-18 fw400 ">{text}</p>
      <button type="button" onClick={onClose} className="unstyle-button r-btn c-pointer rounded-widget text-center text-white container-size-small d-flex align-center j-center">
        <img src={cancel} alt="close action" />
      </button>
    </div>
  );
};

export const RedAlertWithLink = ({ text, lowerText }) => {
  const history = useHistory();
  const goToCompliance = () => {
    history.push('/admin/merchant/compliance');
  };

  return (
    <div className="red-area2 d-flex align-center j-space-between">
      <div className="text-left d-flex align-center">
        <div className="">
          <FontAwesomeIcon icon={faExclamationCircle} color="#9BB8CD" size="2x" />
        </div>
        <Spacer width={20} />
        <div>
          <p className="flex-one primary-color font-size-18 fw400 ">{text}</p>
          <p className="flex-one text-center primary-color">{lowerText}</p>
        </div>
      </div>

      <div className="d-flex align-center py-10 px-20 primary-color btn-bg c-pointer" onClick={() => goToCompliance()}>
        <span className="fw400 no-mobile-but-desktop">Verify Account</span>
        <Spacer width={20} />
        <FontAwesomeIcon icon={faArrowRight} size="1x" />
      </div>
    </div>
  );
};

export const MobileRedAlertWithLink = ({ text, lowerText }) => {
  const history = useHistory();
  const goToCompliance = () => {
    history.push('/admin/merchant/compliance');
  };

  return (
    <>
      <div className="mobile-red-alert no-desktop-but-mobile">
        <div className="d-flex align-center">
          <div className="text-left">
            <p className="flex-one primary-color font-size-18 fw400 ">{text}</p>
            <p className="flex-one primary-color">{lowerText}</p>
          </div>
        </div>
        <Spacer height={20} />
        <div className="d-flex align-center py-10 px-20 primary-color btn-bg w-60 c-pointer" onClick={() => goToCompliance()}>
          <span className="fw400">Verify Account</span>
          <Spacer width={20} />
          <FontAwesomeIcon icon={faArrowRight} size="1x" />
        </div>
      </div>
      <Spacer height={10} />
    </>
  );
};
