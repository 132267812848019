import { DiscountAnalysis, LoyaltyTopCards, TopTransactingCustomers, TransactionAnalytics } from '../../components/loyalty_scheme/loyalty_scheme';
import { MerchantContentWrapper } from '../../components/merchant_widgets/merchant_widgets';

const LoyaltyScheme = () => {
  return (
    <MerchantContentWrapper className="d-flex flex-vertical fade-in" hasTopSpacing>
      <div className="primary-bg-color full-height py-15 px-20 no-padding-important-mobile">
        <LoyaltyTopCards />
        <TransactionAnalytics />
        <TopTransactingCustomers />
        <DiscountAnalysis />
      </div>
    </MerchantContentWrapper>
  );
};

export default LoyaltyScheme;
