import flagsmith from 'flagsmith';
import { FlagsmithProvider } from 'flagsmith/react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import App from './app';
import ErrorBoundary from './components/error_boundary/error_boundary';
import './index.css';
import { persistor, store } from './redux/store';
import reportWebVitals from './reportWebVitals';

const queryClient = new QueryClient();

const { REACT_APP_ENV, REACT_APP_FLAGSMITH_STAGING_ENVIRONMENT, REACT_APP_FLAGSMITH_PRODUCTION_ENVIRONMENT } = process.env;

const flagsmithConfigOptions = {
  environmentID: REACT_APP_ENV === 'dev' ? REACT_APP_FLAGSMITH_STAGING_ENVIRONMENT : REACT_APP_FLAGSMITH_PRODUCTION_ENVIRONMENT,
};

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <PersistGate persistor={persistor}>
        <QueryClientProvider client={queryClient}>
          <ErrorBoundary>
            <FlagsmithProvider flagsmith={flagsmith} options={flagsmithConfigOptions}>
              <App />
            </FlagsmithProvider>
          </ErrorBoundary>
        </QueryClientProvider>
      </PersistGate>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
