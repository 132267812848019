export const transferFormValidator = (values) => {
  let errors = {};
  if (!values.accountNumber) {
    errors.accountNumber = 'Account Number is required';
  }
  if (!values.amount) {
    errors.amount = 'Amount is required';
  }

  return errors;
};

export const beneficiaryFormValidator = (values) => {
  let errors = {};
  if (!values.accountNumber) {
    errors.accountNumber = 'Account Number is required';
  }
  if (!values.amount) {
    errors.amount = 'Amount is required';
  }
  if (!values.bankName) {
    errors.amount = 'Amount is required';
  }

  return errors;
};
