import { Tabs } from 'antd';
import { Spacer } from '../../../components/layout/layout';
import { MerchantContentWrapper } from '../../../components/merchant_widgets/merchant_widgets';
import AccountLimit from './increase_limit';

export default function Authorizations() {
  const TabItems = [
    {
      label: 'Increase Account Limit',
      key: 'increase_account_limit',
      children: (
        <div className="full-height flex-vertical full-width d-flex align-center j-center">
          <Spacer height={25} />
          <AccountLimit />
        </div>
      ),
    },
  ];
  return (
    <MerchantContentWrapper className="full-height-minus-150 fade-in tab-settings fw400">
      <Tabs items={TabItems} />
    </MerchantContentWrapper>
  );
}
