import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Radio, Tabs } from 'antd';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spacer } from '../../components/layout/layout';
import {
  EmptyView,
  Filter,
  MerchantContentWrapper,
  MobileVirtualAccountFailedWebhooks,
  MobileVirtualAccountFilteredHistory,
  VirtualAccountFailedWebhooks,
  VirtualAccountFilteredHistory,
  VirtualAccountTransactionTable,
  VirtualMobileMerchantCashpointTransactionTable,
} from '../../components/merchant_widgets/merchant_widgets';
import { fetchCashpointCustomer, fetchCashpoints } from '../../redux/merchant/merchant_actions';
import { formatNumber } from '../../utils/formatNumber';
import { isDesktop } from '../../utils/helper';
import { DeactivateCashpointPopup, VirtualAccountTopCards, VirtualMobileTopCard } from './cashpoint';
import './index.css';
import { VirtualAccountColumn } from './table_data';
const { TabPane } = Tabs;

export default function MerchantVirtualAccounts() {
  const id = useSelector((state) => state.user.currentMerchant['businessid']);
  const currentCashpoint = useSelector((state) => state.merchant.currentCashpoint);
  const open = useSelector((state) => state.merchant.isOpenCreateCashpoints);
  const cashpointCustomers = useSelector((state) => state.merchant.cashpointCustomers);
  const shouldCashpointModalOpen = useSelector((state) => state.merchant.shouldCashpointModalOpen);
  const [showBalance, setShowBalance] = useState(false);
  const [values, setValues] = useState({ from: '', to: '' });

  const [date, setDate] = useState({ from: '', to: '' });
  const { cardPaymentCount, inboundTransferCount, inboundTotalCount } = useSelector((state) => state.virtualAccount);
  const [transferType, setTransferType] = useState('');
  const [pageNumber, setPageNumber] = useState(1);

  const transactionHeader = ['Amount', 'Customer', 'Description', 'Date/Time', 'Status'];
  const failedTransactionHeader = ['Amount', 'Customer', 'Description', 'Date/Time', 'Status', 'WebHook Status'];

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchCashpoints(id));
    dispatch(fetchCashpointCustomer(currentCashpoint !== null ? currentCashpoint._id : '0', { period: 'year' }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const totalTransactions = cashpointCustomers ? cashpointCustomers?.total : 0;

  const handleToggleBalance = (e) => {
    e.stopPropagation();
    setShowBalance((prev) => !prev);
  };
  return (
    <MerchantContentWrapper className="full-height-minus-150 fade-in tab-settings">
      <Tabs defaultActiveKey="Virtual_Accounts" destroyInactiveTabPane={true}>
        <TabPane tab="Virtual Accounts" key="Virtual_Accounts">
          <MerchantContentWrapper
            className="full-height-minus-150 fw400 fade-in no-padding-important-mobile"
            beforeSidePadding={<Filter needPrint={false} setValues={setValues} setPageNumber={setPageNumber} />}
          >
            {!open && (
              <>
                <Spacer height={30} />
                <VirtualAccountTopCards
                  bottomText={showBalance ? (totalTransactions ? `₦${formatNumber(totalTransactions)}` : '₦0.00') : '₦****'}
                  toggleBalanceIcon={<FontAwesomeIcon icon={showBalance ? faEyeSlash : faEye} className="c-pointer text-white" onClick={() => setShowBalance((prev) => !prev)} />}
                />
                <VirtualMobileTopCard
                  transaction={showBalance ? `₦${formatNumber(totalTransactions)}` : '₦****'}
                  toggleBalanceIcon={<FontAwesomeIcon icon={showBalance ? faEyeSlash : faEye} className="c-pointer text-white" onClick={handleToggleBalance} />}
                />
                <Spacer height={30} />
                {cashpointCustomers?.data ? (
                  cashpointCustomers?.data?.length < 1 ? (
                    <div className="d-flex align-center j-center full-width full-height">
                      <EmptyView
                        title="No Information Available"
                        description={
                          <>
                            Your virtual account history page is empty.
                            <br /> Make a new transaction and find all your transactions here.
                          </>
                        }
                      />
                    </div>
                  ) : (
                    <>
                      <VirtualMobileMerchantCashpointTransactionTable cashpointID={currentCashpoint?._id} values={values} />
                      <VirtualAccountTransactionTable cashpointID={currentCashpoint?._id} columns={VirtualAccountColumn} values={values} transactionHeader={transactionHeader} isRowClickable={true} />
                    </>
                  )
                ) : (
                  <EmptyView
                    title="No Information Available yet"
                    description={
                      <>
                        Your virtual account history page is empty.
                        <br /> Make a new transaction and find all your transactions here.
                      </>
                    }
                  />
                )}
                {shouldCashpointModalOpen && <DeactivateCashpointPopup title="Deactivate Virtual Account" />}
              </>
            )}
          </MerchantContentWrapper>
        </TabPane>
        <TabPane tab="View All Failed Transactions" key="failed_transactions">
          <MerchantContentWrapper className="full-height-minus-150 fw400 fade-in no-padding-important-mobile">
            <Spacer height={50} />
            {!open && (
              <>
                {cashpointCustomers.length < 1 ? (
                  <div className="d-flex align-center j-center full-width full-height">
                    <EmptyView
                      title="No Information Available"
                      description={
                        <>
                          Your failed web hook notifications page is empty.
                          <br /> Failed web hook notifications would show here if you have any.
                        </>
                      }
                    />
                  </div>
                ) : (
                  <>
                    <MobileVirtualAccountFailedWebhooks merchantID={id} values={values} />
                    <VirtualAccountFailedWebhooks merchantID={id} columns={VirtualAccountColumn} values={values} transactionHeader={failedTransactionHeader} isRowClickable={false} />
                  </>
                )}
              </>
            )}
          </MerchantContentWrapper>
        </TabPane>
        <TabPane tab="Filter History" key="filter-history">
          <MerchantContentWrapper
            className="full-height-minus-150 fw400 fade-in no-padding-important-mobile"
            beforeSidePadding={<Filter needPrint={false} setValues={setDate} setPageNumber={setPageNumber} />}
          >
            <Spacer height={30} />
            <div className="d-flex j-space-between mobile-radio-group">
              <RadioButtons setTransferType={setTransferType} transferType={transferType} />
              <Spacer height={5} className="no-desktop-but-mobile " />
              <>
                {<CountDisplay cardCount={cardPaymentCount} transferCount={inboundTransferCount} allTnxCount={inboundTotalCount} />}
                {<MobileCountDisplay cardCount={cardPaymentCount} transferCount={inboundTransferCount} allTnxCount={inboundTotalCount} />}
              </>
            </div>
            <Spacer height={30} />
            {!isDesktop() && <MobileVirtualAccountFilteredHistory values={date} merchantID={id} transactionHeader={transactionHeader} isRowClickable={true} transferType={transferType} />}
            {isDesktop() && <VirtualAccountFilteredHistory values={date} merchantID={id} transactionHeader={transactionHeader} isRowClickable={true} transferType={transferType} />}
          </MerchantContentWrapper>
        </TabPane>
      </Tabs>
    </MerchantContentWrapper>
  );
}

const CountDisplay = ({ cardCount, transferCount, allTnxCount }) => {
  return (
    <div className="no-mobile-but-desktop bordered">
      <div className="d-flex j-center align-center pr-40 fade-in">
        <p className="fw400 pr-40 display-flex">
          All Inbound Payments Count <span className="transferCount fade-in">{allTnxCount}</span>
        </p>
        <p className="fw400 display-flex">
          Inbound Transfers <span className="transferCount fade-in">{transferCount}</span>
        </p>
        <Spacer width={50} />
        <p className="fw400 pr-40 display-flex">
          Card Payments <span className="transferCount fade-in">{cardCount}</span>
        </p>
      </div>
    </div>
  );
};

const MobileCountDisplay = ({ cardCount, transferCount, allTnxCount }) => {
  return (
    <div className="no-desktop-but-mobile sm-text-alignLeft">
      <Spacer height={10} className="no-desktop-but-mobile " />
      <p className="fw400">
        All Inbound Payments Count <span className="fade-in text-bold">{allTnxCount}</span>
      </p>
      <p className="fw400">
        Inbound Transfers Count:<span className="fade-in text-bold"> {transferCount}</span>
      </p>
      <Spacer width={50} />
      <p className="fw400">
        Card Payments Count: <span className="fade-in text-bold">{cardCount}</span>
      </p>
    </div>
  );
};

export const RadioButtons = ({ setTransferType, transferType }) => {
  const onChange = (e) => {
    setTransferType(e.target.value);
  };
  return (
    <div className="w-50 d-flex flex-column-sm">
      <p className="fw400">Filter Payment History By Payment Type</p>
      <Spacer width={30} className="no-mobile" />
      <Spacer height={5} className="no-desktop-but-mobile " />
      <Radio.Group onChange={onChange} value={transferType} className="flex-stack-col">
        <Radio value="">All</Radio>
        <Radio value="transfer">Inbound Transfer</Radio>
        <Radio value="card">Card Payment</Radio>
      </Radio.Group>
    </div>
  );
};
