import Search from 'antd/lib/input/Search';
import './app_search.css';

export default function SearchInput({ placeholder, allowClear, onSearch }) {
  return (
    <div className="full-width">
      <Search placeholder={placeholder} allowClear={allowClear} onSearch={onSearch} />
    </div>
  );
}
