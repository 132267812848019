import { LoadingOutlined } from '@ant-design/icons';
import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useDispatch, useSelector } from 'react-redux';
import back from '../../assets/images/back-left.svg';
import addIcon from '../../assets/images/Icon.svg';
import payout from '../../assets/images/payout.svg';
import referral from '../../assets/images/referral.svg';
import AppButton from '../../components/app_button/app_button';
import { GradientBackground } from '../../components/contact_content/contact_content';
import { FormInput } from '../../components/form_input/form_input';
import { Spacer } from '../../components/layout/layout';
import { HistoryMobileTransactionContainer, PaginatedPayoutLists } from '../../components/merchant_widgets/merchant_widgets';
import Popup, { SharePopup } from '../../components/popup/popup';
import { useInput } from '../../hooks/use_input';
import { fetchBonusBalance } from '../../redux/merchant/bonus.feature';
import { fetchUserMerchant, openMessageRed, openTransferPopup } from '../../redux/merchant/merchant_actions';
import { addAggregatorService, aggregatorDownline, bonusWithdrawService } from '../../services/apiSevices';
import { formatNumber } from '../../utils/formatNumber';
import { isDesktop } from '../../utils/helper';
import { openNotificationWithIcon } from '../auth/primary_signup/primary_signup';
import { EarningBox, ReferralLinkContainer, ReferralList } from './referral';
import { TabButtons } from './transfer';
import { Withdraw } from './withdraw';

function Bonus() {
  const dispatch = useDispatch();
  const { bank, isaggregator = false, aggregatorid = '' } = useSelector((state) => state.merchant.merchantDetails);
  const { publickey, businessid } = useSelector((state) => state.user.currentMerchant);
  const isWithdrawPopupOpen = useSelector((state) => state.merchant.isWithdrawPopupOpen);
  const [position, setPosition] = useState(1);
  const [showPopup, setShowPopup] = useState(false);
  const [showShare, setShowShare] = useState(false);
  const [_id, setID] = useState('');
  const [_name, setName] = useState('');
  const { data } = useSelector((state) => state.bonus.bonusBalance);
  const { referrals, referral_link } = useSelector((state) => state.referral.referrals);

  useEffect(() => {
    dispatch(fetchBonusBalance(isaggregator ? 'aggregator' : 'merchant', publickey));
    return () => null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publickey]);

  // const bonusBalance = isaggregator ? data?.accrued_balance : data?.bonus_balance;

  const onPopupClose = () => {
    dispatch(fetchUserMerchant(businessid));
    setShowPopup(false);
  };

  const onWithdrawClick = () => {
    if (!bank) {
      openMessageRed(dispatch);
    } else {
      openTransferPopup(dispatch);
    }
  };
  return (
    <div className="p-relative fade-in">
      <GradientBackground className="bonus-grad" justifyContent="flex-start">
        <Spacer height={50} />
        <div className="">
          <div className="d-flex align-center p-relative earnings-container">
            <div>
              <p className="fw400 primary-color font-size-16 line-height17">Withdrawable Earnings</p>
              <p className="fw700 primary-color font-size-18 line-height17">{`₦ ${formatNumber(data?.withdrawable_balance ?? 0)}`}</p>
            </div>
            <div className="d-flex align-center no-mobile  column-gap1">
              <EarningBox top={`₦ ${formatNumber(data?.paid_balance ?? 0)}`} bottom="Paid Earnings" className="bonus-earn" />
              <EarningBox top={`₦ ${formatNumber(data?.accrued_balance ?? 0)}`} bottom="Accrued Earnings" className="bonus-earn" />
            </div>
            <img onClick={() => onWithdrawClick()} className="c-pointer p-i" src={payout} alt="click to withdraw Earnings" />
            <Spacer height={30} className="no-desktop-but-mobile" />
          </div>
        </div>
      </GradientBackground>
      <div className="d-flex align-center bonus-box no-desktop-but-mobile-flex  column-gap1">
        <EarningBox top={`₦ ${formatNumber(data?.accrued_balance ?? 0)}`} bottom="Accrued Earnings" className="bonus-earn" />
        <EarningBox top={`₦ ${formatNumber(data?.paid_balance ?? 0)}`} bottom="Paid Earnings" className="bonus-earn" />
      </div>
      <Spacer className="no-desktop-but-mobile" height={20} />
      <div className="d-flex flex-vertical p-relative unstack-div align-center">
        {position === 1 && <BonusContainer setShowPopup={setShowPopup} setPosition={setPosition} />}
        {position === 2 && <DownlineList setName={setName} setID={setID} setPosition={setPosition} />}
        {position === 3 && <PayoutHistory setPosition={setPosition} />}
        {position === 4 && (
          <ReferralLinkContainer setShowPopup={setShowShare} goBack={() => setPosition(1)} setPosition={setPosition} position={5} link={referral_link}>
            <div className="full-width d-flex align-center flex-vertical">
              <Spacer height={20} className="" />
              {isaggregator && (
                <CopyToClipboard text={aggregatorid} onCopy={() => openNotificationWithIcon('success', 'Success', 'Aggregator Code Copied')}>
                  <span className="action-color c-pointer text-center  fw500 font-size-14">
                    {isDesktop() ? `Click` : `Tap`} To Copy Aggregator Code: {aggregatorid}
                  </span>
                </CopyToClipboard>
              )}
            </div>
          </ReferralLinkContainer>
        )}
        {position === 5 && <ReferralList referrals={referrals} setPosition={setPosition} />}
        {position === 6 && <MemberHistory _name={_name} setPosition={setPosition} id={_id} />}
        {isWithdrawPopupOpen && <Withdraw withdrawService={bonusWithdrawService} />}
        {showPopup && <AddAggregatorPopup onClose={() => onPopupClose()} />}
        {showShare && <SharePopup url={referral_link} onClose={() => setShowShare(false)} />}
      </div>
    </div>
  );
}

export default Bonus;

export const TabButtonsWithChldren = (Component) => {
  const [activeTab, setActiveTab] = useState(1);
  const payouts = useSelector((state) => state.merchant.payouts);
  // eslint-disable-next-line
  const [_, setCurrentRecord] = useState(null);
  const [show, setShow] = useState(false);
  // eslint-disable-next-line react/display-name
  return ({ children, ...rest }) => {
    const currentComponent = React.Children.toArray(children)[activeTab - 1];
    if (React.isValidElement(currentComponent)) {
      return (
        <Component activeTab={activeTab} setActiveTab={setActiveTab} {...rest}>
          <Spacer height={10} />
          {React.cloneElement(currentComponent, { activeTab, setActiveTab, payouts, setCurrentRecord, show, setShow, ...rest })}
        </Component>
      );
    } else {
      return <Component activeTab={activeTab} setActiveTab={setActiveTab} {...rest} />;
    }
  };
};

const DownlineList = ({ setPosition, setID, setName }) => {
  const dataRef = useRef([]);
  const { publickey } = useSelector((state) => state.user.currentMerchant);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);

  const observer = useRef();
  const lastElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          if (data.length > 9) {
            setPageNumber((prev) => prev + 1);
          }
        }
      });
      if (node) observer.current.observe(node);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loading, hasMore],
  );

  useEffect(() => {
    setPageNumber(1);
    setData([]);
    return () => null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publickey]);

  useEffect(() => {
    setLoading(true);
    onChange();
    return () => null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, publickey]);

  const onChange = async () => {
    try {
      const {
        data: { data },
      } = await aggregatorDownline(publickey, pageNumber);
      setData((prevData) => {
        return [...prevData, ...data.downlines];
      });
      dataRef.current = data?.downlines;
      setHasMore(data?.downlines.length > 0);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const onVieHistoryClick = (id, name) => {
    setID(id);
    setName(name);
    setPosition(6);
  };

  return (
    <div className="ref-link-container p-relative  d-flex flex-vertical">
      <p className="text-color fw500 font-size-17">Members</p>
      <div onClick={() => setPosition(1)} className="d-flex align-center column-gap1 g-b-arrow p-absolute ">
        <img className="" src={back} alt="go back arrow" />
        <p className="fw500 action-color font-size-14">Back</p>
      </div>
      <Spacer height={20} />
      <div className="ref-box full-width">
        {data &&
          data.length > 0 &&
          data.map(({ contactlastname, contactfirstname, businessname, _id }, idx) => (
            <DownlineItem
              onClick={() => onVieHistoryClick(_id, businessname)}
              ref={data.length === idx + 1 ? lastElementRef : null}
              key={idx}
              name={contactfirstname + ' ' + contactlastname}
              businessname={businessname}
            />
          ))}
        {(data?.length < 1 || !data) && <p className="fw400 font-size-17 text-center text-color">Invite customers to transfer using Vpay and Earn.</p>}
        <Spacer height={20} />
        <div className="full-width d-flex align-center j-center">{loading && <LoadingOutlined style={{ fontSize: '1.18em' }} size="large" />}</div>
        <Spacer height={20} />
      </div>
    </div>
  );
};
// eslint-disable-next-line react/display-name
const DownlineItem = React.forwardRef(({ businessname, name, onClick }, ref) => {
  return (
    <div ref={ref} className="d-flex ref-item full-width j-space-between align-baseline">
      <div className="d-flex flex-vertical">
        <p className="text-color fw400 font-size-17">{businessname}</p>
        <Spacer height={5} />
      </div>
      <div className="d-flex flex-vertical align-end row-gap05">
        <p className="text-color fw400 opacity-5">{name}</p>
        <p onClick={onClick} className="action-color fw500 c-pointer">
          View History
        </p>
      </div>
      {/* <p className="fw400 font-size-15 reward-price">₦ {numberWithCommas(bonus_amount)}</p> */}
    </div>
  );
});

const BonusContainer = ({ setPosition, setShowPopup }) => {
  const merchantDetails = useSelector((state) => state.merchant.merchantDetails);
  // const { data } = useSelector((state) => state.bonus.bonusBalance);
  return (
    <div className="ref-link-container d-flex flex-vertical">
      <p className="text-color max-width-250 fw500 font-size-14 text-center">Manage your agency banking {merchantDetails.isaggregator && 'members, '}bonuses and earnings all in one spot. </p>
      <Spacer height={25} />
      {!merchantDetails.isaggregator && !merchantDetails.aggregatorcode && (
        <div className="d-flex flex-vertical align-center">
          <img onClick={() => setShowPopup(true)} className="addDownline c-pointer" src={addIcon} alt="Bonus" />
          <Spacer height={10} />
          <p className="text-color font-size-15 text-center">Click the plus icon to add an aggregator</p>
        </div>
      )}
      {(merchantDetails.aggregatorcode || merchantDetails.isaggregator) && <img src={referral} className="full-width" alt="Bonus" />}
      {!merchantDetails?.removereverrallinkpage && (
        <Fragment>
          <Spacer height={25} />
          <span onClick={() => setPosition(4)} className="action-color c-pointer fw500 font-size-14">
            {isDesktop() ? `Click` : `Tap`} To Get Your {merchantDetails.isaggregator && 'Aggregator Code and '}Referral Link
          </span>
        </Fragment>
      )}

      {/* <Spacer height={20} />
      {(formatNumber(data?.accrued_balance) !== 0 || formatNumber(data?.withdrawable_balance) !== 0) && (
        <>
          <span className="text-color fw400 text-center font-size-16">Your next withdrawal cycle is on: </span>
          <span className="payment-time-stroke fw400 text-center">{formatDay(data?.paymentcycle)}</span>
        </>
      )} */}
      <Spacer height={30} className="no-mobile" />
      <Spacer height={30} className="no-desktop-but-mobile" />
      {merchantDetails.isaggregator && (
        <button onClick={() => setPosition(2)} className="share-ref _b-share c-pointer fw500 font-size-15 primary-color">
          View Members
        </button>
      )}
      <Spacer height={10} />
      <button onClick={() => setPosition(3)} className="view-refs c-pointer fw500 font-size-15 primary-color">
        Payout History
      </button>
      <Spacer height={30} className="no-desktop-but-mobile" />
    </div>
  );
};

export const PayoutHistory = ({ setPosition }) => {
  const NewTabsButton = TabButtonsWithChldren(TabButtons);
  const { isaggregator = false } = useSelector((state) => state.merchant.merchantDetails);
  const actor = isaggregator ? 'aggregator' : 'merchant';

  return (
    <div className="ref-link-container  p-relative  d-block flex-vertical">
      <p className="text-color fw500 text-center font-size-17">Payout History</p>
      <div onClick={() => setPosition(1)} className="d-flex align-center column-gap1 g-b-arrow p-absolute ">
        <img className="" src={back} alt="go back arrow" />
        <p className="fw500 action-color font-size-14">Back</p>
      </div>
      <Spacer height={30} />
      <NewTabsButton firstText="Earnings History" secondText="Payout History">
        <HistoryMobileTransactionContainer actor={actor} emptyTitle="No Earnings" emptyDescription="Earnings history is empty" isBonus noClick shouldDisplay bonusType="credit" />
        <HistoryMobileTransactionContainer actor={actor} isBonus emptyDescription="Payout history is empty" noClick shouldDisplay bonusType="debit" />
      </NewTabsButton>
    </div>
  );
};

export const AddAggregatorPopup = ({ onClose }) => {
  const aggregatorProps = useInput('');
  const [loading, setLoading] = useState(false);
  const { publickey } = useSelector((state) => state.user.currentMerchant);
  const onClick = async () => {
    const { value } = aggregatorProps;
    if (value) {
      let data = {
        aggregatorcode: value,
      };
      try {
        setLoading(true);
        await addAggregatorService(data, publickey);
        openNotificationWithIcon('success', 'Success', 'Aggregator added successfully');
        setLoading(false);
        onClose();
      } catch (error) {
        setLoading(false);
        openNotificationWithIcon('error', 'Error', error.response?.data?.message);
        console.error(error);
      }
    }
  };

  return (
    <Popup width="30%" onClose={onClose}>
      <div className="p-relative p20">
        <FormInput name="aggregatorCode" className="important-full-width" label="Enter Aggregator Code" {...aggregatorProps} />
        <AppButton isActive={true} isBusy={loading} onClick={onClick} className="done-editing font-size-17 fw400 full-width primary-color" name="Add" />
      </div>
    </Popup>
  );
};

export const MemberHistory = ({ setPosition, id, _name }) => {
  return (
    <div className="member-history ref-link-container  p-relative  d-block flex-vertical">
      <p className="text-color fw500 text-center font-size-17">{_name} History</p>
      <div onClick={() => setPosition(2)} className="d-flex align-center column-gap1 g-b-arrow p-absolute ">
        <img className="" src={back} alt="go back arrow" />
        <p className="fw500 action-color font-size-14">Back</p>
      </div>
      <Spacer height={30} />
      <PaginatedPayoutLists id={id} />
    </div>
  );
};
