import { Fragment, useEffect } from 'react';
import { useLocation, withRouter } from 'react-router-dom';

//This function takes the user to the top of the page when navigating
//The smooth behaviour makes the scrolling noticeably nice
export const ScrollToTop = withRouter(({ history, children }) => {
  const { pathname } = useLocation();
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, [pathname, history]);

  return <Fragment>{children}</Fragment>;
});
