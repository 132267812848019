import { useCallback, useEffect, useRef, useState } from 'react';

export const usePaginate = (id, pageNumber, setPageNumber, dataSource, values, reload) => {
  const dataRef = useRef([]);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [error, setError] = useState(null);
  const [pageSize, setPageSize] = useState(null);
  const [currentList, setCurrentList] = useState([]);

  const observer = useRef();
  const lastElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          if (data.length > 9) {
            setPageNumber((prev) => prev + 1);
          }
        }
      });
      if (node) observer.current.observe(node);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loading, hasMore],
  );

  useEffect(() => {
    setPageNumber(1);
    setData([]);
    // eslint-disable-next-line react/display-name
    return () => null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const onChange = async () => {
    try {
      const data = await dataSource();
      setCurrentList(data);
      if (!data?.transactions) {
        if (pageNumber === 1) {
          setData(data);
        } else {
          setData((prevData) => {
            return [...prevData, ...data];
          });
        }
        dataRef.current = data;
        setHasMore(data.length > 0);
      } else {
        if (pageNumber === 1) {
          setData(data?.transactions);
        } else {
          setData((prevData) => {
            return [...prevData, ...data.transactions];
          });
        }
        dataRef.current = data?.transactions;
        setHasMore(data?.transactions.length > 0);
      }
      setPageSize(data?.pages);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error?.response?.data);
    }
  };

  useEffect(() => {
    setLoading(true);
    setError(null);
    onChange();
    // eslint-disable-next-line react/display-name
    return () => null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, id, values?.from, values?.to, reload]);

  return { loading, error, data, hasMore, lastElementRef, observer, pageSize, onChange, currentList };
};

export const useBulkPaginate = (id, pageNumber, setPageNumber, dataSource) => {
  const dataRef = useRef([]);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [error, setError] = useState(null);
  const [pageSize, setPageSize] = useState(null);
  const [currentList, setCurrentList] = useState([]);

  const observer = useRef();
  const lastElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          if (data.length > 9) {
            setPageNumber((prev) => prev + 1);
          }
        }
      });
      if (node) observer.current.observe(node);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loading, hasMore],
  );

  useEffect(() => {
    setPageNumber(1);
    setData([]);
    // eslint-disable-next-line react/display-name
    return () => null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const onChange = async () => {
    try {
      const data = await dataSource();
      setCurrentList(data);
      if (!data?.groups) {
        if (pageNumber === 1) {
          setData(data);
        } else {
          setData((prevData) => {
            return [...prevData, ...data];
          });
        }
        dataRef.current = data;
        setHasMore(data.length > 0);
      } else {
        if (pageNumber === 1) {
          setData(data);
        } else {
          setData((prevData) => {
            return [...prevData, ...data];
          });
        }
        dataRef.current = data?.groups;
        setHasMore(data?.length > 0);
      }
      setPageSize(data?.pages);
      setLoading(false);
    } catch (error) {
      setError(error.data?.data?.message);
    }
  };

  useEffect(() => {
    setLoading(true);
    setError(null);
    onChange();
    // eslint-disable-next-line react/display-name
    return () => null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, id]);

  return { loading, error, data, hasMore, lastElementRef, observer, pageSize, onChange, currentList };
};

export const useBenficiaryPaginate = (id, pageNumber, setPageNumber, dataSource) => {
  const dataRef = useRef([]);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [error, setError] = useState(null);
  const [pageSize, setPageSize] = useState(null);
  const [currentList, setCurrentList] = useState([]);

  const observer = useRef();
  const lastElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          if (data?.length > 9) {
            setPageNumber((prev) => prev + 1);
          }
        }
      });
      if (node) observer.current.observe(node);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loading, hasMore],
  );

  useEffect(() => {
    setPageNumber(1);
    setData([]);
    // eslint-disable-next-line react/display-name
    return () => null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const onChange = async () => {
    try {
      const data = await dataSource();
      setCurrentList(data);
      if (!data?.beneficiaries) {
        if (pageNumber === 1) {
          setData(data?.beneficiaries);
        } else {
          setData((prevData) => {
            return [...prevData, ...(data?.beneficiaries || [])];
          });
        }
        dataRef.current = data?.beneficiaries;
        setHasMore(data?.beneficiaries.length > 0);
      } else {
        if (pageNumber === 1) {
          setData(data?.beneficiaries);
        } else {
          setData((prevData) => {
            return [...prevData, ...(data?.beneficiaries || [])];
          });
        }
        dataRef.current = data?.beneficiaries;
        setHasMore(data?.beneficiaries?.length > 0);
      }
      setPageSize(data?.pages);
      setLoading(false);
    } catch (error) {
      setError(error.data?.data?.message);
    }
  };

  useEffect(() => {
    setLoading(true);
    setError(null);
    onChange();
    // eslint-disable-next-line react/display-name
    return () => null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, id]);

  return { loading, error, data, hasMore, lastElementRef, observer, pageSize, onChange, currentList };
};

export const usePaginateFailedWebTnx = (id, pageNumber, setPageNumber, dataSource, values, reload) => {
  const dataRef = useRef([]);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [error, setError] = useState(null);
  const [pageSize, setPageSize] = useState(null);
  const [currentList, setCurrentList] = useState([]);

  const observer = useRef();
  const lastElementRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore) {
          if (data.length > 9) {
            setPageNumber((prev) => prev + 1);
          }
        }
      });
      if (node) observer.current.observe(node);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loading, hasMore],
  );

  useEffect(() => {
    setPageNumber(1);
    setData([]);
    // eslint-disable-next-line react/display-name
    return () => null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const onChange = async () => {
    try {
      const data = await dataSource();
      setCurrentList(data);
      if (!data?.inbounds) {
        if (pageNumber === 1) {
          setData(data);
        } else {
          setData((prevData) => {
            return [...prevData, ...data];
          });
        }
        dataRef.current = data;
        setHasMore(data.inbounds.length > 0);
      } else {
        if (pageNumber === 1) {
          setData(data?.inbounds);
        } else {
          setData((prevData) => {
            return [...prevData, ...data.inbounds];
          });
        }
        dataRef.current = data?.inbounds;
        setHasMore(data?.inbounds.length > 0);
      }
      setPageSize(data?.pages);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error.data?.data?.message);
    }
  };

  useEffect(() => {
    setLoading(true);
    setError(null);
    onChange();
    // eslint-disable-next-line react/display-name
    return () => null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, id, values?.from, values?.to, reload]);

  return { loading, error, data, hasMore, lastElementRef, observer, pageSize, onChange, currentList };
};
