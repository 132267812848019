// import ImageBackground from '../image_background/image_background';
import peterOlexa from '../../assets/images/peterOlexa.png';
import mobileApp from '../../assets/images/newVpayMobile.png';
import './home_about.css';
// import CircleAvatar from '../circle_avatar/circle_avatar';
// import colors from '../../utils/color/colors';
import { Spacer } from '../layout/layout';

export default function HomeAbout() {
  return (
    <div className="small-content-padding container mobile-spacing home-about flex-vertical-mobile d-flex">
      <div className="p-relative home-about-left d-flex align-center j-center">
        <img src={peterOlexa} alt="Peter Olexa using vpay" />
        {/* <button type='button' className='unstyle-button p-absolute c-pointer play-button'>
                    <FontAwesomeIcon className='play' icon={faPlay} />
                </button> */}
      </div>
      <img className="p-relative home-about-mobile" src={mobileApp} alt="Peter Olexa using vpay" />
      <Spacer width={60} />
      <div className="d-flex flex-vertical home-about-right j-center">
        <h2 className="fw500 home-about-header-text line-height12 primary-text no-margin">VPay is about more than Selling</h2>
        <Spacer height={20} />
        <div className="home-about-text fw400 line-height17" style={{ maxWidth: '600px' }}>
          VPay is a payment solution that enables cashiers and online shopping carts to increase customer loyalty by accepting payments via bank transfer and instantly confirm these payments without
          depending on the business owner or accountant.
          <Spacer height={20} />
          <span className="second-about-text">Our mission is to simplify the bank transfer option for businesses and their customers when shopping in Africa.</span>
        </div>
      </div>
    </div>
  );
}
