import { GradientBackground } from '../../components/contact_content/contact_content';

import back from '../../assets/images/back-left.svg';
import payout from '../../assets/images/payout.svg';
import referral from '../../assets/images/referral.svg';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useDispatch, useSelector } from 'react-redux';
import { Spacer } from '../../components/layout/layout';
import { openNotificationWithIcon } from '../auth/primary_signup/primary_signup';

import { useEffect, useState } from 'react';
import { fecthReferralDetails } from '../../redux/merchant/merchant.referral';
import { formatNumber, numberWithCommas } from '../../utils/formatNumber';

import { SharePopup } from '../../components/popup/popup';
import { openMessageRed, openTransferPopup } from '../../redux/merchant/merchant_actions';
import { referralWithdraw } from '../../services/apiSevices';
import { Withdraw } from './withdraw';

export default function Referral() {
  const { mid, bank } = useSelector((state) => state.merchant.merchantDetails);
  const isWithdrawPopupOpen = useSelector((state) => state.merchant.isWithdrawPopupOpen);
  const [position, setPosition] = useState(1);
  const [showPopup, setShowPopup] = useState(false);
  const dispatch = useDispatch();
  const { referrals, total_referral_balance, referral_link, total_possible_referral_balance } = useSelector((state) => state.referral.referrals);
  useEffect(() => {
    dispatch(fecthReferralDetails(mid));
    return () => null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mid]);

  const onWithdrawClick = () => {
    if (!bank) {
      openMessageRed(dispatch);
    } else {
      openTransferPopup(dispatch);
    }
  };
  return (
    <div className="p-relative fade-in">
      <GradientBackground justifyContent="flex-start" className="bonus-grad" height="20em">
        <Spacer height={50} />
        <div className="">
          <div className="d-flex align-center p-relative earnings-container">
            <div className="no-mobile">
              <p className="fw400 primary-color font-size-18 line-height17">My Referral Earnings</p>
              <p className="fw700 primary-color font-size-20 line-height17">{`₦ ${formatNumber(total_possible_referral_balance ?? 0)}`}</p>
            </div>
            <div className="no-desktop-but-mobile">
              <p className="fw400 primary-color font-size-18 line-height17">Withdrawable Earnings</p>
              <p className="fw700 primary-color font-size-20 line-height17">{`₦ ${formatNumber(total_referral_balance ?? 0)}`}</p>
            </div>
            <div className="d-flex align-center  no-mobile column-gap1">
              <EarningBox top={`₦ ${formatNumber(total_referral_balance ?? 0)}`} bottom="Withdrawable Earnings" />
              <EarningBox top={`₦ ${formatNumber(0)}`} bottom="Paid Earnings" />
            </div>
            <img onClick={() => onWithdrawClick()} className="c-pointer p-i" src={payout} alt="click to withdraw Earnings" />
            <Spacer height={30} className="no-desktop-but-mobile" />
          </div>
        </div>
      </GradientBackground>
      <div className="d-flex align-center bonus-box no-desktop-but-mobile-flex  column-gap1">
        <EarningBox top={`₦ ${formatNumber(total_possible_referral_balance ?? 0)}`} bottom="Referral Earnings" className="bonus-earn" />
        <EarningBox top={`₦ ${formatNumber(0)}`} bottom="Paid Earnings" className="bonus-earn" />
      </div>
      <Spacer className="no-desktop-but-mobile" height={20} />
      <div className="d-flex flex-vertical p-relative unstack-div align-center">
        {position === 1 && <ReferralLinkContainer show_ref_detail setShowPopup={setShowPopup} setPosition={setPosition} link={referral_link} />}
        {position === 2 && <ReferralList referrals={referrals} setPosition={setPosition} />}
        {showPopup && <SharePopup url={referral_link} onClose={() => setShowPopup(false)} />}
        {isWithdrawPopupOpen && <Withdraw withdrawService={referralWithdraw} total_referral_balance={total_referral_balance} />}
      </div>
    </div>
  );
}

const EarningBox = ({ top, bottom, className }) => (
  <div className={`${className} earning-box`}>
    <p className="fw700 primary-color font-size-18 line-height17">{top}</p>
    <p className="fw400 primary-color box-below-text line-height17">{bottom}</p>
  </div>
);

const ReferralLinkContainer = ({ link = '', setPosition, setShowPopup, position, children, goBack, show_ref_detail }) => {
  const { referral_text } = useSelector((state) => state.referral.referrals);
  return (
    <div className="ref-link-container p-relative d-flex flex-vertical">
      {goBack && (
        <div onClick={() => goBack()} className="d-flex align-center column-gap1 g-b-arrow p-absolute ">
          <img className="" src={back} alt="go back arrow" />
          <p className="fw500 action-color font-size-14">Back</p>
        </div>
      )}
      <img src={referral} alt="Referral F" />
      <Spacer height={25} />
      {show_ref_detail && (
        <>
          <p className="text-color max-width-250 fw400 font-size-14 text-center">{referral_text ?? ''}</p>
          <Spacer height={25} />
        </>
      )}
      <div className="p-relative fw">
        <input className="copy-link-input" disabled readOnly value={link} onChange={() => {}} />
        <CopyToClipboard text={link} onCopy={() => openNotificationWithIcon('success', 'Success', 'Referral Link Copied')}>
          <span className="action-color c-pointer c-link fw400 font-size-14">Copy Link</span>
        </CopyToClipboard>
      </div>
      <Spacer height={6} />
      <button onClick={() => setShowPopup(true)} className="share-ref _share c-pointer fw500 font-size-15">
        Share Referral Link
      </button>
      <Spacer height={6} />
      {show_ref_detail && (
        <button onClick={() => setPosition(position ?? 2)} className="view-refs c-pointer fw500 font-size-15 primary-color">
          View Referrals
        </button>
      )}
      {children}
      <Spacer height={30} className="no-desktop-but-mobile" />
    </div>
  );
};

const ReferralItem = ({ bonus_amount, KYC_level, businessname }) => {
  return (
    <div className="d-flex ref-item full-width j-space-between align-baseline">
      <div className="d-flex flex-vertical">
        <p className="text-color fw400 font-size-17">{businessname}</p>
        <Spacer height={5} />
        <p className="text-color fw400 opacity-5">KYC Level {KYC_level}</p>
      </div>
      <p className="fw400 font-size-15 reward-price">₦ {numberWithCommas(bonus_amount)}</p>
    </div>
  );
};

const ReferralList = ({ setPosition, referrals }) => {
  return (
    <div className="ref-link-container p-relative  d-flex flex-vertical">
      <p className="text-color fw400 font-size-17">Referrals</p>
      <div onClick={() => setPosition(1)} className="d-flex align-center column-gap1 g-b-arrow p-absolute ">
        <img className="" src={back} alt="go back arrow" />
        <p className="fw500 action-color font-size-14">Back</p>
      </div>
      <Spacer height={40} />
      <div className="ref-box full-width">
        {referrals &&
          referrals.length > 0 &&
          referrals.map(({ bonus_amount, KYC_level, businessname }, idx) => <ReferralItem key={idx} bonus_amount={bonus_amount} KYC_level={KYC_level} businessname={businessname} />)}
        {referrals && referrals.length < 1 && <p className="fw400 font-size-17 text-center text-color">Invite customers to transfer using Vpay and Earn.</p>}
      </div>
    </div>
  );
};

export { EarningBox, ReferralLinkContainer, ReferralItem, ReferralList };
