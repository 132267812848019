import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import logo from '../../assets/images/not-found.png';
import AppButton from '../../components/app_button/app_button';
import { Spacer } from '../../components/layout/layout';
import { emails_to_send_crash_report } from '../../constants/emails';
import { useSendCrashReportMutation } from '../../services/hooks/useSendCrashReportMutation';
import { openNotificationWithIcon } from '../auth/primary_signup/primary_signup';
import './not_found.css';

export default function NotFoundPage({ error, errorInfo, sendReport }) {
  const history = useHistory();
  const { mutateAsync, isLoading } = useSendCrashReportMutation();

  const handleClick = () => {
    mutateAsync({
      subject: `Crash Report - ${process.env.REACT_APP_ENV === 'dev' ? 'Staging' : 'Production'}`,
      emails: emails_to_send_crash_report,
      message: `
      Device Agent: ${window.navigator.userAgent.replace(/KHTML/g, '')}
      Error Report: ${errorInfo?.errorInfo?.componentStack}
        `,
    })
      .then(() => {
        openNotificationWithIcon('success', 'Success', 'This error has been reported successfully. Please kindly refresh your browser while we fix this issue');
      })
      .catch(() => openNotificationWithIcon('error', 'Error', 'Error sending report.Please try again'));
  };

  return (
    <div className="full-height full-width info-padding d-flex j-center align-center flex-column">
      <div className="container-inner-not-found">
        <div className="error-code fw400 no-margin">404</div>
        <img src={logo} className="not-found-img" alt="logo for not found" />
      </div>
      <Spacer height={20} />
      <div className="error-reason">{error}</div>
      <Spacer height={20} />
      {sendReport ? (
        <>
          <AppButton name="Send Report" isBusy={isLoading} onClick={handleClick} isActive={true} className="cashier-change-btn error-btn-max-width" color="#FDFDFD" />
          <Spacer height={20} />
          <button onClick={() => window.location.reload()} className="unstyle-button action-color d-flex align-center c-pointer column-gap1">
            <span className="fw500 font-size-15">Refresh your browser</span>
          </button>
        </>
      ) : (
        <button onClick={() => history.push('/')} className="unstyle-button action-color d-flex align-center c-pointer column-gap1">
          <FontAwesomeIcon icon={faArrowLeft} className="c-pointer" />
          <span className="fw500 font-size-15">Back to home</span>
        </button>
      )}
    </div>
  );
}
