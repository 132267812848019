export default function colors() {
  return {
    color: '',
    custom: {
      color: function (color) {
        // console.log(this);
        this.color = color;
        return {
          value: this.color,
          withOpacity: function (opacity) {
            // console.log({ self: this })
            const { r, g, b } = convertHexTODecimal(this.value);
            return r && g && b ? `rgba(${r}, ${g}, ${b}, ${opacity})` : this.value;
          },
        };
      },
    },
    primaryColor: {
      value: 'rgb(255,255,255)',
      withOpacity: function (opacity) {
        return `rgba(255,255,255, ${opacity})`;
      },
    },
    textColor: {
      value: 'rgb(32,14,50)',
      withOpacity: function (opacity) {
        return `rgba(32,14,50, ${opacity})`;
      },
    },
    accentColor: {
      value: 'rgb(171,56,129)',
      withOpacity: function (opacity) {
        return `rgba(171,56,129, ${opacity})`;
      },
    },
  };
}

const decimalReps = (hex) => {
  const value = hex && hex.toLowerCase();
  if (value) {
    const numberRep = Number(hex);
    if (!isNaN(numberRep)) {
      return numberRep;
    } else {
      switch (value) {
        case 'a':
          return 10;
        case 'b':
          return 11;
        case 'c':
          return 12;
        case 'd':
          return 13;
        case 'e':
          return 14;
        case 'f':
          return 15;
        default:
          return;
      }
    }
  }
};

const computeHexValue = (hex) => {
  if (hex) {
    let total = 0;
    let carry = 0;
    for (let i = hex.length - 1; i >= 0; i--) {
      const decimalRep = decimalReps(hex[i]);
      if (decimalReps) {
        total += decimalRep * 16 ** carry;
        carry++;
      } else {
        total = null;
        break;
      }
    }
    return total;
  }
};
const convertHexTODecimal = (hex) => {
  if (!hex || hex.length !== 6) return;
  const firstSlice = hex.slice(0, 2);
  const secondSlice = hex.slice(2, 4);
  const thirdSlice = hex.slice(4, 6);
  const r = computeHexValue(firstSlice);
  const g = computeHexValue(secondSlice);
  const b = computeHexValue(thirdSlice);

  return { r, g, b };
};
