import cardOverlay from '../../assets/images/cardOverlay.svg';

import './dashboard_card.css';

/**
 *
 * @param {ReactNode} children
 * @param {string} height - Specifies the height of the container
 * @param {string} width - Specifies the width of the container
 * @param {boolean} center - If true, justify Card Children center
 * @param {string} backgroundColor - Specifies the background color of the container
 * @param {boolean} needOverlay - Specifies whether the Vpay logo is displaying in the bg
 */

export default function DashBoardCard({ children, className, height, width, background, center, needOverlay, onClick }) {
  return (
    <div onClick={onClick} className={(className || '') + ' p-relative card-container'} style={{ height: height, width: width, background: background, justifyContent: `${center && 'center'}` }}>
      {needOverlay && <img src={cardOverlay} alt="" className="card-overlay" />}
      {children}
    </div>
  );
}

export function DashBoardCardWithNoImage({ children, className, height, width, background, center }) {
  return (
    <div className={(className || '') + ' p-relative card-container'} style={{ height: height, width: width, background: background, justifyContent: `${center && 'center'}` }}>
      {children}
    </div>
  );
}
