import { LoadingOutlined } from '@ant-design/icons';
import { Fragment, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { Spacer } from '../../components/layout/layout';
import { DesktopWebFailedTransactionItem, EmptyView, FailedWebMobileTransactionContainer, MerchantContentWrapper, WebOrderFilter } from '../../components/merchant_widgets/merchant_widgets';
import { usePaginateFailedWebTnx } from '../../hooks/use_paginate';
import { getFailedWebOrderTnxs } from '../../services/apiSevices';
import { isDesktop } from '../../utils/helper';

const WebFailedOrders = () => {
  const merchantID = useSelector((state) => state.user.currentMerchant['businessid']);
  const orderHeaders = ['Account', 'Account Name', 'Amount', 'Failure Reason', 'Date', 'Reversal Status'];
  const [pageNumber, setPageNumber] = useState(1);

  // get failed records
  const getTnxsService = useCallback(async () => {
    const res = await getFailedWebOrderTnxs(pageNumber);
    return res?.data?.data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, merchantID]);

  const { data: inbound, loading, lastElementRef, error } = usePaginateFailedWebTnx(merchantID, pageNumber, setPageNumber, getTnxsService);

  return (
    <MerchantContentWrapper
      className="full-height-minus-150 fw400 fade-in no-padding-important-mobile"
      beforeSidePadding={
        <div className="d-flex align-center flex-vertical-mobile">
          <WebOrderFilter />
        </div>
      }
    >
      <Spacer height={40} className="no-mobile" />
      {!isDesktop() && <FailedWebMobileTransactionContainer />}

      {inbound && inbound.length > 0 ? (
        <>
          <div className="desktop-history-trans no-mobile table-padding primary-bg-color full-width border-radius5 height_auto">
            <div className="d-flex desktop-history-trans-header">
              {orderHeaders.map((header) => (
                <p className="font-size-15 fw500" key={header}>
                  {header}
                </p>
              ))}
            </div>
            <div>
              {inbound
                ? inbound.map((tnx, idx) => {
                    return (
                      <Fragment key={tnx._id}>
                        <DesktopWebFailedTransactionItem t={tnx} ref={inbound.length === idx + 1 ? lastElementRef : null} />
                      </Fragment>
                    );
                  })
                : []}
            </div>
          </div>
          {loading && <Spacer height={20} />}
          <div className="full-width d-flex align-center j-center">{loading && <LoadingOutlined style={{ fontSize: '1em' }} size="large" />}</div>
        </>
      ) : (
        <>
          <Spacer className="no-desktop-but-mobile" height={100} />
          <div className="d-flex align-center no-mobile j-center full-width full-height">
            {!loading && (
              <EmptyView
                title="no failed orders yet"
                description={
                  <>
                    Your failed order page is empty.
                    <br /> Make a new transaction and find all your transactions here.
                  </>
                }
              />
            )}
            {loading && <LoadingOutlined style={{ fontSize: '1em' }} size="large" />}
          </div>
        </>
      )}
    </MerchantContentWrapper>
  );
};

export default WebFailedOrders;
