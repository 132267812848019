import { MerchantContentWrapper } from '../../../components/merchant_widgets/merchant_widgets';
import { SupportPage } from '../../merchant/settings';

const CashpointSupport = () => {
  return (
    <MerchantContentWrapper className="d-flex flex-vertical full-height">
      <SupportPage />
    </MerchantContentWrapper>
  );
};

export default CashpointSupport;
