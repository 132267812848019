import { LoadingOutlined } from '@ant-design/icons';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import info_purple from '../../assets/images/Info_purple.svg';
import dangerIcon from '../../assets/images/danger.png';
import folderIcon from '../../assets/images/folder-open.svg';
import userIcon from '../../assets/images/purple-user-icon.png';
import { useFormFileWithW3 } from '../../hooks/use_form_file';
import { useInput } from '../../hooks/use_input';
import { openNotificationWithIcon } from '../../pages/auth/primary_signup/primary_signup';
import { resetMemberEmail, resetMemberId, resetMemberRole, setMemberEmail, setMemberId, setMemberName, setMemberRole, setShowMemberDetails } from '../../redux/merchant/roles.feature';
import { addTeamMember, lookupCashpoint, removeTeamMemberRole, updateTeamMemberRole } from '../../services/apiSevices';
import { getInLS } from '../../utils/local';
import AppButton, { AppButtonWithIcon } from '../app_button/app_button';
import { FormDropdown } from '../form_dropdown/form_dropdown';
import { FormInput } from '../form_input/form_input';
import { Spacer } from '../layout/layout';
import { RolesImageUpload, WhyImagePopup } from '../new_popup_widgets/popup_widgets';
import Popup, { RolesVideoInfoPopup } from '../popup/popup';
import { RenderVideo } from '../webcam/webcam';
import './team_table.css';
const headers = ['Name/Email Address', 'Role', 'Actions'];

export const TeamTableDesktop = ({ data, setShowUpdatePopup, setShowRemoveMember }) => {
  const [currentRecord, setCurrentRecord] = useState({});

  const dispatch = useDispatch();
  const colorType = (roleName) => {
    if (roleName === 'merchant-finance') {
      return '#AE207C';
    } else if (roleName === 'merchant-support') {
      return '#F3000B';
    } else if (roleName === 'auditor-finance') {
      return '#FFA800';
    } else if (roleName === 'api-finance') {
      return '#FFA800';
    } else if (roleName === 'accountant-finance') {
      return '#F3000B';
    } else if (roleName === 'merchant-authorizer') {
      return '#AE207C';
    } else if (roleName === 'cashieraccountant') {
      return '#F3000B';
    }
  };

  const bgType = (roleName) => {
    if (roleName === 'merchant-finance') {
      return 'rgba(174, 32, 124, 0.10)';
    } else if (roleName === 'merchant-support') {
      return '#FEE6E7';
    } else if (roleName === 'auditor-finance') {
      return '#FFF6E6';
    } else if (roleName === 'api-finance') {
      return '#FFF6E6';
    } else if (roleName === 'accountant-finance') {
      return '#FEE6E7';
    } else if (roleName === 'merchant-authorizer') {
      return 'rgba(174, 32, 124, 0.10)';
    } else if (roleName === 'cashieraccountant') {
      return '#FEE6E7';
    }
  };

  const handleSetRecord = (email, role, id) => {
    setCurrentRecord({ email, role });
    setShowUpdatePopup(true);
    // set role and email values globally
    dispatch(setMemberEmail(email));
    dispatch(setMemberRole(role));
    dispatch(setMemberId(id));
  };

  const handleRemove = (othername, email, role, id) => {
    setShowRemoveMember(true);
    dispatch(setMemberName(othername));
    dispatch(setMemberEmail(email));
    dispatch(setMemberRole(role));
    dispatch(setMemberId(id));
  };

  return (
    <div className="no-mobile full-width mt-2rem table-wrapper">
      <div className="d-flex full-width">
        {headers.map((header) => {
          return (
            <p className="w-33 font-size12em fw500" key={header}>
              {header}
            </p>
          );
        })}
      </div>
      <Spacer height={20} />
      <div className="full-width">
        {data?.map((role, _id) => {
          return (
            <div key={_id} className="d-flex full-width align-center py-15 team-row fw400 font-size-13">
              <div className="w-33 text-capitalize cut-long-text">{role.otheruser_name ?? role.email}</div>
              <div className="w-33">
                <p
                  style={{
                    color: colorType(role?.role),
                    textTransform: 'capitalize',
                    borderRadius: '20px',
                    width: '45%',
                    textAlign: 'center',
                    backgroundColor: bgType(role?.role),
                  }}
                >
                  {role?.role}
                </p>
              </div>
              <p className="w-33 d-flex align-center">
                {/* This change role was removed on Frank's request - 20241707 */}
                {/* <span className="c-pointer" onClick={() => handleSetRecord(role?.email, role?.role, role?._id)}>
                  Change Role
                </span> */}
                <Spacer width={5} />
                <span className="ball-bullet-black"></span>
                <Spacer width={5} />
                <span className="c-pointer" onClick={() => handleRemove(role?.otheruser_name, role?.email, role?.role, role?._id)}>
                  Remove
                </span>
              </p>
            </div>
          );
        })}
      </div>
      <Spacer height={40} />
    </div>
  );
};

export const MobileTeamTableDesktop = ({ data }) => {
  return (
    <div className="full-width mt-2rem table-wrapper no-desktop-but-mobile">
      {data &&
        data?.map((member) => {
          return <TeamMember key={member?._id} member={member} />;
        })}
    </div>
  );
};

export const TeamMember = ({ member }) => {
  const dispatch = useDispatch();
  const colorType = (roleName) => {
    if (roleName === 'merchant-finance') {
      return '#AE207C';
    } else if (roleName === 'merchant-support') {
      return '#F3000B';
    } else if (roleName === 'auditor-finance') {
      return '#FFA800';
    } else if (roleName === 'api-finance') {
      return '#FFA800';
    } else if (roleName === 'accountant-finance') {
      return '#F3000B';
    } else if (roleName === 'merchant-authorizer') {
      return '#AE207C';
    } else if (roleName === 'cashieraccountant') {
      return '#F3000B';
    }
  };

  const bgType = (roleName) => {
    if (roleName === 'merchant-finance') {
      return 'rgba(174, 32, 124, 0.10)';
    } else if (roleName === 'merchant-support') {
      return '#FEE6E7';
    } else if (roleName === 'auditor-finance') {
      return '#FFF6E6';
    } else if (roleName === 'api-finance') {
      return '#FFF6E6';
    } else if (roleName === 'accountant-finance') {
      return '#FEE6E7';
    } else if (roleName === 'merchant-authorizer') {
      return 'rgba(174, 32, 124, 0.10)';
    } else if (roleName === 'cashieraccountant') {
      return '#FEE6E7';
    }
  };

  const setCurrentMember = (othername, email, role, id) => {
    dispatch(setMemberName(othername));
    dispatch(setMemberEmail(email));
    dispatch(setMemberRole(role));
    dispatch(setMemberId(id));
    dispatch(setShowMemberDetails(true));
  };

  return (
    <div className="d-flex full-width j-space-between divider-margin">
      <div className="d-flex ">
        <>
          <img src={userIcon} alt="user icon" className="member-icon" />
        </>
        <Spacer width={10} />
        <div>
          <p className="font-size-14 fw500">{member?.otheruser_name}</p>
          <p className="font-size-12 text-neutral">{member?.email}</p>
        </div>
      </div>
      <div className="font-size-10 d-flex align-center w-33">
        <p
          style={{
            color: colorType(member?.role),
            textTransform: 'capitalize',
            borderRadius: '20px',
            textAlign: 'center',
            width: '100%',
            backgroundColor: bgType(member?.role),
            fontWeight: 'bold',
          }}
        >
          {member?.role}
        </p>
        <Spacer width={10} />
        <div>
          <div className="c-pointer width-2" onClick={() => setCurrentMember(member?.otheruser_name, member?.email, member?.role, member?._id)}>
            <FontAwesomeIcon icon={faAngleRight} size="1x" />
          </div>
        </div>
      </div>
    </div>
  );
};

export const MobileMemberDetails = ({ setShowRemoveMember }) => {
  const [loading, setLoading] = useState(false);
  const { name, email, role, id } = useSelector((state) => state?.roles);
  const dispatch = useDispatch();
  const key = useSelector((state) => state.user.currentMerchant['publickey']);
  const queryClient = useQueryClient();

  const colorType = (roleName) => {
    if (roleName === 'merchant-finance') {
      return '#AE207C';
    } else if (roleName === 'merchant-support') {
      return '#F3000B';
    } else if (roleName === 'auditor-finance') {
      return '#FFA800';
    } else if (roleName === 'api-finance') {
      return '#FFA800';
    } else if (roleName === 'accountant-finance') {
      return '#F3000B';
    } else if (roleName === 'merchant-authorizer') {
      return '#AE207C';
    } else if (roleName === 'cashieraccountant') {
      return '#F3000B';
    }
  };

  const bgType = (roleName) => {
    if (roleName === 'merchant-finance') {
      return 'rgba(174, 32, 124, 0.10)';
    } else if (roleName === 'merchant-support') {
      return '#FEE6E7';
    } else if (roleName === 'auditor-finance') {
      return '#FFF6E6';
    } else if (roleName === 'api-finance') {
      return '#FFF6E6';
    } else if (roleName === 'accountant-finance') {
      return '#FEE6E7';
    } else if (roleName === 'merchant-authorizer') {
      return 'rgba(174, 32, 124, 0.10)';
    } else if (roleName === 'cashieraccountant') {
      return '#FEE6E7';
    }
  };

  const onClose = () => {
    if (loading) {
      return;
    } else {
      dispatch(setShowMemberDetails(false));
    }
  };

  const handleRemoveClick = () => {
    dispatch(setShowMemberDetails(false));
    setShowRemoveMember(true);
  };

  return (
    <Popup flag={false} onClose={() => onClose()}>
      <Spacer height={50} />
      <div>
        <div className="d-flex flex-column align-center">
          <img src={userIcon} className="member-details-icon" />
          <Spacer height={10} />
          <p className="fw500">{name}</p>
        </div>
        <Spacer height={100} />
        <div>
          <div className="d-flex j-space-between">
            <span className="text-neutral">Email Address</span>
            <span className="fw500">{email}</span>
          </div>
          <Spacer height={20} />
          <div className="d-flex j-space-between">
            <span className="text-neutral">Role</span>
            <span
              className=""
              style={{
                color: colorType(role),
                textTransform: 'capitalize',
                borderRadius: '20px',
                textAlign: 'center',
                width: '40%',
                backgroundColor: bgType(role),
                fontSize: '13px',
              }}
            >
              {role}
            </span>
          </div>
        </div>
        <Spacer height={100} />
        <AppButton
          className="d-flex align-center j-center full-width app-button action-color-2 button-nav-padding text-white"
          name="Remove Team Member"
          isActive={true}
          isBusy={loading}
          onClick={() => handleRemoveClick()}
        />
      </div>
    </Popup>
  );
};
export const EmptyTeam = ({ button }) => {
  return (
    <div className="vh-60 d-flex j-center align-center mobile-v100">
      <div className="d-flex j-center align-center flex-column">
        <div className="d-flex j-center align-center folder-wrapper">
          <img className="folder" src={folderIcon} alt="folder" />
        </div>
        <Spacer height={20} />
        <p className="font-size12em fw400">You have no team members</p>
        <Spacer height={20} />
        <div className="">{button}</div>
      </div>
    </div>
  );
};

export const TeamRoles = ({ back, showAddMember }) => {
  const roles = [
    {
      header: 'Merchant Finance:',
      description:
        'This role will enable your finance officer to have access to your VPay account and view all pages, including transaction history and cashpoint transaction history. The assigned officer will have a view-only role and will not be able to perform any actions, but they will be able to see all the necessary financial information.',
    },
    {
      header: 'Merchant Support ',
      description:
        'This role will allow your support personnel to have access to your VPay account and view all pages, including transaction history and cashpoint transaction history. The assigned support personnel will have a view-only role, and they will not be able to perform any actions or view your public key and web hook URL, which can be seen on the VPay web settings page. They can only view the necessary information related to support queries.',
    },
    {
      header: 'Merchant Accountant',
      description:
        'With this role, your finance officer will have access to your VPay account and can view all pages. They will be able to transfer funds to a designated account number, save beneficiaries, and send out account statements. However, other functions on the app will not be available to them, as they will only have limited access.',
    },
    {
      header: 'Merchant Auditor',
      description:
        'The Auditor Finance role enables your finance officer to have access to your VPay account and view all pages. In addition to viewing, they will also be able to create a cashpoint account, deactivate/delete a cashpoint account, and add a new business account.',
    },
    {
      header: 'API Finance',
      description:
        'With this role your finance officer will have access to your VPay account and can view all pages. They will be able to transfer funds to a designated account number, save beneficiaries, send out account statements and withdraw funds to your payout account number. However, other functions of the app will not be available for them, as they will have limited access.',
    },
    {
      header: 'Cashier Accountant',
      description:
        "This role empowers you as a merchant to designate a specific cashier with access to perform transfer transactions and billing transactions. The cashier, under this role, gains visibility into the merchant's account balance, enabling them to gauge the limits of transfer transactions. However, the cashier's capabilities are confined to these designated financial functions, and they do not have access to any other features beyond their existing capabilities.",
    },
    {
      header: 'Merchant Authoriser',
      description:
        'This role provides the user with access to your VPay account, enabling them to view all pages. Additionally, they have the authority to create a cashpoint account, deactivate/delete a cashpoint account, and add a new business account. The user can also execute financial functions such as transferring funds to a specified account, saving beneficiaries, sending account statements, and withdrawing funds to a designated payout account. However, their access is restricted to these specified financial functions, and other features of the app are not available to them.',
    },
  ];

  return (
    <div className="">
      <Spacer height={15} />
      <div className="c-pointer width-2 no-mobile-but-desktop" onClick={() => back()}>
        <FontAwesomeIcon icon={faAngleLeft} size="2x" />
      </div>
      <div className="c-pointer full-width no-desktop-but-mobile-flex j-space-between" onClick={() => back()}>
        <FontAwesomeIcon icon={faAngleLeft} size="2x" />
        <div className="d-flex align-right no-desktop-but-mobile">
          <AppButton
            className="d-flex align-center app-button action-color-2 button-nav-padding text-white fade-in"
            name="Invite Member"
            space={15}
            isActive={true}
            onClick={() => showAddMember(true)}
          />
        </div>
      </div>
      <div className="text-center no-desktop-but-mobile">
        <p className="fw500 font-size13em">Roles</p>
      </div>

      <Spacer height={25} />
      <div className="">
        <div className="d-flex j-space-between align-center no-mobile">
          <p className="fw500 font-size13em">Roles</p>
          <AppButtonWithIcon
            className="d-flex align-center app-button action-color-2 button-nav-padding text-white fade-in"
            name="Invite Member"
            space={15}
            isActive={true}
            onClick={() => showAddMember(true)}
          />
        </div>

        <Spacer height={35} />
        <div>
          {roles.map((role, idx) => (
            <div key={idx} className="">
              <p className="fw500 font-size12em d-flex align-center">
                <span className="ball-bullet"></span>
                <Spacer width={9} />
                {role.header}
              </p>
              <Spacer height={7} />
              <p className="width-90">{role.description}</p>
              <Spacer height={40} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export const AddTeamMemberPopup = ({ back, rolesOptions, showRoles }) => {
  const [role, setRole] = useState('');
  const [inputErr, setInputErr] = useState({});
  const fullnameProps = useInput('', 'text', inputErr, setInputErr);
  const [showInput, setShowInput] = useState(false);
  const emailProps = useInput('', 'text', inputErr, setInputErr);
  const nubanProps = useInput('', 'text', inputErr, setInputErr);
  const [accountName, setAccountName] = useState('');

  const [nameLoader, setNameLoader] = useState(false);
  const [stage, setStage] = useState(1);
  const [cashpointError, setCashpointError] = useState(false);
  const key = useSelector((state) => state.user.currentMerchant['publickey']);
  const imgProps = useFormFileWithW3(true, 'team_member', 'TeamMember');
  const [skipVideo, setSkipVideo] = useState(false);
  const [whyPopup, setWhyPopup] = useState(false);

  const header = (
    <div className="d-flex j-space-between width-70">
      <div className="c-pointer width-2" onClick={() => back(false)}>
        <FontAwesomeIcon icon={faAngleLeft} size="2x" />
      </div>
      <div className="pr-15 fw500 font-size13em mobile-width-77 ">
        <p className="text-capitalize">Add new team member </p>
      </div>
    </div>
  );

  const checkIfVideoHasExp = useCallback(async () => {
    const lsExpTime = getInLS('rolesVideoExpTime');
    if (lsExpTime) {
      //compare the current time and the time set for video expiration
      let isAfterExpTime = dayjs().isAfter(lsExpTime);
      //console.log(isAfterExpTime, ' teste');
      if (isAfterExpTime) {
        setSkipVideo(false);
      } else {
        setSkipVideo(true);
      }
      // eslint-disable-next-line no-empty
    }
  }, []);

  useEffect(() => {
    checkIfVideoHasExp();
    return () => null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //console.log(skipVideo, ' valie of skip video');

  const rolesShow = () => {
    back(false);
    showRoles(true);
  };
  const onChange = (e) => {
    setRole(e);
    setShowInput(true);
    setInputErr((error) => ({ ...error, e: '' }));
  };
  const info = {
    name: fullnameProps.value,
    email: emailProps.value,
    role: convertRole(role),
  };
  if (role === 'cashieraccountant') {
    delete info.email;
    info.nuban = nubanProps.value;
    info.file = imgProps;
    info.name = accountName;
  }

  const doChecks = () => {
    if (role !== 'cashieraccountant' && showInput) {
      const info = {
        fullname: fullnameProps.value,
        email: emailProps.value,
        file: imgProps,
      };
      const error = formValidate(info);
      setInputErr(error);
      if (Object.keys(error).length === 0) {
        if (skipVideo) {
          setStage(3);
        } else {
          setStage(2);
        }
      }
    } else {
      if (imgProps.file.src === null || imgProps.src === null) {
        openNotificationWithIcon('error', 'Error', 'Please upload an image of the team member');
        return;
      }
      const error = formValidateCashier(info);
      setInputErr(error);
      if (Object.keys(error).length === 0) {
        if (skipVideo) {
          setStage(3);
        } else {
          setStage(2);
        }
      }
    }
  };

  const getCashpointName = async () => {
    setNameLoader(true);
    try {
      const res = await lookupCashpoint(key, nubanProps.value);
      if (res?.status) {
        setAccountName(res?.data?.data?.name);
        setCashpointError(false);
      }
      setNameLoader(false);
    } catch (error) {
      setCashpointError(true);
      setAccountName('');
      openNotificationWithIcon('error', 'Error', error?.response?.data?.message);
      setNameLoader(false);
    }
  };

  useEffect(() => {
    if (nubanProps.value.length > 10 && role === 'cashieraccountant') {
      openNotificationWithIcon('error', 'Error', 'Cashpoint Account Number Is Greater Than 10');
    }
    if (nubanProps.value.length === 10 && role === 'cashieraccountant') {
      openNotificationWithIcon('info', 'Info', 'Fetching Cashpoint Name');
      // fetch the account name
      getCashpointName();
    }
    if ((nubanProps.value.length < 10 || nubanProps.value.length > 10) && role === 'cashieraccountant') {
      setAccountName('');
      setCashpointError(true);
      setNameLoader(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nubanProps.value.length, role]);

  const options = rolesOptions?.data?.data;

  return (
    <div>
      {stage === 1 && (
        <Popup flag={true} className="">
          {header}
          <Spacer height={20} />
          <div className="width-90 margin-auto d-flex flex-column align-center">
            <p className="font-size12em">Simply provide their email address and select the appropriate role to add a new member </p>
            <Spacer height={20} />
            {role !== '' && (
              <div className="d-flex align-center">
                <RolesImageUpload props={imgProps} name="team_member" />
                <Spacer width={5} />
                <div className="fw500">
                  Upload a clear image showing the face of your team member.
                  <p className="c-pointer text-vpay-purple fw400" onClick={() => setWhyPopup(true)}>
                    Why?
                  </p>
                </div>
              </div>
            )}
            <Spacer height={20} />
            <FormDropdown className="input-container" placeholder="Select role" options={options} onChange={onChange} name="roles-desc" error={inputErr} />
            {role !== 'cashieraccountant' && showInput && (
              <div className="w-sm-100">
                <FormInput placeholder="Enter full name..." {...fullnameProps} name="fullname" />
                <FormInput placeholder="Enter email address..." {...emailProps} name="email" />
              </div>
            )}
            {role === 'cashieraccountant' && (
              <>
                <FormInput placeholder="Enter Cashpoint Account Number" {...nubanProps} name="nuban" />
                <FormInput name="email" disabled={true} error={cashpointError ? { email: 'cashpoint name error' } : ''} value={accountName} readOnly={true} label={nameLoader && <LoadingOutlined />} />
              </>
            )}
            <div className="full-width pl16rem">
              <ReadMoreRoles rolesShow={rolesShow} text="Read more about the roles" />
            </div>
          </div>
          <Spacer height={30} />

          <div className="width-90 margin-auto d-flex j-center">
            {role !== 'cashieraccountant' && showInput && (
              <AppButton
                className="d-flex align-center j-center half-width app-button action-color-2 button-nav-padding text-white w-sm-100"
                name="Add New Member"
                onClick={() => doChecks()}
                isActive={true}
              />
            )}
            {role === 'cashieraccountant' && (
              <AppButton
                className={`d-flex align-center j-center half-width app-button action-color-2 button-nav-padding text-white w-sm-100`}
                name="Add New Member"
                onClick={() => doChecks()}
                isActive={true}
              />
            )}
          </div>
          <Spacer height={20} />
        </Popup>
      )}
      {stage === 2 && !skipVideo && <RolesVideoInfoPopup onClose={() => back(false)} data={info} />}
      {stage === 3 && skipVideo && <SkipVideoPopup data={info} setStage={setStage} onParentClose={() => back(false)} />}
      {whyPopup && <WhyImagePopup closePopup={setWhyPopup} />}
    </div>
  );
};

const SkipVideoPopup = ({ data, setStage, onParentClose }) => {
  const video_url = getInLS('image_url');
  const onClose = () => {
    setStage(0);
    onParentClose();
  };

  return (
    <Popup flag={true}>
      <div>
        <p>Liveliness videos are valid for 10 minutes</p>
        <p>
          Your current liveliness video expires by <span className="fw500">{getInLS('rolesVideoExpTime')}</span>
        </p>
        <Spacer height={30} />
        <RenderVideo videoFile={video_url} service={addTeamMember} data={data} onClose={onClose} />
        <Spacer height={20} />
      </div>
    </Popup>
  );
};

export const UpdateTeamMember = ({ back, rolesOptions, showRoles }) => {
  const [memberRole, setMemberRole] = useState('');
  const key = useSelector((state) => state.user.currentMerchant['publickey']);
  const [loading, setLoading] = useState(false);
  const { email, role, id } = useSelector((state) => state.roles);
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const onChange = (e) => {
    setMemberRole(e);
  };
  const rolesShow = () => {
    back(false);
    showRoles(true);
  };

  const handleSubmit = async () => {
    const info = {
      email: email,
      role: convertRole(memberRole),
    };
    try {
      setLoading(true);
      await updateTeamMemberRole(key, info, id);
      openNotificationWithIcon('success', 'Success', 'Role changed successfully');
      setLoading(false);
      // invalidate the existing team list
      queryClient.invalidateQueries({ queryKey: ['merchantTeamList'] });
      // clear the redux state for roles
      dispatch(resetMemberEmail());
      dispatch(resetMemberRole());
      dispatch(resetMemberId());
      back(false);
    } catch (error) {
      setLoading(false);
      openNotificationWithIcon('error', 'Error', error?.response?.data?.message);
    }
  };

  const header = (
    <div className="d-flex j-space-between w-60">
      <div className="c-pointer width-2" onClick={() => back(false)}>
        {!loading && <FontAwesomeIcon icon={faAngleLeft} size="2x" />}
      </div>
      <div className="pr-15 fw500 font-size13em">
        <p>Change Role</p>
      </div>
    </div>
  );
  const options = rolesOptions?.data?.data;
  return (
    <Popup flag={true} className="">
      {header}
      <Spacer height={20} />
      <div className="width-90 margin-auto">
        <p className="font-size12em"></p>
        <Spacer height={10} />
        <p>
          Change <span className="fw700">{email}'s</span> role
        </p>
        <Spacer height={20} />
        <FormDropdown className="width-90" placeholder="Select role" options={options} onChange={onChange} name="roles-desc" defaultValue={role} />
        <ReadMoreRoles text="Read more about the roles" rolesShow={() => rolesShow()} />
      </div>
      <Spacer height={20} />
      <div className="width-90 margin-auto d-flex j-center ">
        <AppButton
          className="d-flex align-center j-center  half-width app-button action-color-2 button-nav-padding text-white"
          name="Change Role"
          isActive={true}
          isBusy={loading}
          onClick={() => handleSubmit()}
        />
      </div>
      <Spacer height={20} />
    </Popup>
  );
};
export const RemoveMember = ({ back }) => {
  const { email, role, id, name } = useSelector((state) => state.roles);
  const [loading, setLoading] = useState(false);
  const key = useSelector((state) => state.user.currentMerchant['publickey']);
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const removeMember = async () => {
    const info = {
      email: email,
      role: convertRole(role),
    };
    try {
      setLoading(true);
      await removeTeamMemberRole(key, info, id);
      openNotificationWithIcon('success', 'Success', 'Member removed successfully');
      setLoading(false);
      // invalidate the existing team list
      queryClient.invalidateQueries({ queryKey: ['merchantTeamList'] });
      // clear the redux state for roles
      dispatch(resetMemberEmail());
      dispatch(resetMemberRole());
      dispatch(resetMemberId());
      back(false);
    } catch (error) {
      setLoading(false);
      openNotificationWithIcon('error', 'Error', error?.response?.data?.message);
    }
  };
  return (
    <Popup flag={false} className="" onClose={() => back(false)}>
      <div className="d-flex j-center align-center flex-column text-center">
        <div className="check-bg d-flex j-center align-center">
          <img src={dangerIcon} alt="danger-icon" />
        </div>
        <Spacer height={20} />
        <p className="fw700 font-size13em">Remove Team Member</p>
        <Spacer height={20} />
        <p className="fw400 text-neutral">
          This will prevent <span className="brown-text text-capitalize">{name}</span> from accessing this account any longer. Do you wish to continue?
        </p>
        <Spacer height={20} />
        <AppButton
          className="d-flex align-center j-center  half-width app-button action-color-2 button-nav-padding text-white"
          name="Remove"
          isActive={true}
          isBusy={loading}
          onClick={() => removeMember()}
        />
      </div>
    </Popup>
  );
};

export const ReadMoreRoles = ({ rolesShow, text }) => {
  return (
    <div onClick={() => rolesShow()} className="c-pointer d-flex">
      <img src={info_purple} alt="info" />
      <p className="text-capitalize text-vpay-purple">{text}</p>
    </div>
  );
};

// validator
const formValidate = (values) => {
  let errors = {};

  if (!values.fullname) {
    errors.fullname = 'Full name cannot be blank';
  }
  if (!values.email) {
    errors.email = 'Email is required';
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = 'Email address is invalid';
  }
  if (values.file.src === null) {
    errors.file = 'Please upload an image of the team member';
    openNotificationWithIcon('error', 'Error', 'Please upload an image of the team member');
  }
  return errors;
};
const formValidateCashier = (values) => {
  let errors = {};
  if (!values.nuban) {
    errors.nuban = 'No account number provided';
  }
  if (values.file.src === null) {
    errors.file = 'Please upload an image of the team member';
    openNotificationWithIcon('error', 'Error', 'Please upload an image of the team member');
  }
  return errors;
};
// utils
const convertRole = (roleName) => {
  if (roleName === 'merchant-finance') {
    return 'finance';
  } else if (roleName === 'merchant-support') {
    return 'support';
  } else if (roleName === 'auditor-finance') {
    return 'auditor';
  } else if (roleName === 'api-finance') {
    return 'api';
  } else if (roleName === 'accountant-finance') {
    return 'accountant';
  } else if (roleName === 'merchant-authorizer') {
    return 'authorizer';
  } else if (roleName === 'cashieraccountant') {
    return 'cashieraccountant';
  }
};
