import { useEffect } from 'react';
import { useLocation } from 'react-router';
export default function useTitle(title, mapping) {
  const { pathname } = useLocation();
  useEffect(() => {
    document.title = title + ' :: ' + (mapping[pathname]?.title || 'Checkout By Bank Transfer');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);
}

export function useTitleName(mapping) {
  const { pathname } = useLocation();
  const titleName = mapping[pathname]?.title;
  return titleName || '';
}
