import { Spacer } from '../../components/layout/layout';

export const CashierQA = [
  {
    title: 'I can’t find VPay on my bank app.',
    content: (
      <div className="fw400 body-details font-size-15">
        If you are using a mobile banking app, kindly search for VFD Microfinance Bank, Vbank or VFD MFB. VFD Microfinance Bank is the bank VPay is integrated with.
        <Spacer height={20} />
        Alternatively, your customers can pay you via your unique USSD code which is listed below :
        <Spacer height={20} />
        The customer should type STAR, then code for his/her bank, then STAR, then Zero into 3 places that is zero zero zero, then STAR again, then 5037, then PLUS (if they don’t know how to type plus
        on their phone, they can long press the zero button on their phone, most phones will change that zero to a PLUS) then your VPay account number, then PLUS again, then amount then Hash and enter
        or dail.
        <Spacer height={10} />
        This will instantly transfer the amount to your VPay account.
        <Spacer height={10} />
        For clarity, it is;
        <Spacer height={5} />
        *Bank USSD Code*000* 5037+VPay Account Number+Amount#
        <Spacer height={20} />
        Please note that the bank USSD code is different for all banks, for First Bank, it is 894, for GTB, it is 737, and so on for other banks.
      </div>
    ),
  },
  {
    title: 'I cannot find VFD Microfiance Bank on my bank USSD menu.',
    content: (
      <div className="fw400 body-details font-size-15">
        Your Unique *USSD CODE* is now available. See steps for using it below.
        <Spacer height={20} />
        *BankUSSDCode*000*5037+VPayAccountNumber+Amount#
        <Spacer height={20} />
        This means for example, if you want to send 100 Naira to this VPay account "4600040982" using a GTBank USSD, you will dial something like this:
        <Spacer height={5} />
        *737*000*5037+4600040982+100#
        <Spacer height={10} />
        Once you dial this, the payment will instantly go through after you authenticate it.
        <Spacer height={20} />
        If your are paying from UBA, you change the to UBA bank USSD code (*919*000*.... etc); First Bank (*894*000..., etc). Just follow the pattern and it will work.
      </div>
    ),
  },
];
