import { CashierTransactionTable } from '../../../components/cashier_widgets/cashier_widget';
import { MerchantContentWrapper } from '../../../components/merchant_widgets/merchant_widgets';

const CashpointTransactions = () => {
  return (
    <MerchantContentWrapper className="d-flex flex-vertical full-height" hasTopSpacing>
      <CashierTransactionTable />
    </MerchantContentWrapper>
  );
};

export default CashpointTransactions;
