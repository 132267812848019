import React, { createContext, useState } from 'react';

const LoanContext = createContext({
  goBack: null,
  goForward: null,
});

export const useLoanState = () => {
  const context = React.useContext(LoanContext);
  if (context === undefined) {
    throw new Error('app dispatch must be used within the app global provider');
  }
  return context;
};

export const LoanProvider = ({ children }) => {
  const [position, setPosition] = useState(1);
  const [aggregatorCode, setAggregatorCode] = useState('');

  const goBack = () => {
    setPosition((prev) => prev - 1);
  };

  const goForward = () => {
    setPosition((prev) => prev + 1);
  };

  return (
    <LoanContext.Provider
      value={{
        position,
        setPosition,
        aggregatorCode,
        setAggregatorCode,
        goBack,
        goForward,
      }}
    >
      {children}
    </LoanContext.Provider>
  );
};
