import { openNotificationWithIcon } from '../../pages/auth/primary_signup/primary_signup';
import {
  addBankDetails,
  addCashierBankDetails,
  addCashpoint,
  applyForLoan,
  approveLoan,
  checkEligibilityForLoan,
  completeSignup,
  deactivateCashpoint,
  fetchAlternateCashpointAccountFees,
  fetchBanks,
  fetchBeneficiaries,
  fetchCashierBanks,
  fetchCodes,
  fetchIndustry,
  fetchLoanHistory,
  fetchMerchant,
  fetchMerchantBalance,
  fetchMerchantBalancelater,
  fetchMerchantCashpoints,
  fetchMerchantCustomers,
  fetchMerchantCustomersCashpoints,
  fetchMerchantPayouts,
  fetchMerchantTransactions,
  fetchTranferFees,
  fetchWalletCreditLimit,
  fetchWalletPrefix,
  fetchWarranterLoanRequests,
  getCurrentUser,
  getGuarantorTypes,
  getLoanInterestRates,
  getNextLoanRepayment,
  uploadCashierProfilePicture,
  verifyAgggregatorCode,
  verifyBankDetails,
  verifyCashierBankDetails,
} from '../../services/apiSevices';
import { fetchUser } from '../user/user_actions';
import { loadCashierInfo } from './cashier.information';
import { MerchantActionTypes } from './merchant_types';

const balanceRequestKey = Math.floor(Math.random() * 90000) + 10000 + '-' + new Date().getTime();

// Synchronous

// Merchant

export const fetchMerchantStart = () => ({
  type: MerchantActionTypes.FETCH_MERCHANT_START,
});

export const fetchMerchantFailure = (error) => ({
  type: MerchantActionTypes.FETCH_MERCHANT_FAILURE,
  payload: error,
});

export const fetchMerchantSuccess = (merchant) => ({
  type: MerchantActionTypes.FETCH_MERCHANT_SUCCESS,
  payload: merchant,
});

// action creator for merchant banks
export const fetchBanksStart = () => ({
  type: MerchantActionTypes.FETCH_BANKS_START,
});

export const fetchBanksSuccess = (banks) => ({
  type: MerchantActionTypes.FETCH_BANKS_SUCCESS,
  payload: banks,
});

// action creator for cashier banks
export const fetchCashierBanksStart = () => ({
  type: MerchantActionTypes.FETCH_CASHIER_BANKS_START,
});

export const fetchCashierBanksSuccess = (banks) => ({
  type: MerchantActionTypes.FETCH_CASHIER_BANKS_SUCCESS,
  payload: banks,
});

export const verfiyBankStart = () => ({
  type: MerchantActionTypes.VERIFY_BANK_START,
});

export const verifyBankSuccess = (data) => ({
  type: MerchantActionTypes.VERIFY_BANK_SUCCESS,
  payload: data,
});

export const verifyBankFailure = (error) => ({
  type: MerchantActionTypes.VERIFY_BANK_FAILURE,
  payload: error,
});

export const verfiyCashierBankStart = () => ({
  type: MerchantActionTypes.VERIFY_CASHIER_BANK_START,
});

export const verifyCashierBankSuccess = (data) => ({
  type: MerchantActionTypes.VERIFY_CASHIER_BANK_SUCCESS,
  payload: data,
});

export const verifyCashierBankFailure = (error) => ({
  type: MerchantActionTypes.VERIFY_CASHIER_BANK_FAILURE,
  payload: error,
});

export const updateBankStart = () => ({
  type: MerchantActionTypes.UPDATE_BANK_START,
});

export const updateBankSuccess = () => ({
  type: MerchantActionTypes.UPDATE_BANK_SUCCESS,
});

export const updateBankFailure = () => ({
  type: MerchantActionTypes.UPDATE_BANK_FAILURE,
});

// add bank details action creators for cashier
export const addCashierBankDetailsStart = () => ({
  type: MerchantActionTypes.ADD_CASHIER_BANK_DETAILS_START,
});

export const addCashierBankDetailsSuccess = () => ({
  type: MerchantActionTypes.ADD_CASHIER_BANK_DETAILS_SUCCESS,
});

export const addCashierBankDetailsFailure = () => ({
  type: MerchantActionTypes.ADD_CASHIER_BANK_DETAILS_FAILURE,
});

// upload profile picture action creators for cashier
export const uploadProfilePictureStart = () => ({
  type: MerchantActionTypes.UPLOAD_CASHIER_PROFILE_PICTURE_START,
});

export const uploadProfilePictureSuccess = () => ({
  type: MerchantActionTypes.UPLOAD_CASHIER_PROFILE_PICTURE_SUCCESS,
});

export const uploadProfilePictureFailure = () => ({
  type: MerchantActionTypes.UPLOAD_CASHIER_PROFILE_PICTURE_FAILURE,
});

export const fetchFeesStart = () => ({
  type: MerchantActionTypes.FETCH_FEE_START,
});

export const fetchFeesSuccess = (fees) => ({
  type: MerchantActionTypes.FETCH_FEE_SUCCESS,
  payload: fees,
});

export const fetchFeesFailure = (error) => ({
  type: MerchantActionTypes.FETCH_FEE_FAILURE,
  payload: error,
});

export const addMerchantStart = () => ({
  type: MerchantActionTypes.ADD_MERCHANT_START,
});

export const addMerchantSuccess = () => ({
  type: MerchantActionTypes.ADD_MERCHANT_SUCCESS,
});

export const addMerchantFailure = (error) => ({
  type: MerchantActionTypes.ADD_MERCHANT_FAILURE,
  payload: error,
});

export const temporaryStorePin = (dispatch, pin) => {
  dispatch({
    type: MerchantActionTypes.TEMP_STORE_PIN,
    payload: pin,
  });
};

export const clearStoredPin = (dispatch) => {
  dispatch({
    type: MerchantActionTypes.CLEAR_STORED_PIN,
    payload: '',
  });
};

//  Cashier

export const openCreateCashpoints = (dispatch) => {
  dispatch({
    type: 'OPEN_CREATE_CASHPOINTS',
  });
};
export const closeCreateCashpoints = (dispatch) => {
  dispatch({
    type: 'CLOSE_CREATE_CASHPOINTS',
  });
};

export const openCreateMerchant = (dispatch) => {
  dispatch({
    type: 'OPEN_CREATE_MERCHANT',
  });
};
export const closeCreateMerchant = (dispatch) => {
  dispatch({
    type: 'CLOSE_CREATE_MERCHANT',
  });
};

export const openCashpointPopup = (dispatch) => {
  dispatch({
    type: 'OPEN_CASHPOINT_POPUP',
  });
};
export const closeCashpointPopup = (dispatch) => {
  dispatch({
    type: 'CLOSE_CASHPOINT_POPUP',
  });
};

export const openTransferPopup = (dispatch) => {
  dispatch({
    type: 'OPEN_WITHDRAW_POPUP',
  });
};
export const closeTransferPopup = (dispatch) => {
  dispatch({
    type: 'CLOSE_WITHDRAW_POPUP',
  });
};

export const openMessageRed = (dispatch) => {
  dispatch({
    type: 'OPEN_RED_MESSAGE',
  });
};

export const closeRedMessage = (dispatch) => {
  dispatch({
    type: 'CLOSE_RED_MESSAGE',
  });
};

export const openSuggestionBox = (dispatch) => {
  dispatch({
    type: 'OPEN_SUGGESTION_BOX',
  });
};
export const closeSuggestionBox = (dispatch) => {
  dispatch({
    type: 'CLOSE_SUGGESTION_BOX',
  });
};

export const setPinStart = (dispatch) => {
  dispatch({
    type: 'SET_PIN_START',
  });
};

export const setPinSuccess = (dispatch) => {
  dispatch({
    type: 'SET_PIN_SUCCESS',
  });
};

export const setPinFailure = (dispatch) => {
  dispatch({
    type: 'SET_PIN_FAILURE',
  });
};

export const openQR = (dispatch) => {
  dispatch({
    type: 'OPEN_QR',
  });
};

export const closeQR = (dispatch) => {
  dispatch({
    type: 'CLOSE_QR',
  });
};

export const updateCurrentCashpoint = (id, dispatch) => {
  dispatch({
    type: MerchantActionTypes.UPDATE_CURRENT_CASHIER,
    payload: id,
  });
};

export const deleteCashierStart = () => ({
  type: MerchantActionTypes.DELETE_CASHPOINT_START,
});

export const deleteCashierFailure = (error) => ({
  type: MerchantActionTypes.DELETE_CASHPOINT_FAILURE,
  payload: error,
});

export const deleteCashierSuccess = () => ({
  type: MerchantActionTypes.DELETE_CASHIER_SUCCESS,
});

export const addCashierStart = () => ({
  type: MerchantActionTypes.ADD_CASHPOINT_START,
});

export const addCashierSuccess = () => ({
  type: MerchantActionTypes.ADD_CASHPOINT_SUCCESS,
});

export const addCashierFailure = (error) => ({
  type: MerchantActionTypes.ADD_CASHPOINT_FAILURE,
  payload: error,
});

// Transactions
export const fetchTransactionsStart = () => ({
  type: MerchantActionTypes.FETCH_TRANSACTIONS_START,
});

export const fetchTransactionsFailure = (error) => ({
  type: MerchantActionTypes.FETCH_TRANSACTIONS_FAILURE,
  payload: error,
});

export const fetchTransactionsSuccess = (transactions) => ({
  type: MerchantActionTypes.FETCH_TRANSACTIONS_SUCCESS,
  payload: transactions,
});

//  Customers

export const fetchCustomersStart = () => ({
  type: MerchantActionTypes.FETCH_CUSTOMERS_START,
});

export const fetchCustomersSuccess = (customers) => ({
  type: MerchantActionTypes.FETCH_CUSTOMERS_SUCCESS,
  payload: customers,
});

export const fetchCustomersFailure = (error) => ({
  type: MerchantActionTypes.FETCH_CUSTOMERS_FAILURE,
  payload: error,
});

// Balance

export const fetchBalanceStart = () => ({
  type: MerchantActionTypes.FETCH_BALANCE_START,
});

export const fetchBalanceSuccess = (balance) => ({
  type: MerchantActionTypes.FETCH_BALANCE_SUCCESS,
  payload: balance,
});

export const fetchBalanceFailure = (error) => ({
  type: MerchantActionTypes.FETCH_BALANCE_FAILURE,
  payload: error,
});

export const fetchSuccessCodes = (code) => ({
  type: MerchantActionTypes.FETCH_SUCCESS_CODES,
  payload: code,
});

export const fetchFailureCodes = (code) => ({
  type: MerchantActionTypes.FETCH_FAILURE_CODES,
  payload: code,
});

export const fetchCashpointsStart = () => ({
  type: MerchantActionTypes.FETCH_CASHPOINTS_START,
});

export const fetchCashpointsSuccess = (cashpoints) => ({
  type: MerchantActionTypes.FETCH_CASHPOINTS_SUCCESS,
  payload: cashpoints,
});

export const fetchCashpointsFailure = (error) => ({
  type: MerchantActionTypes.FETCH_CASHPOINTS_FAILURE,
  payload: error,
});

export const fetchPayoutsStart = () => ({
  type: MerchantActionTypes.FETCH_PAYOUTS_START,
});
export const fetchPayoutsSuccess = (payouts) => ({
  type: MerchantActionTypes.FETCH_PAYOUTS_SUCCESS,
  payload: payouts,
});

export const fetchPayoutsFailure = (error) => ({
  type: MerchantActionTypes.FETCH_PAYOUTS_FAILURE,
  payload: error,
});

export const fetchCashpointCustomerStart = () => ({
  type: MerchantActionTypes.FETCH_CASHPOINT_CUSTOMER_START,
});

export const fetchCashpointCustomerFailure = (error) => ({
  type: MerchantActionTypes.FETCH_CASHPOINT_CUSTOMER_FAILURE,
  payload: error,
});

export const fetchCashpointCustomerSuccess = (customer) => ({
  type: MerchantActionTypes.FETCH_CASHPOINT_CUSTOMER_SUCCESS,
  payload: customer,
});

export const checkLoanEligibilityStart = () => ({
  type: MerchantActionTypes.CHECK_LOAN_ELIGIBILITY_START,
});

export const checkLoanEligibilitySuccess = (resp) => ({
  type: MerchantActionTypes.CHECK_LOAN_ELIGIBILITY_SUCCESS,
  payload: resp,
});

export const checkLoanEligibilityFailure = (error) => ({
  type: MerchantActionTypes.CHECK_LOAN_ELIGIBILITY_FAILURE,
  payload: error,
});

export const submitLoanRequestStart = () => ({
  type: MerchantActionTypes.SUBMIT_LOAN_REQUEST_START,
});

export const submitLoanRequestSuccess = (resp) => ({
  type: MerchantActionTypes.SUBMIT_LOAN_REQUEST_SUCCESS,
  payload: resp,
});

export const submitLoanRequestFailure = (error) => ({
  type: MerchantActionTypes.SUBMIT_LOAN_REQUEST_FAILURE,
  payload: error,
});

export const fetchLoanInterestRatesStart = () => ({
  type: MerchantActionTypes.GET_LOAN_INTEREST_RATES_START,
});

export const fetchLoanInterestRatesSuccess = (resp) => ({
  type: MerchantActionTypes.GET_LOAN_INTEREST_RATES_SUCCESS,
  payload: resp,
});

export const fetchLoanInterestRatesFailure = (error) => ({
  type: MerchantActionTypes.GET_LOAN_INTEREST_RATES_FAILURE,
  payload: error,
});

export const verifyAggregatorCodeStart = () => ({
  type: MerchantActionTypes.VERIFY_AGGREGATOR_CODE_START,
});

export const verifyAggregatorCodeSuccess = (data) => ({
  type: MerchantActionTypes.VERIFY_AGGREGATOR_CODE_SUCCESS,
  payload: data,
});

export const verifyAggregatorCodeFailure = (error) => ({
  type: MerchantActionTypes.VERIFY_AGGREGATOR_CODE_FAILURE,
  payload: error,
});

export const fetchLoanHistoryStart = () => ({
  type: MerchantActionTypes.FETCH_LOAN_HISTORY_START,
});

export const fetchLoanHistorySuccess = (data) => ({
  type: MerchantActionTypes.FETCH_LOAN_HISTORY_SUCCESS,
  payload: data,
});

export const fetchLoanHistoryFailure = (error) => ({
  type: MerchantActionTypes.FETCH_LOAN_HISTORY_FAILURE,
  payload: error,
});

export const fetchWarranterLoanRequestsStart = () => ({
  type: MerchantActionTypes.FETCH_WARRANTER_LOAN_REQUESTS_START,
});

export const fetchWarranterLoanRequestsSuccess = (data) => ({
  type: MerchantActionTypes.FETCH_WARRANTER_LOAN_REQUESTS_SUCCESS,
  payload: data,
});

export const fetchWarranterLoanRequestsFailure = (error) => ({
  type: MerchantActionTypes.FETCH_WARRANTER_LOAN_REQUESTS_FAILURE,
  payload: error,
});

export const fetchLoanCreditLimitStart = () => ({
  type: MerchantActionTypes.FETCH_LOAN_CREDIT_LIMIT_START,
});

export const fetchLoanCreditLimitSuccess = (data) => ({
  type: MerchantActionTypes.FETCH_LOAN_CREDIT_LIMIT_SUCCESS,
  payload: data,
});

export const fetchLoanCreditLimitFailure = (error) => ({
  type: MerchantActionTypes.FETCH_LOAN_CREDIT_LIMIT_FAILURE,
  payload: error,
});

export const warranterApproveLoanStart = () => ({
  type: MerchantActionTypes.WARRANTER_LOAN_APPROVAL_START,
});

export const warranterApproveLoanSuccess = (data) => ({
  type: MerchantActionTypes.WARRANTER_LOAN_APPROVAL_SUCCESS,
  payload: data,
});

export const warranterApproveLoanFailure = (error) => ({
  type: MerchantActionTypes.WARRANTER_LOAN_APPROVAL_FAILURE,
  payload: error,
});

export const getNextLoanRepaymentStart = () => ({
  type: MerchantActionTypes.FETCH_NEXT_LOAN_REPAYMENT_START,
});

export const getNextLoanRepaymentSuccess = (data) => ({
  type: MerchantActionTypes.FETCH_NEXT_LOAN_REPAYMENT_SUCCESS,
  payload: data,
});

export const getNextLoanRepaymentFailure = (error) => ({
  type: MerchantActionTypes.FETCH_NEXT_LOAN_REPAYMENT_FAILURE,
  payload: error,
});

export const getGuarantorTypesStart = () => ({
  type: MerchantActionTypes.FETCH_GUARANTOR_TYPES_START,
});

export const getGuarantorTypesSuccess = (data) => ({
  type: MerchantActionTypes.FETCH_GUARANTOR_TYPES_SUCCESS,
  payload: data,
});

export const getGuarantorTypesFailure = (error) => ({
  type: MerchantActionTypes.FETCH_GUARANTOR_TYPES_FAILURE,
  payload: error,
});

export const getAlternateCashpointAccountStart = () => ({
  type: MerchantActionTypes.FETCH_ALTERNATE_ACCOUNT_FEES_START,
});
export const getAlternateCashpointAccountSuccess = (data) => ({
  type: MerchantActionTypes.FETCH_ALTERNATE_ACCOUNT_FEES_SUCCESS,
  payload: data,
});
export const getAlternateCashpointAccountError = (error) => ({
  type: MerchantActionTypes.FETCH_ALTERNATE_ACCOUNT_FEES_FAILURE,
  payload: error,
});

// Asynchronous

export const fetchIndustryList = () => {
  return async (dispatch) => {
    try {
      const response = await fetchIndustry();
      dispatch({
        type: 'FETCH_INDUSTRY_LIST',
        payload: response.data?.industries,
      });
    } catch (error) {
      console.log(error);
    }
  };
};

export const fetchTransactions = (id) => {
  return async (dispatch) => {
    try {
      dispatch(fetchTransactionsStart());
      const response = await fetchMerchantTransactions(id);
      dispatch(fetchTransactionsSuccess(response.data));
    } catch (error) {
      return dispatch(fetchTransactionsFailure(error.response?.data?.message));
    }
  };
};

export const fetchCustomers = (id) => {
  return async (dispatch) => {
    try {
      dispatch(fetchCustomersStart());
      const response = await fetchMerchantCustomers(id);
      dispatch(fetchCustomersSuccess(response.data));
    } catch (error) {
      return dispatch(fetchCustomersFailure(error.response?.data?.message));
    }
  };
};

export const fetchBalance = (id) => {
  let count = 0;
  let backupResponse;

  return async (dispatch) => {
    try {
      dispatch(fetchBalanceStart());
      const response = await fetchMerchantBalance(id, balanceRequestKey);
      dispatch(fetchBalanceSuccess(response.data.balance));
    } catch (error) {
      console.log(error, ' this is the error form');
      if (error?.response?.data?.message === 'Taking too long to respond. Please try again after some minutes.') {
        try {
          let intervalID = setInterval(async () => {
            console.log('set interval is running ');
            backupResponse = await fetchMerchantBalancelater(id, balanceRequestKey);

            if (backupResponse?.data?.balance) {
              dispatch(fetchBalanceSuccess(backupResponse?.data?.balance));
              clearInterval(intervalID);
            }
            count = count + 1;
            if (count === 10) {
              clearInterval(intervalID);
            }
          }, 1000);
        } catch (error2) {
          console.log(error2, ' an error occured in error2');
        }
      }
      return dispatch(fetchBalanceFailure(error.response?.data?.message));
    }
  };
};

export const fetchcodeSuccess = (key) => {
  return async (dispatch) => {
    try {
      const response = await fetchCodes(key, 'success');
      dispatch(fetchSuccessCodes(response.data.data));
    } catch (error) {
      console.log(error.response?.data?.message);
    }
  };
};
export const fetchcodeFailure = (key) => {
  return async (dispatch) => {
    try {
      const response = await fetchCodes(key, 'failure');
      dispatch(fetchFailureCodes(response.data.data));
    } catch (error) {
      console.log(error.response?.data?.message);
    }
  };
};

export const fetchCashpoints = (id) => {
  return async (dispatch) => {
    try {
      dispatch(fetchCashpointsStart());
      //console.log(id);
      const res = await fetchMerchantCashpoints(id);
      dispatch(fetchCashpointsSuccess(res.data));
    } catch (error) {
      return dispatch(fetchCashpointsFailure(error.response?.data?.message));
    }
  };
};

export const fetchPayouts = (id, pageNumber) => {
  return async (dispatch) => {
    try {
      dispatch(fetchPayoutsStart());
      const response = await fetchMerchantPayouts(id, pageNumber);
      return dispatch(fetchPayoutsSuccess(response.data.data));
    } catch (error) {
      return dispatch(fetchPayoutsFailure(error.response?.data?.message));
    }
  };
};

export const fetchCashpointCustomer = (id, data) => {
  return async (dispatch) => {
    try {
      dispatch(fetchCashpointCustomerStart());
      const customer = await fetchMerchantCustomersCashpoints(id, data);
      return dispatch(fetchCashpointCustomerSuccess(customer.data));
    } catch (error) {
      return dispatch(fetchCashpointCustomerFailure(error.response?.data?.message));
    }
  };
};

export const deleteCashier = (data) => {
  const { merchant } = data;
  return async (dispatch) => {
    try {
      dispatch(deleteCashierStart());
      await deactivateCashpoint(data);
      await dispatch(fetchCashpoints(merchant));
      closeCashpointPopup(dispatch);
      openNotificationWithIcon('success', 'Success', 'Cashpoint Deactivated Successfully');
    } catch (error) {
      return dispatch(deleteCashierFailure(error.response?.data?.message));
    }
  };
};

export const addCashier = (data) => {
  return async (dispatch) => {
    try {
      dispatch(addCashierStart());
      await addCashpoint(data);
      await dispatch(fetchCashpoints(data.merchant));
      openNotificationWithIcon('success', 'Success', `Cashpoint created successfully. Account password has been sent to cashpoint's ${data.email ? 'email and' : ''} phone.`);
      closeCreateCashpoints(dispatch);
    } catch (error) {
      if (error.response?.data?.message !== 'session has expired') {
        openNotificationWithIcon('error', 'Error', error.response?.data.message);
      }
      return dispatch(addCashierFailure(error.response?.data.message ?? 'Error Creating Cashier'));
    }
  };
};

export const fetchUserMerchant = (id) => {
  return async (dispatch) => {
    try {
      dispatch(fetchMerchantStart());
      const res = await fetchMerchant(id);
      dispatch(fetchMerchantSuccess(res.data));
    } catch (error) {
      return dispatch(fetchMerchantFailure(error.response?.data?.message));
    }
  };
};

// get banks for merchant
export const getBanks = () => {
  return async (dispatch) => {
    try {
      dispatch(fetchBanksStart());
      const res = await fetchBanks();
      dispatch(fetchBanksSuccess(res.data.banks));
    } catch (error) {
      console.log(error.response);
    }
  };
};

// get banks for cashpoint
export const getCashierBanks = () => {
  return async (dispatch) => {
    try {
      dispatch(fetchCashierBanksStart());
      const res = await fetchCashierBanks();
      dispatch(fetchCashierBanksSuccess(res.data.banks));
    } catch (error) {
      console.log(error.response);
    }
  };
};

export const verifyMerchantBankDetails = (data) => {
  return async (dispatch) => {
    try {
      dispatch(verfiyBankStart());
      const res = await verifyBankDetails(data);
      if (res.data) {
        dispatch(verifyBankSuccess(res.data.accountname));
        return openNotificationWithIcon('success', 'Success', 'Bank details verified');
      }
    } catch (error) {
      dispatch(verifyBankFailure(error.response?.data?.message));
      return openNotificationWithIcon('error', 'Error', error.response?.data?.message ?? 'error');
    }
  };
};

export const verifyCashierBankDetailsAction = (data, bankDetails, cashpointID) => {
  return async (dispatch) => {
    try {
      dispatch(verfiyCashierBankStart());
      const res = await verifyCashierBankDetails(data);

      if (res.data) {
        dispatch(addCashierBankDetailsAction(bankDetails, cashpointID));
        dispatch(verifyCashierBankSuccess(res.data.accountname));
      }
    } catch (error) {
      dispatch(verifyCashierBankFailure(error.response?.data?.message));
      return openNotificationWithIcon('error', 'Error', error.response?.data?.message ?? 'error');
    }
  };
};

export const updateBankDetails = (data) => {
  return async (dispatch) => {
    try {
      dispatch(updateBankStart());
      await addBankDetails(data);
      dispatch(updateBankSuccess());
      dispatch(fetchUserMerchant(data.merchant));
      return openNotificationWithIcon('success', 'Success', 'Bank details updated successfully');
    } catch (error) {
      dispatch(updateBankFailure(error.response?.data?.message));
      return openNotificationWithIcon('error', 'Error', error.response?.data?.message ?? 'error');
    }
  };
};

export const fetchFees = (key, id) => {
  return async (dispatch) => {
    try {
      dispatch(fetchFeesStart());
      const fees = await fetchTranferFees(key, id);
      dispatch(fetchFeesSuccess(fees.data.data));
    } catch (error) {
      dispatch(fetchFeesFailure(error.response?.data?.message));
    }
  };
};

export const setInitialPin = (_key, _data) => {
  return async (dispatch) => {
    try {
      // setPinStart(dispatch)
      // await initialPinSet(key, data)
      // dispatch(fetchUserMerchant(merchantID))
      openNotificationWithIcon('success', 'Success', 'Pin Set Success');
    } catch (error) {
      dispatch(setPinFailure(error.response?.data?.message));
      return openNotificationWithIcon('error', 'Error', error.response?.data?.message ?? 'error');
    }
  };
};

export const getMerchantBeneficiary = (key) => {
  return async (dispatch) => {
    try {
      const res = await fetchBeneficiaries(key);
      dispatch({
        type: MerchantActionTypes.FETCH_BENEFICIARIES_SUCCESS,
        payload: res.data.data,
      });
    } catch (error) {
      console.log(error.response?.data?.message);
    }
  };
};

export const fetchPrefix = (key, id) => {
  return async (dispatch) => {
    try {
      const res = await fetchWalletPrefix(key, id);
      dispatch({
        type: 'FETCH_PREFIX_SUCCESS',
        payload: res.data.data,
      });
    } catch (error) {
      console.log(error.response?.data?.message);
    }
  };
};

export const addMerchant = (data) => {
  return async (dispatch) => {
    try {
      dispatch(addMerchantStart());
      await completeSignup(data);
      dispatch(addMerchantSuccess());
      openNotificationWithIcon('success', 'Success', 'Business Created Successfully');
      closeCreateMerchant(dispatch);
      const response = await getCurrentUser();
      fetchUser(dispatch, response.data);
    } catch (error) {
      dispatch(addMerchantFailure(error.response?.data?.message));
      return openNotificationWithIcon('error', 'Error', error.response?.data?.message);
    }
  };
};

// add bank details for cashier
export const addCashierBankDetailsAction = (data, cashpointID) => {
  return async (dispatch) => {
    try {
      dispatch(addCashierBankDetailsStart());
      await addCashierBankDetails(data);
      dispatch(loadCashierInfo(cashpointID));
      dispatch(addCashierBankDetailsSuccess());
      openNotificationWithIcon('success', 'Success', `Bank details added successfully`);
    } catch (error) {
      if (error.response?.data?.message !== 'session has expired') {
        openNotificationWithIcon('error', 'Error', error.response?.data.message);
      }
      return dispatch(addCashierBankDetailsFailure(error.response?.data.message ?? 'Error Adding Bank Details'));
    }
  };
};

export const uploadCashierProfilePictureAction = (id, data) => {
  return async (dispatch) => {
    try {
      dispatch(uploadProfilePictureStart());
      await uploadCashierProfilePicture(id, data);
      openNotificationWithIcon('success', 'Success', `Profile picture updated successfully`);
    } catch (error) {
      if (error.response?.data?.message !== 'session has expired') {
        openNotificationWithIcon('error', 'Error', error.response?.data.message ?? 'Error Updating Profile Picture');
      }
      return dispatch(uploadProfilePictureFailure(error.response?.data.message ?? 'Error Updating Profile Picture'));
    }
  };
};

export const checkLoanEligibility = () => {
  return async (dispatch) => {
    try {
      dispatch(checkLoanEligibilityStart());
      const response = await checkEligibilityForLoan();

      dispatch(checkLoanEligibilitySuccess(response.data));
    } catch (error) {
      return dispatch(checkLoanEligibilityFailure(error.response?.data?.message));
    }
  };
};

export const submitLoanRequest = (data) => {
  return async (dispatch) => {
    try {
      dispatch(submitLoanRequestStart());
      const response = await applyForLoan(data);
      console.log(response);
      dispatch(submitLoanRequestSuccess(response.data));
    } catch (error) {
      return dispatch(submitLoanRequestFailure(error.response?.data?.message));
    }
  };
};

export const fetchLoanInterestRates = (data) => {
  return async (dispatch) => {
    try {
      dispatch(fetchLoanInterestRatesStart());
      const response = await getLoanInterestRates(data);

      dispatch(fetchLoanInterestRatesSuccess(response.data));
    } catch (error) {
      return dispatch(fetchLoanInterestRatesFailure(error.response?.data?.message));
    }
  };
};

export const verifyAggregatorCode = (data) => {
  return async (dispatch) => {
    try {
      dispatch(verifyAggregatorCodeStart());
      const response = await verifyAgggregatorCode(data);

      dispatch(verifyAggregatorCodeSuccess(response.data));
    } catch (error) {
      return dispatch(verifyAggregatorCodeFailure(error.response?.data?.message));
    }
  };
};

export const getLoanHistory = (data) => {
  return async (dispatch) => {
    try {
      dispatch(fetchLoanHistoryStart());
      const response = await fetchLoanHistory(data);

      dispatch(fetchLoanHistorySuccess(response.data));
    } catch (error) {
      return dispatch(fetchLoanHistoryFailure(error.response?.data?.message));
    }
  };
};

export const getWarranterLoanRequests = (data) => {
  return async (dispatch) => {
    try {
      dispatch(fetchWarranterLoanRequestsStart());
      const response = await fetchWarranterLoanRequests(data);

      dispatch(fetchWarranterLoanRequestsSuccess(response.data));
    } catch (error) {
      return dispatch(fetchWarranterLoanRequestsFailure(error.response?.data?.message));
    }
  };
};

export const getLoanCreditLimit = (data) => {
  return async (dispatch) => {
    try {
      dispatch(fetchLoanCreditLimitStart());
      const response = await fetchWalletCreditLimit(data);

      dispatch(fetchLoanCreditLimitSuccess(response.data));
    } catch (error) {
      return dispatch(fetchLoanCreditLimitFailure(error.response?.data?.message));
    }
  };
};

export const warranterApproveLoan = (data) => {
  return async (dispatch) => {
    try {
      dispatch(fetchLoanCreditLimitStart());
      const response = await approveLoan(data);

      dispatch(fetchLoanCreditLimitSuccess(response.data));
    } catch (error) {
      return dispatch(fetchLoanCreditLimitFailure(error.response?.data?.message));
    }
  };
};

export const fetchNextLoanRepayment = (data) => {
  return async (dispatch) => {
    try {
      dispatch(getNextLoanRepaymentStart());
      const response = await getNextLoanRepayment(data);

      dispatch(getNextLoanRepaymentSuccess(response.data));
    } catch (error) {
      return dispatch(getNextLoanRepaymentFailure(error.response?.data?.message));
    }
  };
};

export const fetchGuarrantorTypes = (data) => {
  return async (dispatch) => {
    try {
      dispatch(getGuarantorTypesStart());
      const response = await getGuarantorTypes(data);

      dispatch(getGuarantorTypesSuccess(response.data));
    } catch (error) {
      return dispatch(getGuarantorTypesFailure(error.response?.data?.message));
    }
  };
};

export const getAlternateCashpointAcctFees = (key, id) => {
  return async (dispatch) => {
    try {
      dispatch(getAlternateCashpointAccountStart());
      const response = await fetchAlternateCashpointAccountFees(key, id);
      dispatch(getAlternateCashpointAccountSuccess(response?.data));
    } catch (error) {
      return dispatch(getAlternateCashpointAccountError(error?.response?.data));
    }
  };
};
