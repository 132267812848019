export const customInboundValidate = (values) => {
  const errors = {};
  if (!values.originator_account_name) {
    errors.customer_name = 'Customer Name Should Not Be Empty.';
  }
  if (!values.amount) {
    errors.amount = 'Amount Should Not Be Empty.';
  }
  if (!values.description) {
    errors.description = 'Description Should Not Be Empty.';
  }
  if (!values.transaction_date) {
    errors.transaction_date = 'Date Should Not Be Empty.';
  }
  if (!values.product_id) {
    errors.product_id = 'Product Should Not Be Empty.';
  }
  if (!values.quantity) {
    errors.count = 'Product Quantity Should Not Be Empty.';
  }
  if (!values.payment_type) {
    errors.paymentMethod = 'Payment Method Should Not Be Empty.';
  }
  return errors;
};

export const addProductValidate = (values) => {
  const errors = {};
  if (!values.categoryName) {
    errors.categoryName = 'Category Name Cannot Be Empty';
  }
  return errors;
};

export const customIncomeValidate = (values) => {
  const errors = {};
  if (!values.product_id) {
    errors.product_id = 'Product Cannot Be Empty';
  }
  return errors;
};

export const addIncomeProductValidate = (values) => {
  const errors = {};

  if (!values.name) {
    errors.name = 'Product Name Cannot Be Empty';
  }
  if (!values.description) {
    errors.description = 'Product Description Cannot Be Empty';
  }
  if (!values.picture_url) {
    errors.picture_url = 'Please upload an image, Product image cannot be empty.';
  }
  return errors;
};
