import { useInput } from '../../hooks/use_input';
import AppButton from '../app_button/app_button';
import { Spacer } from '../layout/layout';
import { PageHeader } from '../terms_content/terms_content';

import cardOverlay from '../../assets/images/cardOverlay.svg';

import { useState } from 'react';
import { openNotificationWithIcon } from '../../pages/auth/primary_signup/primary_signup';
import { contactSupport } from '../../services/apiSevices';
import createGoogleRecaptchaV3 from '../../utils/createCaptchaToken';
import { ContactCards } from '../contact_cards/contact_cards';
import { WaitlistPopUpContainer } from '../home_header/home_header';
import './contact_content.css';

export default function ContactContent() {
  const subjectProps = useInput('');
  const messageProps = useInput('');
  const [loading, setLoading] = useState(false);

  const sendMessage = async () => {
    if (!subjectProps.value || !messageProps.value) {
      openNotificationWithIcon('error', 'Error', 'Message and subject cannot be empty');
      return;
    }
    setLoading(true);
    const appToken = await createGoogleRecaptchaV3();
    const messageBody = {
      subject: subjectProps.value,
      message: messageProps.value,
    };
    try {
      const res = await contactSupport(messageBody, appToken?.token);
      openNotificationWithIcon('success', 'Success', 'Message Sent');
      subjectProps.reset;
      messageProps.reset;
      setLoading(false);
    } catch (error) {
      console.log(error?.response);
      openNotificationWithIcon('error', 'Error', 'Message not sent, try again');
      setLoading(false);
    }
  };

  return (
    <div className="contact-wrapper full-width">
      <GradientBackground>
        <PageHeader text="Reach out to us: support@vpay.africa" />
        <span className="fw400 primary-color page-header-content line-height12">
          Welcome! We’re here to help you get started with VPay. Start a live chat converstation if you have any questions or contact us.
        </span>
      </GradientBackground>
      <ContentPadding>
        <div className="d-grid card-grid">
          <ContactCards />
        </div>
        <Spacer height={60} />
        <input className="contact-subject" style={{ opacity: subjectProps.value ? 1 : 0.3 }} placeholder="Subject" {...subjectProps} />
        <Spacer height={20} />
        <textarea className=" contact-message" style={{ opacity: messageProps.value ? 1 : 0.3 }} placeholder="Message" {...messageProps}></textarea>
        <Spacer height={50} />
        <AppButton name="Send message" isBusy={loading} className="fw500 contact-button" isActive={true} onClick={() => sendMessage()} />
      </ContentPadding>
      <Spacer height={70} />
      <WaitlistPopUpContainer />
    </div>
  );
}

export const GradientBackground = ({ children, className, height, justifyContent }) => (
  <div style={{ height: height ?? '350px', justifyContent: justifyContent ?? 'center' }} className={`p-relative gradient-padding d-flex  line-height17 flex-vertical ${className}`}>
    {children}
    <img src={cardOverlay} alt="" className="p-absolute full-height gradient-overlay" />
  </div>
);

export const ContentPadding = ({ children }) => {
  return <div className="contact-padding p-relative full-height d-flex flex-vertical align-center">{children}</div>;
};
