import './terms_content.css';
import termsContent from './terms.json';
import { Spacer } from '../layout/layout';
import { ContentPadding, GradientBackground } from '../contact_content/contact_content';

import { WaitlistPopUpContainer } from '../home_header/home_header';

export default function TermsContent() {
  return (
    <div className="terms-wrapper">
      <GradientBackground>
        <PageHeader text="VPay Terms and Conditions" />
      </GradientBackground>
      <ContentPadding>
        <Spacer height={20} />
        <div dangerouslySetInnerHTML={{ __html: termsContent.content }}></div>
      </ContentPadding>
      <Spacer height={50} />
      <WaitlistPopUpContainer />
    </div>
  );
}

export const PageHeader = ({ text }) => {
  return <h1 className="fw700 page-header-text primary-color page-header no-margin">{text}</h1>;
};
