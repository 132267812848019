import { createSlice } from '@reduxjs/toolkit';
import { getDataPlansFor, getServicePlans } from '../../services/apiSevices';

const billSlice = createSlice({
  name: 'bills',
  initialState: {
    providers: [],
    packages: [],
    loading: false,
    error: null,
  },
  reducers: {
    getProviders: (state, { payload }) => {
      state.providers = payload;
    },
    getPackages: (state, { payload }) => {
      state.packages = payload;
    },
    billLoading: (state, { payload }) => {
      state.loading = payload;
    },
    billError: (state, { payload }) => {
      state.error = payload;
    },
  },
});
const billReducer = billSlice.reducer;

const apply =
  ({ remote, action }) =>
  async (dispatch) => {
    dispatch(billLoading(true));
    try {
      const { data } = await remote();
      if (data?.status) {
        dispatch(action(data?.data));
        dispatch(billLoading(false));
      } else {
        dispatch(billError(data?.message));
        dispatch(billLoading(false));
      }
    } catch (err) {
      console.log({ err });
      dispatch(billError(err.response?.data?.message));
      dispatch(billLoading(false));
    }
  };

export const { getPackages, getProviders, billError, billLoading } = billSlice.actions;
export const loadService = () => async (dispatch) => {
  await apply({ remote: () => getServicePlans(), action: getProviders })(dispatch);
};

export const loadDataPlansFor = (network) => async (dispatch) => {
  await apply({ remote: () => getDataPlansFor(network), action: getPackages })(dispatch);
};
export const billSelector = (state) => state.bills;
export default billReducer;
