import { LoadingOutlined } from '@ant-design/icons';
import { Divider } from 'antd';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import camera from '../../assets/images/camera-icon.svg';
import cameraIcon from '../../assets/images/camera.svg';
import checkIcon from '../../assets/images/check-rounded.svg';
import messageIcon from '../../assets/images/message-light.svg';
import shareIcon from '../../assets/images/share-red.svg';
import logo from '../../assets/images/vpay-logo.svg';
import AppButton from '../../components/app_button/app_button';
import { BorderLessFormDropdown, BorderLessFormDropdownClone, FormDropdown } from '../../components/form_dropdown/form_dropdown';
import { BorderLessAppInputWithCount, BorderLessDateInput, BorderLessFormInput, FormInput } from '../../components/form_input/form_input';
import { Spacer } from '../../components/layout/layout';
import {
  AddCustomInbound,
  DateRow,
  DesktopHistoryTransactionItem,
  EmptyView,
  Filter,
  HistoryMobileTransactionContainer,
  MerchantContentWrapper,
} from '../../components/merchant_widgets/merchant_widgets';
import { AddProductCategory, BorderlessCategoriseInbound, BorderlessDropdownWithImg, CategoriseInboundPopup, ConfirmCustomHeader } from '../../components/new_popup_widgets/popup_widgets';
import Popup from '../../components/popup/popup';
import { PopupHeader } from '../../components/popup_header/popup_header';
import { MobileRedAlertWithLink } from '../../components/red_alert/red_alert';
import { CustomCountdown } from '../../components/timer';
import { WebcamCapture } from '../../components/webcam/webcam';
import { useFeatureFlag } from '../../hooks/use_feature_flag';
import { useInput } from '../../hooks/use_input';
import { usePaginate } from '../../hooks/use_paginate';
import { useRecieptDetails } from '../../hooks/use_reciept_details';
import useSyncState from '../../hooks/use_sync_state';
import { useTransactionDetail } from '../../hooks/use_transaction_item';
import useVerifiedUser from '../../hooks/use_user_verified';
import { setAllInfo, setConfirmCustomInbound } from '../../redux/merchant/custom_inbound.feature';
import {
  confrimUSSD,
  createDispute,
  fetchMerchantPayouts,
  getDisputeType,
  getMerchantProducts,
  getRecieptPdf,
  getTransactionChallenge,
  reverseTransactionService,
  setChallengeAnswer,
  uploadSelfie,
} from '../../services/apiSevices';
import { formatNumber, numberWithCommas } from '../../utils/formatNumber';
import { formatDay, formatYear, getCloudKeyFromEnv, getCloudNameFromEnv, getCloudUrlFromEnv, isDesktop, isSameOrAfterNow, totalTransactionByDate } from '../../utils/helper';
import { customInboundValidate } from '../../utils/validation_rules/custom_inbound_validate';
import { openNotificationWithIcon } from '../auth/primary_signup/primary_signup';

export default function MerchantTransaction() {
  const merchantID = useSelector((state) => state.user.currentMerchant['businessid']);
  const { publickey } = useSelector((state) => state.user?.currentMerchant);
  const [currentRecord, setCurrentRecord] = useState(null);
  const [show, setShow] = useState(false);
  const [values, setValues] = useState({ from: '', to: '' });

  const [pageNumber, setPageNumber] = useState(1);
  const [showDisputePopup, setShowDisputePopup] = useState(false);
  const [showChallengePopup, setShowChallengePopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [challengeDetails, setChallengeDetails] = useState(null);
  const [showCustomInbound, setShowCustomInbound] = useState(false);
  const { confirmCustomInbound, refreshCount } = useSelector((state) => state?.customInboundReducer);

  const { isFlagRequired } = useFeatureFlag('categorise_tnxs');

  const { hasBVNorNIN, hasIDCard, hasLivelinessVideo, hasBeenVerified } = useVerifiedUser();

  const payouts = useCallback(async () => {
    const res = await fetchMerchantPayouts(merchantID, pageNumber, values.from, values.to);
    return res.data.data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNumber, merchantID, values, refreshCount]);

  const { data, lastElementRef, loading, error } = usePaginate(merchantID, pageNumber, setPageNumber, payouts, values, refreshCount);

  const handleClosePopupDetail = () => {
    setShow(false);
    setShowDisputePopup(true);
  };

  const handleInitiateChallenge = async () => {
    try {
      setIsLoading(true);
      const res = await getTransactionChallenge(publickey, currentRecord._id);
      setChallengeDetails(res.data.data);
      setIsLoading(false);
      setShow(false);
      setShowChallengePopup(true);
    } catch (error) {
      setIsLoading(false);
      if (error.response?.data?.message === 'Invalid parameters') {
        openNotificationWithIcon('error', 'Error', 'You have exceeded the number of times you can initiate a challenge');
      } else {
        openNotificationWithIcon('error', 'Error', error.response?.data?.message);
      }
    }
  };

  let isVerified;
  if (hasBVNorNIN && hasIDCard) {
    isVerified = true;
  } else if (hasBVNorNIN && hasLivelinessVideo) {
    isVerified = true;
  } else {
    isVerified = false;
  }

  const transactionHeader = ['Amount', 'Narrative', 'Tnx Referrence', 'Date', 'Type'];

  let uniqueTransactionDates = new Set();
  const listDateInfo = useRef(uniqueTransactionDates);
  const dailyTotal = {};

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const DATE_MAP = useMemo(() => totalTransactionByDate(data, [...listDateInfo.current], dailyTotal), [dailyTotal, refreshCount]);

  return (
    <MerchantContentWrapper
      className="full-height-minus-150 fw400 fade-in no-padding-important-mobile"
      beforeSidePadding={
        <div className="d-flex align-center flex-vertical-mobile">
          <Filter setValues={setValues} setPageNumber={setPageNumber} />
          {isFlagRequired && <AddCustomInbound setShowCustomInbound={setShowCustomInbound} />}
        </div>
      }
    >
      {(hasBVNorNIN && isVerified) || hasBeenVerified ? '' : <MobileRedAlertWithLink text="Activate Your Account" lowerText="Verify Your Business Account To Enjoy All VPAY Features" />}
      <Spacer height={40} className="no-mobile" />

      {!isDesktop() && <HistoryMobileTransactionContainer values={values} setCurrentRecord={setCurrentRecord} setShow={setShow} />}
      {data && data.length > 0 ? (
        <>
          <div className="desktop-history-trans no-mobile table-padding primary-bg-color full-width border-radius5 height_auto">
            <div className="d-flex desktop-history-trans-header">
              {transactionHeader.map((header) => (
                <p className="font-size-15 fw500" key={header}>
                  {header}
                </p>
              ))}
            </div>
            <div>
              {data
                ? data.map((tnx, idx) => {
                    if (uniqueTransactionDates.has(formatYear(tnx.createdon))) {
                      return <DesktopHistoryTransactionItem key={idx} t={tnx} ref={data.length === idx + 1 ? lastElementRef : null} setShow={setShow} setCurrentRecord={setCurrentRecord} />;
                    } else {
                      listDateInfo.current.add(formatYear(tnx.createdon));
                      uniqueTransactionDates.add(formatYear(tnx.createdon));
                      return (
                        <Fragment key={idx}>
                          <DateRow
                            debit={DATE_MAP[formatYear(tnx.createdon)]?.totalDebit}
                            credit={DATE_MAP[formatYear(tnx.createdon)]?.totalCredit}
                            date={dayjs(tnx.createdon).format('DD/MMM/YYYY')}
                            customInbound={DATE_MAP[formatYear(tnx.createdon)]?.totalCustomInbound}
                          />
                          <DesktopHistoryTransactionItem t={tnx} ref={data.length === idx + 1 ? lastElementRef : null} setShow={setShow} setCurrentRecord={setCurrentRecord} />
                        </Fragment>
                      );
                    }
                  })
                : []}
            </div>
          </div>
          {loading && <Spacer height={20} />}
          <div className="full-width d-flex align-center j-center">{loading && <LoadingOutlined style={{ fontSize: '1em' }} size="large" />}</div>
          {show && (
            <NewPopupDetails
              onClose={() => setShow(false)}
              totalAmount={`₦${numberWithCommas(currentRecord.amount)}`}
              download
              type={currentRecord.type}
              txnRef={currentRecord.transactionref}
              description={currentRecord.narrative}
              date={currentRecord.createdon}
              id={currentRecord._id}
              status={currentRecord.status}
              reversed={currentRecord.reversed}
              handleClosePopupDetail={handleClosePopupDetail}
              isLoading={isLoading}
              handleInitiateChallenge={handleInitiateChallenge}
              currentRecord={currentRecord}
              reversalexpireson={currentRecord.reversalexpireson}
              createdon={currentRecord.createdon}
              // originator_account_name={currentRecord.originator_account_name}
            />
          )}
          {showDisputePopup && (
            <CreateDisputePopup
              onClose={() => setShowDisputePopup(false)}
              totalAmount={numberWithCommas(currentRecord.amount)}
              description={currentRecord.narrative}
              date={currentRecord.created_on}
              id={currentRecord._id}
            />
          )}
          {showCustomInbound && <CustomInboundPopup setShowCustomInbound={setShowCustomInbound} />}
          {confirmCustomInbound && <ConfirmCustomInboundPopup setParentPopup={setShowCustomInbound} />}
          {showChallengePopup && (
            <InitiateChallengePopup
              id={currentRecord._id}
              onClose={() => {
                setShowChallengePopup(false);
                setShow(true);
              }}
              label={challengeDetails?.message}
              type={challengeDetails?.challenge_type}
            />
          )}
        </>
      ) : (
        <>
          <Spacer className="no-desktop-but-mobile" height={100} />
          <div className="d-flex align-center no-mobile j-center full-width full-height">
            {!loading && error && !error?.status && (
              <EmptyView
                title="An Error Occurred"
                description={
                  <>
                    An error occurred when getting your transactions history.
                    <br /> Please refresh the page.
                  </>
                }
              />
            )}
            {loading && <LoadingOutlined style={{ fontSize: '1em' }} size="large" />}
          </div>
        </>
      )}
    </MerchantContentWrapper>
  );
}

export const CreateDisputePopup = ({ id, totalAmount, description, date, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [disputeType, setDisputeType] = useState(null);
  const [remark, setRemark] = useState('');

  useEffect(() => {
    const getDisputeTypes = async () => {
      try {
        const res = await getDisputeType();
        setOptions(res.data.data);
      } catch (error) {
        console.log(error.response?.data?.message);
      }
    };
    getDisputeTypes();
    return () => null;
  }, []);

  const onSubmitClick = async () => {
    try {
      setLoading(true);
      const data = {
        transaction_id: id,
        type: 'merchant_transaction',
        dispute_type: disputeType,
        remark: remark,
      };
      const res = await createDispute(data);
      if (res.data.status) {
        openNotificationWithIcon('success', 'Success', 'Dispute Created Successfully.');
        setLoading(false);
        onClose();
      }
    } catch (error) {
      setLoading(false);
      openNotificationWithIcon('error', 'Error', error.response?.data?.message);
    }
  };

  return (
    <Popup className="details-popup-content px-75" onClose={onClose}>
      <div className="transaction-details d-flex flex-column j-space-between" style={{ height: '65vh' }}>
        <div>
          <div className="d-flex j-space-between mt-20">
            <div className="d-flex">
              <div className="mr-2">
                <img src={cameraIcon} alt="an icon" />
              </div>
              <div>
                <p className="fw500 text-color font-size-15 w-80">{description}</p>
                <span className="fw400 text-color opacity-5 font-size-13">{formatDay(date)}</span>
              </div>
            </div>
            <div>
              <p className="fw500 font-size-16 action-color">{totalAmount}</p>
            </div>
          </div>
          <form className="d-flex flex-column j-space-between full-height mt-20 mb-20">
            <div>
              <FormDropdown
                placeholder="Select type of dispute"
                name="disputeType"
                className="full-width mb-10 dispute-select"
                options={options}
                defaultValue={disputeType}
                onChange={(e) => setDisputeType(e)}
              />
              <textarea
                className="message-box dispute-textarea fw400 font-size-16 full-width px-20 resize-none"
                placeholder="Enter message"
                value={remark}
                onChange={(e) => setRemark(e.target.value)}
              ></textarea>
            </div>
            <div className="mb-20">
              <AppButton
                name="Submit Dispute"
                isActive={true}
                isBusy={loading}
                className="full-width font-size-17 fw400 upload-button primary-color action-color-bg padding-50 margin-top-10"
                onClick={onSubmitClick}
              />
            </div>
          </form>
        </div>
      </div>
    </Popup>
  );
};

export const DisputeStatusPopup = ({ onClose }) => {
  return (
    <Popup className="details-popup-content px-75" onClose={onClose}>
      <div className="transaction-details">
        <div className="text-center">
          <h5 className="fw500 font-size-25 text-color">Transfer Successful</h5>
          <img src={checkIcon} alt="an icon" />
          <p className="font-size-17 tex-color mt-10 w-80 mx-auto">
            Your N50,000.00 transfer to <span className="fw500">Mr. Okoro Frank</span> is successfully done
          </p>
          <AppButton name="Done" className="font-size-17 fw400 upload-button primary-color action-color-bg px-50 my-30" />
        </div>
        <Spacer height={60} />
        <div className="d-flex j-center">
          <div>
            <AppButton
              className="download-receipt-btn d-flex align-center j-center text-left px-25 font-size-17 fw400 c-pointer text-dark-purple mx-5"
              name={
                <>
                  <img src={camera} alt="an icon" /> &nbsp; <span>Take a picture</span>
                </>
              }
            />
          </div>
          <div>
            <AppButton
              className="download-receipt-btn d-flex align-center j-center text-left px-25 font-size-17 fw400 c-pointer text-dark-purple mx-5"
              name={
                <>
                  <img src={shareIcon} alt="an icon" /> &nbsp; <span>Share Receipt</span>
                </>
              }
            />
          </div>
        </div>
        <Spacer height={20} />
      </div>
    </Popup>
  );
};

export const DisputeTransactionReceiptPopup = ({ onClose }) => {
  return (
    <Popup className="details-popup-content px-75" onClose={onClose}>
      <div className="transaction-details">
        <div className="text-center">
          <img src={logo} className="mb-20" alt="company's logo" />
          <p className="font-size-13 fw500 action-color">Transaction Receipt</p>
          <Divider />
          <p className="font-size-17 fw700 text-color">
            Hello (Account Owner) <br />
            Account Number: 54723629917
          </p>
          <Divider />
        </div>
        <div>
          {[].map((item) => (
            <div className="d-flex j-space-between" key={item.id}>
              <p className="font-size-13 text-dark-purple mb-20 w-50">{item.title}</p>
              <p className="font-size-16 fw500 text-color mb-20 w-50">{item.value}</p>
            </div>
          ))}
        </div>
        <Divider />
        <p className="font-size-10 text-justify" style={{ color: 'rgba(25, 0, 65, 0.3)' }}>
          VPay (RC Number). All rights reserved. All deposits are insured by (Insurance Corporation name). DartPay is powered by VFD Microfinance bank. DartPay Solutions and DartPay are trademarks of
          Minerva Technologies LTD. No 2 Bosun Adekoya Street Oniru Victoria Island Lagos.
        </p>
        <div className="d-flex j-space-between mt-20 mb-20">
          <div>
            <AppButton
              className="download-receipt-btn d-flex align-center j-center text-left px-25 font-size-17 fw400 c-pointer text-dark-purple"
              name={
                <>
                  <img src={camera} alt="an icon" /> &nbsp; <span>Take a picture</span>
                </>
              }
            />
          </div>
          <div>
            <AppButton
              className="download-receipt-btn d-flex align-center j-center text-left px-25 font-size-17 fw400 c-pointer text-dark-purple"
              name={
                <>
                  <img src={shareIcon} alt="an icon" /> &nbsp; <span>Share Receipt</span>
                </>
              }
            />
          </div>
        </div>
      </div>
    </Popup>
  );
};

export const NewPopupDetails = (props) => {
  const { totalAmount, download, type, id, customer, txnRef, description, date, onClose, merchant, status, handleClosePopupDetail, isLoading, handleInitiateChallenge, reversalexpireson, createdon } =
    props;
  const successCodes = useSelector((state) => state.merchant?.successCodes);
  const merchantIdFromState = useSelector((state) => state.user?.currentMerchant?.businessid);
  const failureCodes = useSelector((state) => state.merchant?.failureCodes);
  const publickey = useSelector((state) => state.user?.currentMerchant?.publickey);
  const { detail, loading: isFetching } = useTransactionDetail(id);

  const isInbound = description?.includes('Inbound Transfer');
  const isOutbound = description?.includes('Outward Transfer') || description?.includes('Wallet Withdrawal') || description?.includes('Utility Bill');
  const canCategorizeOutbound = description?.includes('Outward Transfer');

  const { recieptDetails: beneficiaryDetails, loading: recieptDetailsLoading } = useRecieptDetails(merchant, isOutbound, id);
  const [loading, setLoading] = useState(false);
  const [reverseLoading, setReverseLoading] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [showCamera, setShowCamera] = useState(false);
  const [isImageLoading, setIsImageLoading] = useState(false);
  const [showReversed, setShowReversed] = useState(true);
  const [showCategory, setShowCategory] = useState(false);
  const [showInboundCategory, setShowInboundCategory] = useState(false);

  const [stage, setStage] = useState(0);

  const [image, setImage] = useState('');
  const info = useSyncState({
    selfie_url: '',
  });

  const merchantID = merchant || merchantIdFromState;

  const baseStyle = {
    textTransform: 'capitalize',
    width: '110px',
    borderRadius: '25px',
    textAlign: 'center',
  };

  const TransactionTypeStyle = {
    border: `1px solid ${type === 'credit' ? '#00BA48' : '#ED2B30'}`,
    color: `${type === 'credit' ? '#00BA48' : '#ED2B30'}`,
    ...baseStyle,
  };

  const StatusStyle = {
    border: `1px solid ${successCodes.includes(status) ? '#00BA48' : failureCodes.includes(status) ? '#f79420' : 'gray'}`,
    color: `${successCodes.includes(status) ? '#00BA48' : failureCodes.includes(status) ? '#f79420' : 'gray'}`,
    ...baseStyle,
  };

  const getTransactionStatus = useCallback(
    (status) => {
      if (successCodes.includes(status)) return 'success';
      if (failureCodes.includes(status)) return 'failed';
      if (status === '09') return 'pending';
      return 'paid';
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [status],
  );
  const onUploadClick = async (file) => {
    const data = new FormData();
    if (file) {
      data.append('file', file);
    }
    data.append('cloud_name', getCloudNameFromEnv());
    data.append('upload_preset', process.env.REACT_APP_UPLOAD_PRESET);
    data.append('folder', 'TakeAPicture');
    data.append('api_key', getCloudKeyFromEnv());

    try {
      setIsImageLoading(true);
      const res = await fetch(getCloudUrlFromEnv(), {
        method: 'POST',
        body: data,
      });
      const fileResponse = await res.json();

      if (fileResponse.secure_url) {
        info.set({
          ...info.get(),
          selfie_url: fileResponse.secure_url,
          // selfie_imagebase64: getBase64String(image),
        });

        const newData = {
          selfie_url: info.get().selfie_url,
          transaction_id: id,
          type: 'merchant_transaction',
        };
        const res = await uploadSelfie(newData);
        if (res.data.status) {
          openNotificationWithIcon('success', 'Success', 'Photo Uploaded Successfully');
          setIsImageLoading(false);
          setShowCamera(false);
        }
      }
      setIsImageLoading(false);
    } catch (error) {
      setIsImageLoading(false);
      openNotificationWithIcon('error', 'Error', error.response?.data?.message);
    }
  };

  const captureAndUpload = useCallback(() => {
    return (
      <Popup onClose={() => setShowCamera(false)}>
        <div>
          <p className="fw400 faded-text-color text-center font-size-14">Take a clear photo.</p>
          <Spacer height={15} />
          <WebcamCapture loading={isImageLoading} onSubmitClick={() => onUploadClick(imageFile)} image={image} setImageFile={setImageFile} setImage={setImage} />
        </div>
      </Popup>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image, imageFile, isImageLoading]);

  const BeneficiaryBlock = useCallback(() => {
    if (beneficiaryDetails) {
      const { beneficiaryAccountNo, beneficiaryBank } = beneficiaryDetails;
      return (
        <>
          <p className="font-size-13 text-color opacity-5 line-height17">Beneficiary Details</p>
          <p className="text-color fw400 font-size-13">{`${beneficiaryAccountNo} | ${beneficiaryBank} `}</p>
          <Spacer height={30} />
        </>
      );
    }
    return null;
  }, [beneficiaryDetails]);

  const onDownloadClick = async () => {
    try {
      setLoading(true);
      const res = await getRecieptPdf(merchantID, id);
      const pdfBlob = new Blob([res.data], { type: 'application/pdf' });
      saveAs(pdfBlob, 'VPay Tranaction Reciept');
      setLoading(false);
      openNotificationWithIcon('success', 'Success', 'Receipt Successfully Downloaded');
    } catch (error) {
      setLoading(false);
      openNotificationWithIcon('error', 'Error', error.response?.data?.message);
    }
  };

  const getDisputeButtonName = useMemo(() => {
    if (beneficiaryDetails?.dispute_status === 'resolved') return 'Dispute: Resolved';
    if (beneficiaryDetails?.dispute_status === 'pending') return 'Dispute: Pending';
    return (
      <>
        <img src={messageIcon} alt="message icon" /> &nbsp;
        <span>Log A Dispute</span>
      </>
    );
  }, [beneficiaryDetails?.dispute_status]);

  const getDisputeButtonStyle = useMemo(() => {
    if (beneficiaryDetails?.dispute_status === 'resolved') return 'success-bg-outline c-default';
    if (beneficiaryDetails?.dispute_status === 'pending') return 'action-color-bg-outline c-default';
    return 'action-color-bg';
  }, [beneficiaryDetails?.dispute_status]);

  const reverseTransaction = async () => {
    try {
      setReverseLoading(true);
      await reverseTransactionService(id, publickey);
      setReverseLoading(false);
      setShowReversed(false);
      openNotificationWithIcon('success', 'Success', 'Transaction Reversed Successfully');
    } catch (error) {
      setReverseLoading(false);
      openNotificationWithIcon('error', 'Error', error.response?.data?.message);
    }
  };

  const showReversalTimer = () => {
    if (!isSameOrAfterNow(reversalexpireson)) {
      return false;
    }
    if (!detail?.ismasked && detail?.issetselfie) {
      return false;
    }

    if (detail?.reversed) {
      return false;
    }
    return true;
  };

  const isCardPayment = description.includes('Card Payment');

  const shouldShowSelfie = useMemo(() => {
    if (isInbound && !isCardPayment && !detail?.issetselfie && !isFetching) return true;
    return false;
  }, [isInbound, isCardPayment, detail?.issetselfie, isFetching]);

  const shouldShowChallenge = useMemo(() => {
    if (isInbound && !isCardPayment && detail?.ismasked && !detail?.reversed && !isFetching) return true;
    return false;
  }, [isInbound, isCardPayment, detail?.ismasked, detail?.reversed, isFetching]);

  const shouldShowDispute = useMemo(() => {
    if (isOutbound && beneficiaryDetails?.dispute_status && !recieptDetailsLoading) return true;
    return false;
  }, [isOutbound, beneficiaryDetails?.dispute_status, recieptDetailsLoading]);

  return (
    <Popup className="details-popup-content px-75" onClose={onClose}>
      <div className="transaction-details">
        <div className="d-flex items-center">
          <div>
            <span className="fw400 text-color font-size-15">Total Amount</span>
            <p className="no-margin fw700 text-color font-size-20" style={{ marginTop: '-3px !important' }}>
              {totalAmount}
            </p>
          </div>
        </div>
        <Divider />
        <Spacer height={10} />
        <div className="details-grid p-relative d-grid dropdown-grid-temp-col-1fr mobile-gap grid-col-gap50">
          <div className="flex-one">
            <div>
              <p className="font-size-13 text-color opacity-5 line-height17">{status ? 'Status' : 'Type'}</p>
              <p className="text-color fw400 font-size-13 px-20 pt-2px pb-7px mt-5" style={status ? StatusStyle : TransactionTypeStyle}>
                {status ? getTransactionStatus(status) : type}
              </p>
              <Spacer height={15} />
            </div>
            <div className="max-width-13em overflow-auto">
              <p className="font-size-13 text-color opacity-5 line-height17">Destination</p>
              <p className="text-color fw400 font-size-13">{description || 'Not Available'}</p>
              <Spacer height={30} />
            </div>
            <div className="max-width-13em overflow-auto">{BeneficiaryBlock()}</div>
          </div>
          <div className="flex-one">
            {beneficiaryDetails && (
              <div>
                <p className="font-size-13 text-color opacity-5 line-height17">Fee</p>
                <p className="text-color fw400 font-size-13">{`₦${formatNumber(beneficiaryDetails.fee)}`}</p>
                <Spacer height={30} />
              </div>
            )}
            {customer && (
              <div>
                <p className="font-size-13 text-color opacity-5 line-height17">Customer</p>
                <p className="text-color fw400 font-size-13">{customer}</p>
                <Spacer height={30} />
              </div>
            )}
            <div>
              <p className="font-size-13 text-color opacity-5 line-height17">Date</p>
              <p className="text-color fw400 font-size-13">{formatDay(date)}</p>
              <Spacer height={30} />
            </div>
            {txnRef && (
              <div>
                <p className="font-size-13 text-color opacity-5 line-height17">Transaction Ref</p>
                <p className="text-color fw400 font-size-13">{txnRef}</p>
                <Spacer height={30} />
              </div>
            )}
            {beneficiaryDetails?.receiptID && (
              <div className="max-width-13em overflow-auto">
                <p className="font-size-13 text-color opacity-5 line-height17">Receipt ID</p>
                <p className="text-color fw400 font-size-13">{beneficiaryDetails.receiptID}</p>
                <Spacer height={30} />
              </div>
            )}
          </div>
        </div>
        <div className="pt-40 pb-20">
          {isFetching && isInbound && <LoadingOutlined style={{ fontSize: '1em' }} size="large" />}
          <div>
            {isOutbound && !detail?.reversed && !isFetching && (
              <div>
                <Spacer height={20} />
                {/* I think this should be removed */}
                <BorderlessDropdownWithImg openCategory={() => setShowCategory(true)} closeParentPopup={onClose} />
              </div>
            )}
          </div>

          {isInbound && !isFetching && (
            <div>
              <Spacer height={20} />
              <BorderlessCategoriseInbound openPopup={setShowInboundCategory} setStage={setStage} stage={stage} />
            </div>
          )}
          {showCategory && <AddProductCategory merchantId={merchantID} closeCategory={() => setShowCategory(false)} />}

          {shouldShowSelfie && (
            <div className="d-flex align-center j-center full-width">
              <button
                className="action-color c-pointer unstyle-button bg-none letter-spacing1 full-width d-flex j-center align-center font-size-15 fw500 margin-top-10 mb-10"
                onClick={() => setShowCamera(true)}
              >
                Take A Picture
              </button>
              {showCamera && captureAndUpload()}
            </div>
          )}
          {showInboundCategory && <CategoriseInboundPopup popupTrigger={setShowInboundCategory} tnxData={props} setStage={setStage} stage={stage} />}
          {shouldShowChallenge && (
            <AppButton
              isActive={true}
              isBusy={isLoading}
              onClick={() => handleInitiateChallenge()}
              name="Initiate a Challenge"
              className="action-color bg-none letter-spacing1 full-width d-flex j-center align-center font-size-13 fw500 margin-top-10 mb-10"
            />
          )}

          {shouldShowDispute && (
            <AppButton
              isActive={true}
              onClick={() => handleClosePopupDetail()}
              disabled={beneficiaryDetails?.dispute_status !== 'non' ? true : false}
              name={getDisputeButtonName}
              className={`${getDisputeButtonStyle} full-width d-flex j-center align-center font-size-14 fw400 upload-button primary-color padding-50 margin-top-10 mb-10`}
            />
          )}
          {isInbound && !isCardPayment && showReversed && showReversalTimer() && !isFetching && (
            <AppButton
              isActive={true}
              onClick={() => reverseTransaction()}
              name={<CustomCountdown reversalExpiriyTime={reversalexpireson} txnCreationTime={createdon || date} onStop={() => setShowReversed(false)} />}
              isBusy={reverseLoading}
              className="action-color-bg-outline full-width d-flex j-center align-center upload-button py-15 fw400 margin-top-10 mb-10"
            />
          )}
          {recieptDetailsLoading && isOutbound && <LoadingOutlined style={{ fontSize: '1em' }} size="large" />}
          <div className="full-width d-flex align-center j-center">
            {download && beneficiaryDetails?.receiptID && (
              <AppButton
                isActive={true}
                isBusy={loading}
                onClick={onDownloadClick}
                className="download-receipt-btn full-width font-size-14 fw400 c-pointer"
                name={
                  <>
                    <img src={shareIcon} alt="message icon" /> &nbsp; <span>Download Receipt</span>
                  </>
                }
              />
            )}
            <Spacer height={30} />
          </div>
        </div>
      </div>
    </Popup>
  );
};

export const InitiateChallengePopup = ({ id, type, label, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [answer, setAnswer] = useState('');
  const { publickey } = useSelector((state) => state.user?.currentMerchant);

  const getSubmitChallengeButtonStyle = () => {
    if (answer === '') return 'c-default';
    return 'c-pointer';
  };

  const onSubmitClick = async () => {
    try {
      setLoading(true);
      const res = await setChallengeAnswer({ answer }, id, publickey);
      if (res.data.status) {
        openNotificationWithIcon('success', 'Success', 'Challenge Setup Successfully.');
        setLoading(false);
        onClose();
      }
    } catch (error) {
      setLoading(false);
      openNotificationWithIcon('error', 'Error', error.response?.data?.message);
    }
  };

  const onConfirmUssdClick = async () => {
    try {
      setLoading(true);
      const res = await confrimUSSD(id);
      if (res.data.status) {
        openNotificationWithIcon('success', 'Success', res?.data?.message);
        setLoading(false);
        onClose();
      } else {
        openNotificationWithIcon('error', 'Error', res?.data?.message);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      openNotificationWithIcon('error', 'Error', error.response?.data?.message);
    }
  };

  return (
    <Popup className="details-popup-content px-75" onClose={onClose}>
      <div className="transaction-details d-flex flex-column j-space-between" style={{ height: '65vh' }}>
        <div>
          {type === 'otp' ? (
            <div className="d-flex align-center flex-vertical">
              <p className="input-label fw200 ">{label}</p>
              <Spacer height={15} />
              <AppButton
                isActive={true}
                isBusy={loading}
                onClick={() => onConfirmUssdClick()}
                name="Continue"
                className="action-color bg-none letter-spacing1 full-width d-flex j-center align-center font-size-15 fw500 margin-top-10 mb-10"
              />
            </div>
          ) : (
            <form className="d-flex flex-column j-space-between full-height mt-20">
              <div>
                <FormInput label={label} value={answer} onChange={(e) => setAnswer(e.target.value)} />
              </div>
              <div className="mb-20 d-flex align-center flex-vertical">
                <AppButton
                  name="Submit Challenge"
                  disabled={answer === ''}
                  isActive={true}
                  isBusy={loading}
                  onClick={onSubmitClick}
                  className={`${getSubmitChallengeButtonStyle()} mb-20 full-width font-size-17 fw400 upload-button primary-color action-color-bg padding-50`}
                />
              </div>
            </form>
          )}
        </div>
      </div>
    </Popup>
  );
};

export const CashpointNewPopupDetails = (props) => {
  const { totalAmount, type, id, merchantId, customer, txnRef, description, date, onClose, status, isLoading, handleInitiateChallenge, reversalexpireson, createdon } = props;
  const successCodes = useSelector((state) => state.merchant?.successCodes);
  //const merchantIdFromState = useSelector((state) => state.user?.currentMerchant?.businessid);
  const failureCodes = useSelector((state) => state.merchant?.failureCodes);
  const publickey = useSelector((state) => state.user?.currentMerchant?.publickey);
  const { detail, loading: isFetching } = useTransactionDetail(id);

  const isInbound = description?.includes('Inbound Transfer');

  const [reverseLoading, setReverseLoading] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [showCamera, setShowCamera] = useState(false);
  const [isImageLoading, setIsImageLoading] = useState(false);
  const [showReversed, setShowReversed] = useState(true);

  const checkSelfie = detail.merchanttransactiondata && detail?.merchanttransactiondata[0]?.issetselfie;
  const checkIsMasked = detail.merchanttransactiondata && detail?.merchanttransactiondata[0]?.ismasked;
  const checkReversed = detail.merchanttransactiondata && detail?.merchanttransactiondata[0]?.reversed;

  const [image, setImage] = useState('');
  const info = useSyncState({
    selfie_url: '',
  });

  const baseStyle = {
    textTransform: 'capitalize',
    width: '110px',
    borderRadius: '25px',
    textAlign: 'center',
  };

  const TransactionTypeStyle = {
    border: `1px solid ${type === 'credit' ? '#00BA48' : '#ED2B30'}`,
    color: `${type === 'credit' ? '#00BA48' : '#ED2B30'}`,
    ...baseStyle,
  };

  const StatusStyle = {
    border: `1px solid ${successCodes.includes(status) ? '#00BA48' : failureCodes.includes(status) ? '#f79420' : '#00BA48'}`,
    color: `${successCodes.includes(status) ? '#00BA48' : failureCodes.includes(status) ? '#f79420' : '#00BA48'}`,
    ...baseStyle,
  };

  const getTransactionStatus = useCallback(
    (status) => {
      if (successCodes.includes(status)) return 'success';
      if (failureCodes.includes(status)) return 'failed';
      if (status === '09') return 'pending';
      return 'paid';
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [status],
  );

  const onUploadClick = async (file) => {
    const data = new FormData();
    if (file) {
      data.append('file', file);
    }
    data.append('cloud_name', getCloudNameFromEnv());
    data.append('upload_preset', process.env.REACT_APP_UPLOAD_PRESET);
    data.append('folder', 'TakeAPicture');
    data.append('api_key', getCloudKeyFromEnv());

    try {
      setIsImageLoading(true);
      const res = await fetch(getCloudUrlFromEnv(), {
        method: 'POST',
        body: data,
      });
      const fileResponse = await res.json();

      if (fileResponse.secure_url) {
        info.set({
          ...info.get(),
          selfie_url: fileResponse.secure_url,
          // selfie_imagebase64: getBase64String(image),
        });

        const newData = {
          selfie_url: info.get().selfie_url,
          transaction_id: merchantId,
          type: 'merchant_transaction',
        };
        const res = await uploadSelfie(newData);
        if (res.data.status) {
          openNotificationWithIcon('success', 'Success', 'Photo Uploaded Successfully');
          setIsImageLoading(false);
          setShowCamera(false);
        }
      }
      setIsImageLoading(false);
    } catch (error) {
      setIsImageLoading(false);
      openNotificationWithIcon('error', 'Error', error.response?.data?.message);
    }
  };

  const captureAndUpload = useCallback(() => {
    return (
      <Popup onClose={() => setShowCamera(false)}>
        <div>
          <p className="fw400 faded-text-color text-center font-size-15">Take a clear photo.</p>
          <Spacer height={15} />
          <WebcamCapture loading={isImageLoading} onSubmitClick={() => onUploadClick(imageFile)} image={image} setImageFile={setImageFile} setImage={setImage} />
        </div>
      </Popup>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image, imageFile, isImageLoading]);

  const reverseTransaction = async () => {
    try {
      setReverseLoading(true);
      await reverseTransactionService(merchantId, publickey);
      setReverseLoading(false);
      setShowReversed(false);
      openNotificationWithIcon('success', 'Success', 'Transaction Reversed Successfully');
    } catch (error) {
      setReverseLoading(false);
      openNotificationWithIcon('error', 'Error', error.response?.data?.message);
    }
  };

  const showReversalTimer = () => {
    if (!isSameOrAfterNow(reversalexpireson)) {
      return false;
    }
    if (!checkIsMasked && checkSelfie) {
      return false;
    }
    if (checkReversed) {
      return false;
    }
    return true;
  };

  const shouldShowSelfie = useMemo(() => {
    if (isInbound && !checkSelfie && !isFetching) return true;
    return false;
  }, [isInbound, checkSelfie, isFetching]);

  const shouldShowChallenge = useMemo(() => {
    if (isInbound && checkIsMasked && !checkReversed && !isFetching) return true;
    return false;
  }, [isInbound, checkIsMasked, checkReversed, isFetching]);

  return (
    <Popup className="details-popup-content px-75" onClose={onClose}>
      <div className="transaction-details">
        <div className="d-flex items-center">
          <div>
            <span className="fw400 text-color font-size-15">Total Amount</span>
            <p className="no-margin fw700 text-color font-size-24" style={{ marginTop: '-3px !important' }}>
              {totalAmount}
            </p>
          </div>
        </div>
        <Divider />
        <Spacer height={10} />
        <div className="details-grid p-relative d-grid dropdown-grid-temp-col-1fr mobile-gap grid-col-gap50">
          <div className="flex-one">
            <div>
              <p className="font-size-15 text-color opacity-5 line-height17">{status ? 'Status' : 'Type'}</p>
              <p className="text-color fw400 font-size-17 px-20 pt-2px pb-7px mt-5" style={status ? StatusStyle : TransactionTypeStyle}>
                {status ? getTransactionStatus(status) : type}
              </p>
              <Spacer height={15} />
            </div>
            <div className="max-width-13em overflow-auto">
              <p className="font-size-15 text-color opacity-5 line-height17">Destination</p>
              <p className="text-color fw400 font-size-17">{description || 'Not Available'}</p>
              <Spacer height={30} />
            </div>
          </div>
          <div className="flex-one">
            {customer && (
              <div>
                <p className="font-size-15 text-color opacity-5 line-height17">Customer</p>
                <p className="text-color fw400 font-size-17">{customer}</p>
                <Spacer height={30} />
              </div>
            )}
            <div>
              <p className="font-size-15 text-color opacity-5 line-height17">Date</p>
              <p className="text-color fw400 font-size-17">{formatDay(date)}</p>
              <Spacer height={30} />
            </div>
            {txnRef && (
              <div>
                <p className="font-size-15 text-color opacity-5 line-height17">Transaction Ref</p>
                <p className="text-color fw400 font-size-17">{txnRef}</p>
                <Spacer height={30} />
              </div>
            )}
          </div>
        </div>
        <div className="pt-40 pb-20">
          {isFetching && isInbound && <LoadingOutlined style={{ fontSize: '1em' }} size="large" />}
          {shouldShowSelfie && (
            <div className="d-flex align-center j-center full-width">
              <button
                className="action-color c-pointer unstyle-button bg-none letter-spacing1 full-width d-flex j-center align-center font-size-15 fw500 margin-top-10 mb-10"
                onClick={() => setShowCamera(true)}
              >
                Take A Picture
              </button>
              {showCamera && captureAndUpload()}
            </div>
          )}
          {shouldShowChallenge && (
            <AppButton
              isActive={true}
              isBusy={isLoading}
              onClick={() => handleInitiateChallenge()}
              name="Initiate a Challenge"
              className="action-color bg-none letter-spacing1 full-width d-flex j-center align-center font-size-15 fw500 margin-top-10 mb-10"
            />
          )}
          {isInbound && showReversed && showReversalTimer() && !isFetching && (
            <AppButton
              isActive={true}
              onClick={() => reverseTransaction()}
              name={<CustomCountdown reversalExpiriyTime={reversalexpireson} txnCreationTime={createdon || date} onStop={() => setShowReversed(false)} />}
              isBusy={reverseLoading}
              className="action-color-bg-outline full-width d-flex j-center align-center upload-button py-15 fw400 margin-top-10 mb-10"
            />
          )}
        </div>
      </div>
    </Popup>
  );
};

export const CustomInboundPopup = ({ setShowCustomInbound }) => {
  const nameProps = useInput('', 'text', '', '');
  const amountProps = useInput('', 'number', '', '');
  const descProps = useInput('', 'text', '', '');
  const [dateProps, setDateString] = useState('');
  const [product, setProduct] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [error, setError] = useState({});
  useSelector((state) => state?.customInboundReducer);
  const merchantId = useSelector((state) => state.user?.currentMerchant?.businessid);
  //const { data: categoryList, isLoading } = useQuery({ queryKey: ['getCategoryList'], queryFn: () => getTnxCategoryList(merchantId, 1), refetchInterval: false });

  // get the list of categories
  const { data: categoryList, isLoading } = useQuery({ queryKey: ['getMerchantProductList'], queryFn: () => getMerchantProducts(merchantId, 1), refetchInterval: false });

  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(0);

  const dispatch = useDispatch();

  const handleAdd = () => {
    setCount((count) => count + 1);
  };

  const handleMinus = () => {
    if (count === 0) {
      return 0;
    }
    setCount((count) => count - 1);
  };

  const dataList = categoryList?.data?.data?.products;

  const handledateChange = (date, dateString) => {
    setDateString(dateString);
  };

  const paymentType = ['Transfer', 'USSD', 'Phone', 'QRCode', 'Cash'];

  const info = {
    merchant_id: merchantId,
    product_id: product,
    amount: amountProps.value,
    payment_type: paymentMethod,
    originator_account_name: nameProps.value,
    description: descProps.value,
    transaction_date: dateProps,
    quantity: count,
  };

  const handleSave = async () => {
    const error = customInboundValidate(info);
    setError(error);
    if (Object.keys(error).length === 0) {
      //  dispatch redux actions
      dispatch(setAllInfo(info));
      // show confirm popup
      dispatch(setConfirmCustomInbound());
      // eslint-disable-next-line no-empty
    } else {
    }
  };

  return (
    <Popup onClose={() => setShowCustomInbound(false)} className="details-popup-content px-75 fade-in vh80">
      <PopupHeader text="Add new inbound" hideIcon />
      <Spacer height={30} />
      <div>
        <p>Enter the details of this transaction correctly</p>
        <Spacer height={20} />
        <BorderLessFormInput placeholder="Enter customer name" {...nameProps} name="customer_name" error={error} />
        <BorderLessFormInput placeholder="Enter amount" {...amountProps} name="amount" error={error} needNairaPrefix={true} />
        <BorderLessFormInput placeholder="Short description" {...descProps} name="description" error={error} />
        <BorderLessDateInput placeholder="Enter date of transaction" onChange={handledateChange} name="date" error={error} />
        {!isLoading && <BorderLessFormDropdownClone options={dataList} className="full-width" error={error} placeholder="Select product" onChange={(e) => setProduct(e)} name="product" />}{' '}
        <BorderLessAppInputWithCount placeholder="Enter quantity" error={error} name="count" handleAdd={handleAdd} handleMinus={handleMinus} count={count} readOnly={true} />
        <BorderLessFormDropdown className="full-width" placeholder="Select payment type" error={error} options={paymentType} onChange={(e) => setPaymentMethod(e)} name="paymentMethod" />
        <Spacer height={30} />
        <div className="d-flex j-center">
          <AppButton
            className="d-flex half-width align-center j-center app-button action-color-2 button-nav-padding text-white fade-in margin-top-10"
            name="Save"
            isActive={true}
            onClick={() => handleSave()}
            isBusy={loading}
          />
        </div>
      </div>
    </Popup>
  );
};

export const ConfirmCustomInboundPopup = ({ setParentPopup }) => {
  return (
    <Popup width="30%" flag={true} noPadding={true}>
      <ConfirmCustomHeader setParentPopup={setParentPopup} />
    </Popup>
  );
};

// virtual account popup details

export const VirtualAccountPopupDetails = (props) => {
  const {
    payouts,
    totalAmount,
    type,
    id,
    merchantId,
    customer,
    txnRef,
    description,
    date,
    onClose,
    status,
    isLoading,
    handleInitiateChallenge,
    reversalexpireson,
    createdon,
    issenttowebhook,
    merchantTnxId,
  } = props;
  const successCodes = useSelector((state) => state.merchant?.successCodes);
  const merchantIdFromState = useSelector((state) => state.user?.currentMerchant?.businessid);
  const failureCodes = useSelector((state) => state.merchant?.failureCodes);
  const publickey = useSelector((state) => state.user?.currentMerchant?.publickey);
  const { detail, loading: isFetching } = useTransactionDetail(id);

  const isInbound = description?.includes('Inbound Transfer');

  const [reverseLoading, setReverseLoading] = useState(false);
  const [imageFile, setImageFile] = useState(null);
  const [showCamera, setShowCamera] = useState(false);
  const [isImageLoading, setIsImageLoading] = useState(false);
  const [showReversed, setShowReversed] = useState(true);

  const checkSelfie = detail.merchanttransactiondata && detail?.merchanttransactiondata[0]?.issetselfie;
  const checkIsMasked = detail.merchanttransactiondata && detail?.merchanttransactiondata[0]?.ismasked;
  const checkReversed = detail.merchanttransactiondata && detail?.merchanttransactiondata[0]?.reversed;

  const [image, setImage] = useState('');
  const info = useSyncState({
    selfie_url: '',
  });

  const baseStyle = {
    textTransform: 'capitalize',
    width: '110px',
    borderRadius: '25px',
    textAlign: 'center',
  };

  const TransactionTypeStyle = {
    border: `1px solid ${type === 'credit' ? '#00BA48' : '#ED2B30'}`,
    color: `${type === 'credit' ? '#00BA48' : '#ED2B30'}`,
    ...baseStyle,
  };

  const StatusStyle = {
    border: `1px solid ${successCodes.includes(status) ? '#00BA48' : failureCodes.includes(status) ? '#f79420' : '#00BA48'}`,
    color: `${successCodes.includes(status) ? '#00BA48' : failureCodes.includes(status) ? '#f79420' : '#00BA48'}`,
    ...baseStyle,
  };

  const getTransactionStatus = useCallback(
    (status) => {
      if (successCodes.includes(status)) return 'success';
      if (failureCodes.includes(status)) return 'failed';
      if (status === '09') return 'pending';
      return 'paid';
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [status],
  );

  const onUploadClick = async (file) => {
    const data = new FormData();
    if (file) {
      data.append('file', file);
    }
    data.append('cloud_name', getCloudNameFromEnv());
    data.append('upload_preset', process.env.REACT_APP_UPLOAD_PRESET);
    data.append('folder', 'TakeAPicture');
    data.append('api_key', getCloudKeyFromEnv());

    try {
      setIsImageLoading(true);
      const res = await fetch(getCloudUrlFromEnv(), {
        method: 'POST',
        body: data,
      });
      const fileResponse = await res.json();

      if (fileResponse.secure_url) {
        info.set({
          ...info.get(),
          selfie_url: fileResponse.secure_url,
          // selfie_imagebase64: getBase64String(image),
        });

        const newData = {
          selfie_url: info.get().selfie_url,
          transaction_id: merchantId,
          type: 'merchant_transaction',
        };
        const res = await uploadSelfie(newData);
        if (res.data.status) {
          openNotificationWithIcon('success', 'Success', 'Photo Uploaded Successfully');
          setIsImageLoading(false);
          setShowCamera(false);
        }
      }
      setIsImageLoading(false);
    } catch (error) {
      setIsImageLoading(false);
      openNotificationWithIcon('error', 'Error', error.response?.data?.message);
    }
  };

  const captureAndUpload = useCallback(() => {
    return (
      <Popup onClose={() => setShowCamera(false)}>
        <div>
          <p className="fw400 faded-text-color text-center font-size-15">Take a clear photo.</p>
          <Spacer height={15} />
          <WebcamCapture loading={isImageLoading} onSubmitClick={() => onUploadClick(imageFile)} image={image} setImageFile={setImageFile} setImage={setImage} />
        </div>
      </Popup>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [image, imageFile, isImageLoading]);

  const reverseTransaction = async () => {
    try {
      setReverseLoading(true);
      await reverseTransactionService(merchantId, publickey);
      setReverseLoading(false);
      setShowReversed(false);
      openNotificationWithIcon('success', 'Success', 'Transaction Reversed Successfully');
    } catch (error) {
      setReverseLoading(false);
      openNotificationWithIcon('error', 'Error', error.response?.data?.message);
    }
  };

  const showReversalTimer = () => {
    if (!isSameOrAfterNow(reversalexpireson)) {
      return false;
    }
    if (!checkIsMasked && checkSelfie) {
      return false;
    }
    if (checkReversed) {
      return false;
    }
    return true;
  };

  const shouldShowSelfie = useMemo(() => {
    if (isInbound && !checkSelfie && !isFetching) return true;
    return false;
  }, [isInbound, checkSelfie, isFetching]);

  const shouldShowChallenge = useMemo(() => {
    if (isInbound && checkIsMasked && !checkReversed && !isFetching) return true;
    return false;
  }, [isInbound, checkIsMasked, checkReversed, isFetching]);

  // const pushWebHook = async (merchantID, TnxId) => {
  //   try {
  //     setWebhookLoading(true);
  //     const res = await repushWebhook(merchantID, TnxId);
  //     if (res?.data?.status) {
  //       setWebhookLoading(false);
  //       openNotificationWithIcon('success', 'Success', 'Webhook Pushed Successfully');
  //       onClose();
  //     }
  //   } catch (error) {
  //     setWebhookLoading(false);
  //     openNotificationWithIcon('error', 'Error', error.response?.data?.message);
  //   }
  // };

  return (
    <Popup className="details-popup-content px-75" onClose={onClose}>
      <div className="transaction-details">
        <div className="d-flex items-center">
          <div>
            <span className="fw400 text-color font-size-15">Total Amount</span>
            <p className="no-margin fw700 text-color font-size-24" style={{ marginTop: '-3px !important' }}>
              {totalAmount}
            </p>
          </div>
        </div>
        <Divider />
        <Spacer height={10} />
        <div className="details-grid p-relative d-grid dropdown-grid-temp-col-1fr mobile-gap grid-col-gap50">
          <div className="flex-one">
            <div>
              <p className="font-size-15 text-color opacity-5 line-height17">{status ? 'Status' : 'Type'}</p>
              <p className="text-color fw400 font-size-17 px-20 pt-2px pb-7px mt-5" style={status ? StatusStyle : TransactionTypeStyle}>
                {status ? getTransactionStatus(status) : type}
              </p>
              <Spacer height={15} />
            </div>
            <div className="max-width-13em overflow-auto">
              <p className="font-size-15 text-color opacity-5 line-height17">Destination</p>
              <p className="text-color fw400 font-size-17">{description || 'Not Available'}</p>
              <Spacer height={30} />

              {issenttowebhook === false && <Spacer height={15} />}
            </div>
          </div>
          <div className="flex-one">
            {customer && (
              <div>
                <p className="font-size-15 text-color opacity-5 line-height17">Customer</p>
                <p className="text-color fw400 font-size-17">{customer}</p>
                <Spacer height={30} />
              </div>
            )}
            <div>
              <p className="font-size-15 text-color opacity-5 line-height17">Date</p>
              <p className="text-color fw400 font-size-17">{formatDay(date)}</p>
              <Spacer height={30} />
            </div>
            {txnRef && (
              <div>
                <p className="font-size-15 text-color opacity-5 line-height17">Transaction Ref</p>
                <p className="text-color fw400 font-size-17">{txnRef}</p>
                <Spacer height={30} />
              </div>
            )}
          </div>
        </div>
        <div className="pt-40 pb-20">
          {isFetching && isInbound && <LoadingOutlined style={{ fontSize: '1em' }} size="large" />}
          {shouldShowSelfie && (
            <div className="d-flex align-center j-center full-width">
              <button
                className="action-color c-pointer unstyle-button bg-none letter-spacing1 full-width d-flex j-center align-center font-size-15 fw500 margin-top-10 mb-10"
                onClick={() => setShowCamera(true)}
              >
                Take A Picture
              </button>
              {showCamera && captureAndUpload()}
            </div>
          )}
          {shouldShowChallenge && (
            <AppButton
              isActive={true}
              isBusy={isLoading}
              onClick={() => handleInitiateChallenge()}
              name="Initiate a Challenge"
              className="action-color bg-none letter-spacing1 full-width d-flex j-center align-center font-size-15 fw500 margin-top-10 mb-10"
            />
          )}
          {isInbound && showReversed && showReversalTimer() && !isFetching && (
            <AppButton
              isActive={true}
              onClick={() => reverseTransaction()}
              name={<CustomCountdown reversalExpiriyTime={reversalexpireson} txnCreationTime={createdon || date} onStop={() => setShowReversed(false)} />}
              isBusy={reverseLoading}
              className="action-color-bg-outline full-width d-flex j-center align-center upload-button py-15 fw400 margin-top-10 mb-10"
            />
          )}
        </div>
      </div>
    </Popup>
  );
};

export const CashpointInitiateChallengePopup = ({ id, type, label, publickey, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [answer, setAnswer] = useState('');

  const getSubmitChallengeButtonStyle = () => {
    if (answer === '') return 'c-default';
    return 'c-pointer';
  };

  const onSubmitClick = async () => {
    try {
      setLoading(true);
      const res = await setChallengeAnswer({ answer }, id, publickey);
      if (res.data.status) {
        openNotificationWithIcon('success', 'Success', 'Challenge Setup Successfully.');
        setLoading(false);
        onClose();
      }
    } catch (error) {
      setLoading(false);
      openNotificationWithIcon('error', 'Error', error.response?.data?.message);
    }
  };

  const onConfirmUssdClick = async () => {
    try {
      setLoading(true);
      const res = await confrimUSSD(id);
      if (res.data.status) {
        openNotificationWithIcon('success', 'Success', res?.data?.message);
        setLoading(false);
        onClose();
      } else {
        openNotificationWithIcon('error', 'Error', res?.data?.message);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      openNotificationWithIcon('error', 'Error', error.response?.data?.message);
    }
  };

  return (
    <Popup className="details-popup-content px-75" onClose={onClose}>
      <div className="transaction-details d-flex flex-column j-space-between" style={{ height: '65vh' }}>
        <div>
          {type === 'otp' ? (
            <div className="d-flex align-center flex-vertical">
              <p className="input-label fw200 ">{label}</p>
              <Spacer height={15} />
              <AppButton
                isActive={true}
                isBusy={loading}
                onClick={() => onConfirmUssdClick()}
                name="Continue"
                className="action-color bg-none letter-spacing1 full-width d-flex j-center align-center font-size-15 fw500 margin-top-10 mb-10"
              />
            </div>
          ) : (
            <form className="d-flex flex-column j-space-between full-height mt-20">
              <div>
                <FormInput label={label} value={answer} onChange={(e) => setAnswer(e.target.value)} />
              </div>
              <div className="mb-20 d-flex align-center flex-vertical">
                <AppButton
                  name="Submit Challenge"
                  disabled={answer === ''}
                  isActive={true}
                  isBusy={loading}
                  onClick={onSubmitClick}
                  className={`${getSubmitChallengeButtonStyle()} mb-20 full-width font-size-17 fw400 upload-button primary-color action-color-bg padding-50`}
                />
              </div>
            </form>
          )}
        </div>
      </div>
    </Popup>
  );
};
